import {createContext, useCallback, useContext, useMemo, useState,} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {PolicyDTO} from "../../generated/swagger/swagger.api";
import {fTimestamp} from "../../utils/format-time";
import {ROW_COUNT} from "../../components/sprintify/table/use-table";
import {initialTable, TableProps, useTable} from "../../components/sprintify/table";
import {IPolicyFilters, IPolicyFilterValue} from "../../types/board";

// ----------------------------------------------------------------------
type ApplyFilterProps = {
    inputData: PolicyDTO[];
    comparator: (a: any, b: any) => number;
    filters: IPolicyFilters;
    dateError: boolean;
}
const defaultFilters: IPolicyFilters = {
    query: "",
    types: [],
    startDate: null,
    endDate: null,
};

type Props = {
    searchParams: URLSearchParams;
    paramPage: number;
    paramSize: number;
    paramQuery: string;
    paramStartDate: string;
    paramEndDate: string;

    table: TableProps;
    denseHeight: number;
    defaultFilters: IPolicyFilters;
    filters: IPolicyFilters;
    handleFilters: (name: string, value: IPolicyFilterValue) => void;
    handleResetFilters: () => void;

    onDeleteData: (id: number) => void;
};
const initialState: Props = {
    searchParams: new URLSearchParams(''),
    paramPage: 0,
    paramSize: 0,
    paramQuery: '',
    paramStartDate: '',
    paramEndDate: '',

    table: initialTable,
    denseHeight: 72,
    defaultFilters,
    filters: defaultFilters,
    handleFilters: (name: string, value: IPolicyFilterValue) => {
    },
    handleResetFilters: () => {
    },

    onDeleteData: (id: number) => {
    }
};
export const BoardManagerContext = createContext(initialState);

export const useBoardManagerContext = () => {
    const context = useContext(BoardManagerContext);

    if (!context)
        throw new Error(
            'useEditorContext must be use inside SlateEditorContextProvider'
        );

    return context;
};

type ManagerProviderProps = {
    children: React.ReactNode;
};

export function PolicyManagerProvider({children}: ManagerProviderProps) {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const paramPage = searchParams.get("page") ? Number(searchParams.get("page")) : 0;
    const paramSize = searchParams.get("size") ? Number(searchParams.get("size")) : ROW_COUNT;
    const paramQuery = searchParams.get("query") || "";
    const paramStartDate = searchParams.get("startTime") || "";
    const paramEndDate = searchParams.get("endTime") || "";

    const table = useTable({
        defaultOrderBy: "id",
        defaultOrder: "desc",
        defaultRowsPerPage: paramSize,
        defaultCurrentPage: paramPage,
    });
    const [filters, setFilters] = useState(defaultFilters); // 필터 객체

    const denseHeight = table.dense ? 52 : 72;

    const handleFilters = useCallback(
        (name: string, value: IPolicyFilterValue) => {
            table.onResetPage();
            setFilters((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [table]
    );

    const handleResetFilters = useCallback(() => {
        setFilters(defaultFilters);
    }, []);

    const onDeleteData = useCallback((id: number) => {
        try {
            // const {data} = await Swagger.api.boardDelete(d);
            // enqueueSnackbar(data.message, {variant: "success"});
            console.log('delete');
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, {variant: "error"});
        }
    }, [enqueueSnackbar]);

    const memoizedValue = useMemo(
        () => ({
            searchParams,
            paramPage,
            paramSize,
            paramQuery,
            paramStartDate,
            paramEndDate,

            table,
            denseHeight,

            defaultFilters,
            filters,
            handleFilters,
            handleResetFilters,

            onDeleteData
        }),
        [
            searchParams,
            paramPage,
            paramSize,
            paramQuery,
            paramStartDate,
            paramEndDate,
            table,
            denseHeight,
            filters,
            handleFilters,
            handleResetFilters,
            onDeleteData]
    );

    return (
        <BoardManagerContext.Provider value={memoizedValue}>
            {children}
        </BoardManagerContext.Provider>
    );
}

export const applyFilter = ({
                                inputData,
                                comparator,
                                filters: f,
                                dateError
                            }: ApplyFilterProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {startDate, endDate} = f;
    console.log(inputData, 'inputData');
    console.log(f, 'filter');

    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    inputData = stabilizedThis.map((el) => el[0]);

    // if (name) {
    //   inputData = inputData.filter(
    //     (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    //   );
    // }
    //
    // if (status !== 'all') {
    //   inputData = inputData.filter((user) => user.status === status);
    // }
    //
    // if (role.length) {
    //   inputData = inputData.filter((user) => role.includes(user.role));
    // }
    if (!dateError) {
        if (startDate && endDate) {
            inputData = inputData.filter(
                (data) =>
                    fTimestamp(data.createdTime) >= fTimestamp(startDate) &&
                    fTimestamp(data.createdTime) <= fTimestamp(endDate),
            );
        }
    }
    return inputData;
};
