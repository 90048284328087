// @mui
import {alpha, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// auth
import {useAuthContext} from 'src/auth/hooks';
// routes
// hooks
import {useResponsive} from 'src/hooks/use-responsive';
// theme
import {bgGradient} from 'src/theme/css';
// components
import {Logo} from 'src/components/logo';
import {m} from "framer-motion";
import getVariant from "../../sections/_examples/extra/animate-view/get-variant";
import {varBgColor} from "../../components/animate";

// ----------------------------------------------------------------------


type Props = {
    title?: string;
    image?: string;
    children: React.ReactNode;
};

export default function AuthClassicLayout({children, image, title}: Props) {

    const theme = useTheme();

    const upMd = useResponsive('up', 'md');

    const renderContent = (
        <Stack
            sx={{
                width: 1,
                mx: 'auto',
                maxWidth: 480,
                px: {xs: 2, md: 8},
                py: {xs: 15, md: 30},
            }}
        >
            {children}
        </Stack>
    );

    const renderSection = (
        <Box component={m.div} {...getVariant('bgColor', theme)} sx={{ height: 1, width: 1 }}>
            <Stack
                flexGrow={1}
                alignItems="center"
                justifyContent="center"
                spacing={10}
                sx={{
                    height: '100vh'
                }}
            >
                <Box
                    component="img"
                    alt="auth"
                    src={image || '/assets/images/logo.svg'}
                    sx={{maxWidth: 720}}
                />

            </Stack>
        </Box>
    );

    return (
        <Stack
            component="main"
            direction="row"
            sx={{
                minHeight: '100vh',
            }}
        >

            {upMd && renderSection}

            {renderContent}
        </Stack>
    );
}
