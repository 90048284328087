import numeral from 'numeral';
import {isNumber} from "lodash";

// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fCurrencyWon(number: number) {
  const format = isNumber(number) ? Number(number).toLocaleString() : '';

  return `${format} 원`;
}

export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fPhoneNumber(phoneNumber: string): string {
  // 11자리 번호인 경우 (010-1111-1114)
  if (phoneNumber.length === 11) {
    return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  // 10자리 번호인 경우 (010-111-1114)
  if (phoneNumber.length === 10) {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
  // 그 외의 경우는 원래 번호를 그대로 반환
  
  return phoneNumber;
  
}

export function calculateFee(price: number, feeRate: number): number {
  const fee = price * (feeRate / 100);
  return Math.round(fee); // 소수점 두 자리까지 반올림
}
