import * as Yup from "yup";
import {useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
// form
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
// @mui
import {LoadingButton} from "@mui/lab";
import {Card, Stack} from "@mui/material";
import {useSnackbar} from 'src/components/snackbar';
// routes
// @types
// components
import {ReqKko} from "../../../generated/swagger/swagger.api";
import {Swagger} from "../../../utils/API";
import FormProvider, {RHFTextField} from "../../../components/hook-form";
import {useSettingsContext} from "../../../components/settings";

// ----------------------------------------------------------------------

type Props = {
    isEdit?: boolean;
    id?: number;
    currentData?: ReqKko;
    onEnd: VoidFunction;
};

export default function KkoNewEditForm({isEdit, id, currentData, onEnd}: Props) {
    console.log(currentData, 'currentData');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {themeStretch, systemLanguage, systemMultilingual, systemDefaultLanguage} = useSettingsContext();

    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const NewProductSchema = Yup.object({
        // title: Yup.string().required("제목(한국어)는 필수 입력사항입니다.").max(100),
        // TODO : 다국어 설정에 따라 달라짐.
        title: Yup.string().required("제목는 필수 입력사항입니다.").max(100),
        templateCode: Yup.string().required("템플릿 코드는 필수 입력사항입니다.").max(100),
        template: Yup.string().required("템플릿은 필수 입력사항입니다.").max(1000),
    });

    const defaultValues = useMemo(
        () => ({
            // title: currentData?.title.ko || ''
            title: currentData?.title || "",
            templateCode: currentData?.templateCode || "",
            template: currentData?.template || "",
        }),
        [currentData]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: {isSubmitting},
    } = methods;

    const values = watch();

    useEffect(() => {
        if (isEdit && currentData) {
            reset(defaultValues);
        }
        if (!isEdit) {
            reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, currentData]);

    const onSubmit = handleSubmit(async (data) => {
        // await new Promise((resolve) => setTimeout(resolve, 3000));
        console.log(data, 'data');
        const body = data as ReqKko;
        if (isEdit) {
            if (!id) {
                enqueueSnackbar('수정할 ID가 없습니다.', {variant: "error"});
                return;
            }
            try {
                const response = await Swagger.api.kkoUpdate(id, body);
                console.log(response, 'response');
                if (response.status === 200) {
                    enqueueSnackbar('수정이 완료되었습니다.', {variant: "success"});
                    onEnd();
                    return;
                }
            } catch (e) {
                console.error(e);
                enqueueSnackbar(e.message, {variant: "error"});
                return;
            }
        } else {
            try {
                const response = await Swagger.api.kkoCreate(body);
                console.log(response, 'response');
                if (response.status === 201) {
                    enqueueSnackbar('등록이 완료되었습니다.', {variant: "success"});
                    reset();
                    onEnd();
                    return;
                }
            } catch (e) {
                console.error(e);
                enqueueSnackbar(e.message, {variant: "error"});
                return;
            }
        }
        enqueueSnackbar('?', {variant: "warning"});
    });


    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={3}>
                <Card>
                    <Stack spacing={3} sx={{p: 3}}>
                        {/* <SPI18nTextField name="title" label="제목"/> */}
                        <RHFTextField name="title" label="제목" />
                        <RHFTextField name="templateCode" label="템플릿 코드" />

                        <RHFTextField name="template" multiline rows={10} label="템플릿" />
                    </Stack>

                </Card>

            </Stack>
            <Stack sx={{mt: 5}}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={isSubmitting}
                >
                    {!isEdit ? "등록하기" : "수정하기"}
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
