// react
import {useState} from "react";
// @mui
import {Checkbox, Divider, IconButton, MenuItem, Stack, Switch, TableCell, TableRow, Tooltip, Typography} from "@mui/material";
// type
import {ReviewDTO, ServiceType} from "src/generated/swagger/swagger.api";
// utils
import {Swagger} from "src/utils/API";
import {fDate} from "src/utils/format-time";

// hooks
import {useBoolean} from "src/hooks/use-boolean";
// components
import TextMaxLine from "src/components/text-max-line";
import Iconify from "src/components/iconify";
import {useSnackbar} from "src/components/snackbar";
import CustomPopover, {usePopover} from "src/components/custom-popover";
import CategoryNameText from "src/components/sprintify/localized/CategoryNameText";
import Label from "src/components/label";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ExpertViewBody from "../../user/view/expert-view-body";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import Rating from "@mui/material/Rating";
import {Box} from "@mui/system";
import Avatar from "@mui/material/Avatar";
import {useResponsive} from "../../../hooks/use-responsive";

// ----------------------------------------------------------------------

type Props = {
    row: ReviewDTO;
    selected?: boolean;
    onSelectRow?: VoidFunction;
    onRefreshData: VoidFunction;
};

export default function ReviewTableRow({
                                          row,
                                          selected,
                                          onSelectRow,
                                          onRefreshData,
                                      }: Props) {
    const {enqueueSnackbar} = useSnackbar();
    const {
        id,
        rating,
        createdTime,
        task,
        isMainView,
        message,
        images,
        userNickname,
        userImage

    } = row;
    const {title,service} = task
    console.log('row',row)
    // 반응형
    const isMdUp = useResponsive('up', 'md');
    // open
    const openReviewView = useBoolean();
    const openRowDeleteDiagram = useBoolean();
    const openPopover = usePopover();

    const handleDelete = async (_id: number) => {
        try {
            const {data} = await Swagger.api.boardDelete(_id);
            enqueueSnackbar(data.message, {variant: "success"});
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, {variant: "error"});
        }
        openRowDeleteDiagram.onFalse();
        onRefreshData();
    };

  const [checkedMainView, setCheckedMainView] = useState(isMainView);
  const changeMainView = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedMainView(event.target.checked);
    await handleChangeMainView(event.target.checked)
  };
  const handleChangeMainView = async (value:boolean) =>{
    const  {userId, taskId} = id
    try {
      const body = {
        value
      }
      const response = await Swagger.api.changeMainView(userId,taskId, body);
      if(response) {
        enqueueSnackbar('변경되었습니다.', {variant: "success"});

      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(e.message, {variant: "error"});
    }
  }
  return (
        <>
            <TableRow hover selected={selected}>
                <TableCell>
                    <Stack flexDirection='row'>
                        <Label variant="soft">{service?.type === ServiceType.SELF_INFO ? '자기소개서' : '면접대본'}</Label>
                    </Stack>
                </TableCell>

                 <TableCell>
                     <Stack flexDirection='row'>
                         <CategoryNameText array={service?.categories as any}/>
                     </Stack>
                 </TableCell>

                <TableCell>
                  <Tooltip title={title} placement='top'>
                    <TextMaxLine
                        variant="body2"
                        line={1}
                   >
                      {title}
                    </TextMaxLine>
                  </Tooltip>
                </TableCell>

                <TableCell>
                    <TextMaxLine
                        variant="body2"
                        line={1}
                    >
                        {message}
                    </TextMaxLine>
                </TableCell>

                <TableCell>
                    <Button variant='outlined' onClick={openReviewView.onTrue}>상세보기</Button>
                </TableCell>

                <TableCell align="center">
                  <Stack spacing={0.5} direction="row" alignItems="center">
                    <Iconify
                      icon="carbon:star-filled"
                      sx={{ color: 'primary.main' }}
                      width={14}
                    />
                    <Typography variant="body2">
                      {rating}.0
                    </Typography>
                  </Stack>
                </TableCell>

                <TableCell>
                  <Switch
                    name="mainView"
                    checked={checkedMainView}
                    onChange={changeMainView}
                  />
                </TableCell>


                <TableCell>{fDate(createdTime)}</TableCell>

                <TableCell align="right">
                    <IconButton
                        color={openPopover.open ? "inherit" : "default"}
                        onClick={openPopover.onOpen}
                    >
                        <Iconify icon="eva:more-vertical-fill"/>
                    </IconButton>
                </TableCell>
            </TableRow>

            <CustomPopover
                open={openPopover.open}
                onClose={openPopover.onClose}
                arrow="right-top"
                sx={{width: 160}}
            >
                <MenuItem
                    onClick={() => {
                        openRowDeleteDiagram.onTrue();
                        openPopover.onClose();
                    }}
                    sx={{color: "error.main"}}
                >
                    <Iconify icon="eva:trash-2-outline"/>
                    삭제
                </MenuItem>
            </CustomPopover>

            {/* <TableRowDeleteDiagram */}
            {/*    open={openRowDeleteDiagram.value} */}
            {/*    dataId={id} */}
            {/*    onClose={() => openRowDeleteDiagram.onFalse()} */}
            {/*    onDeleteRow={() => handleDelete(id)} */}
            {/* /> */}

            <Dialog fullWidth maxWidth="xs" open={openReviewView.value} onClose={openReviewView.onFalse}>
                <DialogTitle>리뷰 상세 정보</DialogTitle>

                <DialogContent>
                    <Stack direction="column" spacing={2.5}>
                        <Stack spacing={1}>
                            <Typography variant="body2">
                                {title || ''}
                            </Typography>
                            <Typography variant="body2">
                                코렉터: {service?.expert.userNickname || ''}
                            </Typography>

                            <Divider sx={{ borderStyle: 'dashed' , my:1}} />

                            <Stack direction="row" alignItems="center">
                                <Avatar
                                    alt={userNickname}
                                    src={userImage}
                                    sx={{ width: 40, height: 40, mr: 2 }}
                                />
                                <Typography variant="subtitle2">{userNickname}</Typography>
                            </Stack>

                            <Rating
                                size="small"
                                value={rating || 0}
                                readOnly
                                sx={{ color: 'primary.main' }}
                            />
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{ __html: message || '' }}
                                sx={{ fontSize: 14, color: 'text.secondary' }}
                            />
                            {/* 리뷰 이미지 */}
                            {images && (
                                <Box sx={{ pt: '20px', display: 'flex', flexWrap: 'wrap' }}>
                                    {images.map((image, index) => (
                                        <Avatar
                                            key={index}
                                            variant="rounded"
                                            alt="리뷰 이미지"
                                            src={image.url}
                                            sx={{
                                                width: isMdUp ? 64 : 58,
                                                height: isMdUp ? 64 : 58,

                                                mx: { xs: '6px', md: '8px' },
                                                mb: 1,
                                            }}
                                        />
                                    ))}
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={openReviewView.onFalse} variant="outlined" color="inherit">
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
