import {Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";

type ItemProps = {
    label: string,
    value?: string | React.ReactNode | undefined,
}

type Props = {
    item: ItemProps
}
export default function ViewBodyLabelAndView({item}: Props) {
    return <Stack flexGrow={1} sx={{width: 1}}>
        <Stack direction="row" sx={{typography: 'body2'}}>
            <Box component="span" sx={{width: 120, minWidth:120, mr: 2}}>
                <Typography variant="subtitle2" color="text.secondary">{item.label}</Typography>
            </Box>
            {item.value}
        </Stack>
    </Stack>;
}
