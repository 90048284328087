// @mui
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles/createTheme";
import {Card, Stack, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useSettingsContext} from "../../../components/settings";
import {fDateTime} from "../../../utils/format-time";
import Iconify from "../../../components/iconify";
import {useBoolean} from "../../../hooks/use-boolean";
import {BannerDTO, ColorMode, LocalizedDTO} from "../../../generated/swagger/swagger.api";
import ViewBodyFooter from "../../../components/view-body/ViewBodyFooter";
import {valueByLang} from "../../../utils/i18n.utils";
import {alpha} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Image from "../../../components/image";
import Label from "../../../components/label";
import ViewBodyLabelAndView from "../../../components/view-body/ViewBodyLabelAndView";
import * as React from "react";

// ----------------------------------------------------------------------

interface Props {
    data: BannerDTO;
    sx?: SxProps<Theme>;
}

export default function BannerViewBody({data, sx, ...other}: Props) {
    const {
        id,
        background,
        backgroundColor,
        buttonTargetBlank,
        colorMode,
        fontColor1,
        fontColor2,
        buttonUrl,
        orderBy,
        hide,
        createdTime,
        updatedTime, createdBy, updatedBy
    } = data;
    const {systemDefaultLanguage} = useSettingsContext();
    const text1 = typeof data.text1 === 'string' ? data.text1 : valueByLang(data.text1 as LocalizedDTO, systemDefaultLanguage);
    const text2 = typeof data.text2 === 'string' ? data.text2 : valueByLang(data.text2 as LocalizedDTO, systemDefaultLanguage);
    const buttonLabel = typeof data.buttonLabel === 'string' ? data.buttonLabel : valueByLang(data.buttonLabel as LocalizedDTO, systemDefaultLanguage);

    const properties = useBoolean(true);

    const colorChip = (color: string) => <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
            width: 20,
            height: 20,
            bgcolor: color,
            borderRadius: '50%',
            border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
        }}
    />

    const getColorMode = () => {
        if (colorMode === ColorMode.MANAGER) {
            return '관리자설정';
        }
        if (colorMode === ColorMode.SYSTEM) {
            return '시스템';
        }
        return '';
    }

    const getButtonTarget = () => {
        if (buttonTargetBlank) {
            return '아웃링크';
        }
        return '내부링크';
    }

    const infoMode = [
        {
            label: '색상 모드',
            value: <Label color="info">{getColorMode()}</Label>
        }
    ]

    const infoBg = [
        {
            label: '배경 색상',
            value: <Stack spacing={1} direction="row">
                {colorChip(backgroundColor)}
                {backgroundColor}
            </Stack>
        }
    ]

    const infoOrderBy = [
        {
            label: '순서(오름차순)',
            value: `${orderBy}`
        }
    ]

    const infoButtons = [
        {
            label: '버튼 라벨',
            value: buttonLabel
        },
        {
            label: '버튼 URL',
            value: buttonUrl
        },
        {
            label: '버튼 타깃',
            value: getButtonTarget()
        },
    ]

    const infoHides = [
        {
            label: '숨김',
            value: hide ? '숨김' : '-'
        },
    ]

    return (
        <Stack spacing={3}>
            <Card sx={{p: 5}}>
                <Stack spacing={1}>
                    <Stack
                        spacing={3}
                        direction={{xs: 'column', sm: 'row'}}
                        alignItems={{xs: 'flex-end', sm: 'center'}}
                        sx={{mb: {xs: 3, md: 0}}}
                    >
                        <Stack flexGrow={1} sx={{width: 1}} spacing={3}>
                            <Stack>
                                {/* 날짜 */}
                                <Typography variant="caption" sx={{color: 'text.secondary', mb: 1}}>
                                    {fDateTime(createdTime)}
                                </Typography>

                            </Stack>
                            <Stack spacing={1.5}>
                                {infoMode.map((item) => <ViewBodyLabelAndView item={item}/>)}
                            </Stack>
                            <Stack>
                                <Stack direction="row" alignItems="center" spacing={1} sx={{pt: 2}}>
                                    {fontColor1 && colorChip(fontColor1)}
                                    <Typography variant="h6">
                                        {text1}
                                    </Typography>
                                </Stack>

                                <Stack direction="row" alignItems="center" spacing={1} sx={{pt: 2}}>
                                    {fontColor2 && colorChip(fontColor2)}
                                    <Typography variant="h6">
                                        {text2}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Divider/>
                            <Stack spacing={1}>
                                <Image
                                    src={background}
                                    sx={{borderRadius: 1, height: 300, width: 1}}/>
                            </Stack>
                            {backgroundColor && <Stack spacing={1.5}>
                                {infoBg.map((item) => <ViewBodyLabelAndView item={item}/>)}
                            </Stack>}
                            <Divider/>
                            <Stack spacing={1.5}>
                                {infoButtons.map((item) => <ViewBodyLabelAndView item={item}/>)}
                            </Stack>
                            <Divider/>
                            <Stack spacing={1.5}>
                                {infoOrderBy.map((item) => <ViewBodyLabelAndView item={item}/>)}
                            </Stack>
                            <Stack spacing={1.5}>
                                {infoHides.map((item) => <ViewBodyLabelAndView item={item}/>)}
                            </Stack>
                        </Stack>


                    </Stack>

                </Stack>
            </Card>

            <Stack spacing={1.5} sx={{p: 1}}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{typography: 'subtitle2'}}
                >
                    메타 정보
                    <IconButton size="small" onClick={properties.onToggle}>
                        <Iconify
                            icon={properties.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                        />
                    </IconButton>
                </Stack>
                {properties.value && (
                    <ViewBodyFooter
                        data={{
                            createdTime,
                            createdBy,
                            updatedTime,
                            updatedBy,
                        }}
                    />
                )}
            </Stack>
        </Stack>
    );
}
