// @mui
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles/createTheme";
import {Card, Divider, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useSettingsContext} from "../../../components/settings";
import Iconify from "../../../components/iconify";
import {useBoolean} from "../../../hooks/use-boolean";
import {ChatMessageDTO, ChatMessageType, ChatRoomDTO} from "../../../generated/swagger/swagger.api";
import ViewBodyFooter from "../../../components/view-body/ViewBodyFooter";
import * as React from "react";
import ViewBodyLabelAndView from "../../../components/view-body/ViewBodyLabelAndView";
import ChatMessageItem from "./chat-message-item";
import {useLightBox} from "../../../components/lightbox";
import {useState} from "react";

// ----------------------------------------------------------------------

interface Props {
    data: ChatRoomDTO;
    sx?: SxProps<Theme>;
}

export default function ChatManageViewBody({data, sx, ...other}: Props) {
    const {systemDefaultLanguage} = useSettingsContext();
    const {
        id,
        user,
        expert,
        countByMessage,
        latestTime,
        createdTime,
        createdBy,
        updatedTime,
        updatedBy,
        chatMessages
    } = data;

    const [combinedList, setCombinedList] = useState<ChatMessageDTO[]>(chatMessages);

    const slides = combinedList
        .filter((message) => message.type === ChatMessageType.IMG)
        .map((message) => ({src: message.file?.url || ''}));

    const lightbox = useLightBox(slides);

    const properties = useBoolean(true);
    const viewChatRoom = useBoolean(false);

    const info1 = [
        {
            label: '구매자 ID',
            value: `${id.userId}`
        },
        {
            label: '구매자 명',
            value: user.fullName,
        },
        {
            label: '구매자 이메일',
            value: user.email,
        },
        {
            label: '구매자 번호',
            value: user.mobile,
        },
    ];

    const info2 = [
        {
            label: '전문가 ID',
            value: `${id.expertId}`
        },
        {
            label: '전문가 명',
            value: expert.userNickname,
        },
        {
            label: '전문가 이메일',
            value: expert.userEmail,
        }
    ];

    return (
        <Stack spacing={3}>
            <Card sx={{p: 5}}>
                <Stack spacing={2}>

                    <Stack spacing={1.5}>
                        {info1.map((item) => <ViewBodyLabelAndView item={item}/>)}
                    </Stack>

                    <Divider/>
                    <Stack spacing={1.5}>
                        {info2.map((item) => <ViewBodyLabelAndView item={item}/>)}
                    </Stack>

                </Stack>
            </Card>
            <Card sx={{p: 5}}>
                <Stack spacing={2}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{typography: 'subtitle2'}}
                    >
                        채팅 내역
                        <IconButton size="small" onClick={viewChatRoom.onToggle}>
                            <Iconify
                                icon={viewChatRoom.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                            />
                        </IconButton>
                    </Stack>
                    {viewChatRoom.value && (<Stack spacing={1}>

                        {
                            chatMessages &&
                            [...chatMessages]
                                .map((chatMessage) => (
                                    <ChatMessageItem
                                        key={`${chatMessage.id}`}
                                        chatRoom={data}
                                        chatMessage={chatMessage}
                                        onOpenLightbox={(url) => lightbox.onOpen(url)}
                                    />
                                ))}
                    </Stack>)}

                </Stack>

            </Card>

            <Stack spacing={1.5} sx={{p: 1}}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{typography: 'subtitle2'}}
                >
                    메타 정보
                    <IconButton size="small" onClick={properties.onToggle}>
                        <Iconify
                            icon={properties.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                        />
                    </IconButton>
                </Stack>
                {properties.value && (
                    <ViewBodyFooter
                        data={{
                            createdTime,
                            createdBy,
                            updatedTime,
                            updatedBy,
                        }}
                    />
                )}
            </Stack>
        </Stack>
    );
}
