// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
// types
// components
//


import { alpha } from '@mui/material/styles';
import { Button } from '@mui/material';
import React from 'react';
import {ChatMessageDTO, ChatMessageType, ChatRoomDTO, OrderStatus} from "../../../generated/swagger/swagger.api";
import {useRouter} from "../../../routes/hooks";
import {useAuthContext} from "../../../auth/hooks";
import {getUserImage, getUsername} from "../../../utils/chat.utils";
import {fToNow} from "../../../utils/format-time";
import Iconify from "../../../components/iconify";
import TextMaxLine from "../../../components/text-max-line";
import Image from "../../../components/image";
import {fCurrencyWon} from "../../../utils/format-number";
import Label from "../../../components/label";
import Alert from "@mui/material/Alert";

// ----------------------------------------------------------------------

type Props = {
  chatRoom: ChatRoomDTO;
  chatMessage: ChatMessageDTO;
  onOpenLightbox: (value: string) => void;
};

export default function ChatMessageItem({
  chatRoom,
  chatMessage,
  onOpenLightbox,
}: Props) {
  const { push } = useRouter();
  const {
    id,
    message,
    user,
    expert,
    createdTime,
    chatRoomId,
    type,
    file,
    order,
  } = chatMessage;

  const isMe = () => user !== undefined &&  user !== null;

  const nickname = getUsername(chatRoom, isMe(), true);
  const image = getUserImage(chatRoom, isMe(), true);

  const hasTxt = type === ChatMessageType.TXT;
  const hasImage = type === ChatMessageType.IMG;
  const hasFile = type !== ChatMessageType.TXT && type !== ChatMessageType.IMG;
  const hasOrder = type === ChatMessageType.ORD && order;

  const renderInfo = (
    <Typography
      noWrap
      variant="caption"
      sx={{
        mb: 1,
        color: 'text.disabled',
        ...(!isMe() && {
          mr: 'auto',
        }),
      }}
    >
      {!isMe() && `${nickname},`} &nbsp;
      {createdTime && fToNow(createdTime)}
    </Typography>
  );

  const renderBody = (
    <Stack
      sx={{
        p: 1.5,
        minWidth: 48,
        maxWidth: 320,
        borderRadius: 1,
        typography: 'body2',
        bgcolor: 'background.neutral',
        ...(isMe() && {
          color: 'grey.800',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
        }),
        ...(hasImage && {
          p: 0,
          bgcolor: 'transparent',
        }),
      }}
    >
      {hasImage && (
        <Box
          component="img"
          alt="attachment"
          src={file?.url}
          onClick={() => onOpenLightbox(file?.url || '')}
          sx={{
            minHeight: 220,
            borderRadius: 1.5,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.9,
            },
          }}
        />
      )}
      {hasTxt && message}
      {hasFile && file?.url && (
        <Button
          variant="text"
          sx={{ textAlign: 'left', color: 'text.primary', fontWeight: 400 }}
          startIcon={<Iconify icon="bx:file" />}
          href={file?.url || ''}
          target="_blank"
        >
          {file?.originalFilename}
        </Button>
      )}
      {hasOrder && (
        <Stack spacing={1}>
          <Stack direction="row">
              <Alert color="info" sx={{pt:0, pb:0, width: '100%'}}>{message}</Alert>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Box>
                <Image alt="" src={order?.service?.thumbnail?.url} ratio="1/1" sx={{ width: 70, borderRadius: 2 }} />
            </Box>
            <Stack justifyContent="space-between" sx={{ py: 0.5 }}>
              <TextMaxLine line={2} variant="body2">
                {order?.title || '-'}
              </TextMaxLine>
              <Typography variant="subtitle2">
                {order?.amount && fCurrencyWon(order.amount)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );

  return (
    <Stack
      direction="row"
      justifyContent={isMe() ? 'flex-end' : 'flex-start'}
      sx={{ mb: 2 }}
    >
      {/* {!me && ( */}
      {/*  <Avatar */}
      {/*    alt={nickname} */}
      {/*    src={image} */}
      {/*    sx={{ width: 32, height: 32, mr: 2 }} */}
      {/*  /> */}
      {/* )} */}
      <Stack alignItems={isMe() ? 'flex-end' : 'flex-start'}>
        {renderInfo}
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            position: 'relative',
            '&:hover': {
              '& .message-actions': {
                opacity: 1,
              },
            },
          }}
        >
          {renderBody}
        </Stack>
      </Stack>
    </Stack>
  );
}
