import * as Yup from "yup";
import {useCallback, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
// form
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
// @mui
import {LoadingButton} from "@mui/lab";
import {Card, Divider, Stack, Typography} from "@mui/material";
import {useSnackbar} from 'src/components/snackbar';
// routes
// @types
// components
import {ColorMode, ReqBanner} from "../../../generated/swagger/swagger.api";
import {Swagger} from "../../../utils/API";
import FormProvider, {
    RHFColorPicker,
    RHFRadioGroup,
    RHFSwitch,
    RHFTextField,
    RHFUpload
} from "../../../components/hook-form";
import {useSettingsContext} from "../../../components/settings";
import {SPI18nTextField} from "../../../components/sprintify/form";
import {palette as themePalette} from "../../../theme/palette";

// ----------------------------------------------------------------------
const palette = themePalette('light');
const FONT_COLOR_OPTIONS = [
    palette.text.primary,
    palette.text.secondary,
    palette.text.disabled,
    palette.common.white,
    palette.secondary.main,
    palette.info.main,
    palette.info.darker,
    palette.success.main,
    palette.warning.main,
    palette.error.main,
    palette.error.darker,
    palette.common.black,
    '#FFFFFF00'
];
const BG_COLOR_OPTIONS = [
    palette.common.black,
    palette.common.white,
    palette.primary.main,
    palette.secondary.main,
    palette.info.main,
    palette.info.darker,
    palette.success.main,
    palette.warning.main,
    palette.error.main,
    palette.error.darker,
    '#FFFFFF00'
];

type Props = {
    isEdit?: boolean;
    id?: number;
    currentData?: ReqBanner;
    onEnd: VoidFunction;
};

export default function BannerNewEditForm({isEdit, id, currentData, onEnd}: Props) {
    console.log(currentData, 'currentData');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {themeStretch, systemLanguage, systemMultilingual, systemDefaultLanguage} = useSettingsContext();

    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();


    const NewSchema = Yup.object({
        text1: Yup.object().shape({
            ko: Yup.string().max(30),
            en: Yup.string().max(30),
            zhCn: Yup.string().max(30),
            zhTw: Yup.string().max(30),
            ja: Yup.string().max(30),
        }),
        text2: Yup.object().shape({
            ko: Yup.string().max(30),
            en: Yup.string().max(30),
            zhCn: Yup.string().max(30),
            zhTw: Yup.string().max(30),
            ja: Yup.string().max(30),
        }),
        background: Yup.string().url(),
        backgroundColor: Yup.string().max(9),
        fontColor1: Yup.string().max(9),
        fontColor2: Yup.string().max(9),
        buttonUrl: Yup.string().url().nullable(),
        buttonLabel: Yup.object().shape({
            ko: Yup.string().max(30),
            en: Yup.string().max(30),
            zhCn: Yup.string().max(30),
            zhTw: Yup.string().max(30),
            ja: Yup.string().max(30),
        }).nullable(),
        orderBy: Yup.number(),
        hide: Yup.boolean(),
        buttonTargetBlank: Yup.boolean(),
        colorMode: Yup.string().max(10),
    });

    const defaultValues = useMemo(
        () => ({
            // title: currentData?.title.ko || ''
            text1: {
                ko: currentData?.text1.ko || "",
                en: currentData?.text1.en || "",
                zhCn: currentData?.text1.zhCn || "",
                zhTw: currentData?.text1.zhTw || "",
                ja: currentData?.text1.ja || "",
            },
            text2: {
                ko: currentData?.text1.ko || "",
                en: currentData?.text1.en || "",
                zhCn: currentData?.text1.zhCn || "",
                zhTw: currentData?.text1.zhTw || "",
                ja: currentData?.text1.ja || "",
            },
            background: currentData?.background || "",
            backgroundColor: currentData?.backgroundColor || BG_COLOR_OPTIONS[10],
            fontColor1: currentData?.fontColor1 || FONT_COLOR_OPTIONS[0],
            fontColor2: currentData?.fontColor2 || FONT_COLOR_OPTIONS[1],
            buttonUrl: currentData?.buttonUrl || "",
            buttonLabel: {
                ko: currentData?.text1.ko || "",
                en: currentData?.text1.en || "",
                zhCn: currentData?.text1.zhCn || "",
                zhTw: currentData?.text1.zhTw || "",
                ja: currentData?.text1.ja || "",
            },
            hide: currentData?.hide === true,
            buttonTargetBlank: currentData?.buttonTargetBlank || false,
            orderBy: currentData?.orderBy || 0,
            colorMode: currentData?.colorMode || ColorMode.MANAGER,
        }),
        [currentData]
    );

    const methods = useForm({
        resolver: yupResolver(NewSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: {isSubmitting},
    } = methods;

    const values = watch()

    useEffect(() => {
        if (isEdit && currentData) {
            reset(defaultValues);
        }
        if (!isEdit) {
            reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, currentData]);

    const onSubmit = handleSubmit(async (data) => {
        // await new Promise((resolve) => setTimeout(resolve, 3000));
        console.log(data, 'data');
        const body = data as ReqBanner;
        if (isEdit) {
            if (!id) {
                enqueueSnackbar('수정할 ID가 없습니다.', {variant: "error"});
                return;
            }
            try {
                const response = await Swagger.api.bannerUpdate(id, body);
                console.log(response, 'response');
                if (response.status === 200) {
                    enqueueSnackbar('수정이 완료되었습니다.', {variant: "success"});
                    onEnd();
                    return;
                }
            } catch (e) {
                console.error(e);
                enqueueSnackbar(e.message, {variant: "error"});
                return;
            }
        } else {
            try {
                const response = await Swagger.api.bannerCreate(body);
                console.log(response, 'response');
                if (response.status === 201) {
                    enqueueSnackbar('등록이 완료되었습니다.', {variant: "success"});
                    reset();
                    onEnd();
                    return;
                }
            } catch (e) {
                console.error(e);
                enqueueSnackbar(e.message, {variant: "error"});
                return;
            }
        }
        enqueueSnackbar('?', {variant: "warning"});
    }, (errors:any) => {
        console.log(errors, 'errors');
        // 에러가 있을 경우, 첫 번째 에러 메시지를 alert로 표시
        // const firstError = Object.values(errors)[0];
        // alert(?.message || '폼 제출에 문제가 발생했습니다.');
    });


    const handleDrop = useCallback(
        async (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];
            if (file) {

                try {
                    const {data} = await Swagger.api.fileUpload({file})
                    setValue('background', data.url, {shouldValidate: true});
                } catch (e) {
                    console.error(e);
                }
            }
        },
        [setValue]
    );


    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={3}>
                <Card>
                    <Stack spacing={3} sx={{p: 3}}>
                        <RHFRadioGroup row spacing={4} name="colorMode"
                                       options={[{
                                           label: '관리자 설정',
                                           value: ColorMode.MANAGER
                                       }, {label: '시스템', value: ColorMode.SYSTEM}]}/>
                        <Divider/>
                        <Typography variant="subtitle2">Text 1</Typography>
                        <SPI18nTextField name="text1" label="Text 1" placeholder="Text 1 를 입력하시오"/>
                        <RHFColorPicker name="fontColor1" colors={FONT_COLOR_OPTIONS}/>

                        <Divider/>

                        <Typography variant="subtitle2">Text 1</Typography>
                        <SPI18nTextField name="text2" label="Text 2" placeholder="Text 2 를 입력하시오"/>
                        <RHFColorPicker name="fontColor2" colors={FONT_COLOR_OPTIONS}/>

                        <Divider/>

                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">배경</Typography>
                            <RHFUpload
                                thumbnail
                                name="background"
                                maxSize={3145728}
                                onDrop={handleDrop}
                                onUpload={() => console.info('ON UPLOAD')}
                            />
                        </Stack>
                        <RHFColorPicker name="backgroundColor" colors={BG_COLOR_OPTIONS}/>

                        <Divider/>
                        <Typography variant="subtitle2">버튼</Typography>
                        <RHFTextField name="buttonUrl" label="버튼 URL" type="url"/>
                        <SPI18nTextField name="buttonLabel" label="버튼 라벨" placeholder="버튼 라벨을 입력하시오"/>
                        <RHFSwitch name="buttonTargetBlank" label="아웃 링크"/>

                        <Divider/>
                        <RHFTextField name="orderBy" label="순서 (오름차순)" type="number"/>
                        <Divider/>
                        <RHFSwitch name="hide" label="숨김"/>


                    </Stack>
                </Card>

            </Stack>
            <Stack sx={{mt: 5}}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={isSubmitting}
                >
                    {!isEdit ? "등록하기" : "수정하기"}
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
