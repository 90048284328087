import {Helmet} from 'react-helmet-async';
// sections
import {SERVICE_NAME} from "../../../config-global";
import ServiceCategoryListView from "../../../sections/service-category/view/service-category-list-view";
import {PageProps} from "../../../types/page";

// ----------------------------------------------------------------------
export default function ServiceCategoryPage({pageName}:PageProps) {

    return (
        <>
            <Helmet>
                <title>
                    {pageName} | {SERVICE_NAME}
                </title>
            </Helmet>

             <ServiceCategoryListView pageName={pageName}/>

        </>
    );
}
