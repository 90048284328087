// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
// utils
import {fCurrencyWon} from 'src/utils/format-number';
// types
// components
import Iconify from 'src/components/iconify';
import CustomPopover, {usePopover} from 'src/components/custom-popover';
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {CalculateDTO} from "../../../generated/swagger/swagger.api";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Label from "../../../components/label";
import {Swagger} from "../../../utils/API";
import {useSnackbar} from "../../../components/snackbar";


// ----------------------------------------------------------------------

type Props = {
    row: CalculateDTO;
    selected: boolean;
    onViewRow: VoidFunction;
    onSelectRow: VoidFunction;
    onDeleteRow: VoidFunction;
    onRefresh: VoidFunction;
};

export default function CalculateTableRow({
                                              row,
                                              selected,
                                              onViewRow,
                                              onSelectRow,
                                              onDeleteRow,
                                              onRefresh,
                                          }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    console.log(row, 'row');
    const {
        expert,
        orders
    } = row;

    const collapse = useBoolean();
    const popover = usePopover();

    const [totalAmount, setTotalAmount] = useState(0);
    const [buyAmount, setBuyAmount] = useState(0);

    const handlerComplete = useCallback(async (orderId: string) => {
        try {
            const {data} = await Swagger.api.calculateComplete(orderId);
            if(data.result) {
                console.log('success');
                enqueueSnackbar("정산완료 처리되었습니다.", {
                    variant: "success",
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            window.location.reload();
        }
    }, [enqueueSnackbar]);

    useEffect(() => {
        let _totalAmount = 0;
        let _buyAmount = 0;
        orders.forEach(order => {
            _totalAmount += order.amount - order.feeAmount - order.calculateAmount;
            _buyAmount += order.buyAmount;
        })
        setTotalAmount(_totalAmount);
        setBuyAmount(_buyAmount);
    }, [orders]);

    const renderPrimary = (
        <TableRow hover selected={selected}>
            <TableCell padding="checkbox">
                <Checkbox checked={selected} onClick={onSelectRow}/>
            </TableCell>

            <TableCell>
                <Stack direction="row" alignItems="center">
                    <Avatar alt={expert.userNickname || ''} src={expert.userProfile || ''} sx={{mr: 2}}/>

                    <ListItemText
                        primary={expert.userNickname}
                        secondary={expert.userEmail}
                        primaryTypographyProps={{typography: 'body2'}}
                        secondaryTypographyProps={{
                            component: 'span',
                            color: 'text.disabled',
                        }}
                    />
                </Stack>
            </TableCell>

            <TableCell>
                <ListItemText
                    primary={`${expert.withdrawal?.bank} ${expert.withdrawal?.accountNo}`}
                    secondary={expert.withdrawal?.accountHolder}
                    primaryTypographyProps={{typography: 'body2'}}
                    secondaryTypographyProps={{
                        component: 'span',
                        color: 'text.disabled',
                    }}
                />
            </TableCell>

            <TableCell>

                <Typography>{orders.length} 건</Typography>

            </TableCell>

            <TableCell>
                {fCurrencyWon(buyAmount)}
            </TableCell>

            <TableCell>
                {fCurrencyWon(totalAmount)}
            </TableCell>

            <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>
                <IconButton
                    color={collapse.value ? 'inherit' : 'default'}
                    onClick={collapse.onToggle}
                    sx={{
                        ...(collapse.value && {
                            bgcolor: 'action.hover',
                        }),
                    }}
                >
                    <Iconify icon="eva:arrow-ios-downward-fill"/>
                </IconButton>
            </TableCell>
        </TableRow>
    );

    const renderSecondary = (
        <TableRow>
            <TableCell sx={{p: 0, border: 'none'}} colSpan={8}>
                <Collapse
                    in={collapse.value}
                    timeout="auto"
                    unmountOnExit
                    sx={{bgcolor: 'background.neutral'}}
                >
                    <Stack component={Paper} sx={{m: 1.5}}>

                        {orders && orders.map((order) => <Stack
                            key={1}
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                                '&:not(:last-of-type)': {
                                    borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                                },
                            }}
                        >


                            <ListItemText
                                primary={<Stack direction="row" spacing={2}>{order.title}</Stack>}
                                secondary={order.orderId}
                                primaryTypographyProps={{
                                    typography: 'body2',
                                }}
                                secondaryTypographyProps={{
                                    component: 'span',
                                    color: 'text.disabled',
                                    mt: 0.5,
                                }}
                            />


                            {/* <Box sx={{width: 110, textAlign: 'right'}}>{`${fCurrencyWon(0)}`}</Box> */}
                            <ListItemText
                                sx={{textAlign: 'right'}}
                                primary={`판매금액: ${fCurrencyWon(order.buyAmount)}`}
                                secondary={`정산금액: ${fCurrencyWon(order.amount - order.feeAmount - order.calculateAmount)}`}
                                primaryTypographyProps={{
                                    typography: 'body2',
                                }}
                                secondaryTypographyProps={{
                                    component: 'span',
                                    color: 'text.disabled',
                                    mt: 0.5,
                                }}
                            />

                            <Button size="small" variant="contained" onClick={() => handlerComplete(order.orderId)}>완료처리</Button>
                        </Stack>)}

                    </Stack>
                </Collapse>
            </TableCell>
        </TableRow>
    );

    return (
        <>
            {renderPrimary}

            {renderSecondary}

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{width: 140}}
            >
                <MenuItem
                    onClick={() => {
                        onViewRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="eva:eye-fill"/>
                    서비스 상세
                </MenuItem>
            </CustomPopover>
        </>
    );
}
