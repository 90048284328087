// routes
import { paths } from 'src/routes/paths';
import {styled} from "@mui/material/styles";

// 공통 오류 메세지
export const UNKNOWN_ERROR =
  '예기치 않은 오류가 발생했습니다. 관리자에게 문의하세요.';


// API
// ----------------------------------------------------------------------

export const SERVICE_NAME = process.env.REACT_APP_SERVICE_NAME || 'Admin';
export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const HOST_API_URL = process.env.REACT_APP_HOST_API_URL || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'


// STYLE
// ----------------------------------------------------------------------
// LIST ITEM
export const StyledListItem = styled('li')(({ theme }) => ({
  color: theme.palette.text.secondary,
  listStyle: 'none',
  '&::before': {
    content: '""',
    display: 'inline-block',
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    marginRight: '6px',
    backgroundColor: theme.palette.text.secondary,
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));
export const StyledListItemHyphen = styled('li')(({ theme }) => ({
  color: theme.palette.text.secondary,
  listStyle: 'none',
  '&::before': {
    content: "'-'",
    marginRight: '6px',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));
