// @mui
import {
    Button,
    Checkbox,
    Divider,
    IconButton,
    MenuItem,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
// utils
// @types
// components
import Label from "src/components/label";
import Iconify from "src/components/iconify";
import {KkoDTO} from "src/generated/swagger/swagger.api";
import TableRowDeleteDiagram from "src/components/sprintify/table/TableRowDeleteDiagram";
import {Swagger} from "src/utils/API";
import {useSnackbar} from "src/components/snackbar";
import LocalizedText from "src/components/sprintify/localized";
import CategoryNameText from "src/components/sprintify/localized/CategoryNameText";
import {fDateTime} from "../../../utils/format-time";
import CustomPopover, {usePopover} from "../../../components/custom-popover";
import {useBoolean} from "../../../hooks/use-boolean";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import {fCurrencyWon} from "../../../utils/format-number";
import * as React from "react";
import {ConfirmDialog} from "../../../components/custom-dialog";
import {useState} from "react";

// ----------------------------------------------------------------------

type Props = {
    row: KkoDTO;
    selected: boolean;
    onSelectRow: VoidFunction;
    onViewRow: VoidFunction;
    onEditRow: VoidFunction;
    onRefreshData: VoidFunction;
};

export default function KkoTableRow({
                                        row,
                                        selected,
                                        onSelectRow,
                                        onViewRow,
                                        onEditRow,
                                        onRefreshData,
                                    }: Props) {
    const {enqueueSnackbar} = useSnackbar();
    const {
        id,
        title,
        template,
        templateCode,
        createdTime,
    } = row;

    const collapse = useBoolean();
    const openSendTestDiagram = useBoolean();
    const openRowDeleteDiagram = useBoolean();
    const openPopover = usePopover();

    const [mobileNumber, setMobileNumber] = useState('');

    const handleMobileNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMobileNumber(event.target.value);
    };


    const handleDelete = async (_id: number) => {
        try {
            const {data} = await Swagger.api.kkoDelete(_id);
            enqueueSnackbar(data.message, {variant: "success"});
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, {variant: "error"});
        }
        openRowDeleteDiagram.onFalse();
        onRefreshData();
    };

    const handleSendTest = async () => {
        console.log("입력된 모바일 번호:", mobileNumber);
        if (mobileNumber) {
            console.log('OK');
            try {
                const {data} = await Swagger.api.kkoTest({
                    mobile: mobileNumber,
                    templateCode
                });
                if(data.result) {
                    openSendTestDiagram.onFalse();
                    enqueueSnackbar(data.message, {variant: 'success'});
                } else {
                    openSendTestDiagram.onFalse();
                    enqueueSnackbar(data.message, {variant: 'warning'});
                }
                console.log(data, '테스트');
            } catch (e) {
                console.error(e);
            }
        } else {
            enqueueSnackbar('수신받을 모바일 번호를 입력하세요.', {variant: 'warning'})
        }
    }

    const renderSecondary = (
        <TableRow>
            <TableCell sx={{p: 0, border: 'none'}} colSpan={8}>
                <Collapse
                    in={collapse.value}
                    timeout="auto"
                    unmountOnExit
                    sx={{bgcolor: 'background.neutral'}}
                >
                    <Stack component={Paper} sx={{m: 1.5}}>

                        <Stack
                            key={1}
                            direction="row"
                            alignItems="center"
                            sx={{
                                p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                                '&:not(:last-of-type)': {
                                    borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                                },
                                whiteSpace: 'pre-line', // 줄바꿈 적용
                            }}
                        >
                            {template}
                        </Stack>
                    </Stack>
                </Collapse>
            </TableCell>
        </TableRow>
    );

    return (
        <>
            <TableRow hover selected={selected}>
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow}/>
                </TableCell>

                <TableCell>{id}</TableCell>


                <TableCell>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                            variant="body2"
                            sx={{cursor: "pointer"}}
                            onClick={() => {
                                onViewRow();
                                openPopover.onClose();
                            }}
                        >
                            {title}
                        </Typography>

                    </Stack>
                </TableCell>

                <TableCell align="center">{templateCode}</TableCell>
                <TableCell align="center"><IconButton
                    color={collapse.value ? 'inherit' : 'default'}
                    onClick={collapse.onToggle}
                    sx={{
                        ...(collapse.value && {
                            bgcolor: 'action.hover',
                        }),
                    }}
                >
                    <Iconify icon="eva:arrow-ios-downward-fill"/>
                </IconButton></TableCell>
                <TableCell align="center">{fDateTime(createdTime)}</TableCell>

                <TableCell align="right">
                    <Stack direction="row" spacing={1}>
                            <Tooltip title="테스트 보내기" placement="top" arrow>
                                <IconButton color="default" onClick={() => openSendTestDiagram.onTrue()}>
                                    <Iconify icon="ri:kakao-talk-fill"/>
                                </IconButton>
                            </Tooltip>
                        <IconButton
                            color={openPopover.open ? "inherit" : "default"}
                            onClick={openPopover.onOpen}
                        >
                            <Iconify icon="eva:more-vertical-fill"/>
                        </IconButton>
                    </Stack>
                </TableCell>
            </TableRow>

            {renderSecondary}

            <CustomPopover
                open={openPopover.open}
                onClose={openPopover.onClose}
                arrow="right-top"
                sx={{width: 160}}
            >
                <MenuItem
                    onClick={() => {
                        onEditRow();
                        openPopover.onClose();
                    }}
                >
                    <Iconify icon="mingcute:pencil-line"/>
                    수정
                </MenuItem>

                <Divider sx={{borderStyle: "dashed"}}/>

                <MenuItem
                    onClick={() => {
                        openRowDeleteDiagram.onTrue();
                        openPopover.onClose();
                    }}
                    sx={{color: "error.main"}}
                >
                    <Iconify icon="eva:trash-2-outline"/>
                    삭제
                </MenuItem>
            </CustomPopover>

            <TableRowDeleteDiagram
                open={openRowDeleteDiagram.value}
                dataId={id}
                onClose={() => openRowDeleteDiagram.onFalse()}
                onDeleteRow={() => handleDelete(id)}
            />

            <ConfirmDialog
                open={openSendTestDiagram.value}
                onClose={() => openSendTestDiagram.onFalse()}
                title="카카오 알림톡 테스트"
                content={<Stack sx={{pt: 3}}>
                    <TextField
                        label="모바일 번호"
                        variant="outlined"
                        fullWidth
                        type="tel"
                        placeholder="010-1234-5678"
                        value={mobileNumber}
                        onChange={handleMobileNumberChange}
                    />
                </Stack>}
                action={
                    <Button variant="contained" onClick={handleSendTest}>
                        발송
                    </Button>
                }
            />
        </>
    );
}
