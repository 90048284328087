// react
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
// mui
import { Card, Container, Table, TableBody, TableContainer} from "@mui/material";
import isEqual from "lodash/isEqual";
// type
import { ReviewDTO } from "src/generated/swagger/swagger.api";
// utils
import {Swagger} from "src/utils/API";
import {fDate} from "../../../utils/format-time";
// hooks
import {useBoolean} from "src/hooks/use-boolean";
// provider
import {applyFilter, useBoardManagerContext} from "../review-manage-provider";
// components
import {
    emptyRows,
    getComparator,
    TableEmptyRows,
    TableHeadCustom,
    TableNoData,
    TablePaginationActions,
    TablePaginationCustom,
    TableSkeletonAll
} from "src/components/sprintify/table";
import {useSnackbar} from "src/components/snackbar";
import ReviewTableToolbar from "src/sections/review/review-table-toolbar";
import TableRowDeleteDiagram from "src/components/sprintify/table/TableRowDeleteDiagram";
import {paths, ROOT_PAGE_NAME} from "../../../routes/paths";
import ReviewTableFiltersResult from "../review-table-filters-result";
import ReviewTableRow from "./review-table-row";
import Scrollbar from "src/components/scrollbar";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import {useSettingsContext} from "src/components/settings";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: "type", label: "서비스타입", width: 100},
    {id: "categories.name", label: "카테고리", width: 100},
    {id: "task.title", label: "서비스명", width: 160, },
    {id: "message", label: "리뷰내용"},
    {id: "detail", label: "상세보기", width: 110},
    {id: "rating", label: "별점", width: 100, isSort: true},
    {id: "mainView", label: "메인노출",  width: 100},
    {id: "createdTime", label: "등록시간", width: 110, isSort: true},
    {id: '-', label: '관리', width: 60},
];

type Props = {
    pageName: string;
}

export default function ReviewListView({pageName}: Props) {
    const navigate = useNavigate();
    const {themeStretch} = useSettingsContext();
    const {enqueueSnackbar} = useSnackbar();
    const {
        table,
        denseHeight,

        defaultFilters,
        filters,
        handleFilters,
        handleResetFilters,

        searchParams,
        paramQuery,
        paramStartDate,
        paramEndDate,
    } = useBoardManagerContext();

    //= Loading State
    const [listDataLoading, setListDataLoading] = useState<boolean>(false);

    //= Popup
    const openSelectedDeleteDiagram = useBoolean();

    //= DATA
    const [tableData, setTableData] = useState<ReviewDTO[]>([]);
    const dateError = filters.startDate && filters.endDate ? filters.startDate.getTime() > filters.endDate.getTime() : false;
    const dataFiltered = applyFilter({
        inputData: tableData,
        comparator: getComparator(table.order, table.orderBy),
        filters,
        dateError,
    });
    const canReset = !isEqual(defaultFilters, filters);
    const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

    const loadData = async () => {
        setListDataLoading(true);
        let _query = '';
        if (filters.query && filters.query.length > 1) {
            _query = filters.query;
        }

        const {data} = await Swagger.api.reviewPage({
            size: table.rowsPerPage,
            page: table.page + 1,
            query: _query,
            startTime: fDate(filters.startDate, 'yyyy-M-d:00:00:00') || undefined,
            endTime:fDate(filters.endDate, 'yyyy-M-d:23:59:59') || undefined,
        });
        console.log(data, 'review');
        setTableData(data.items);
        table.setPageMetadata(data.metadata);
        setListDataLoading(false);
    };

    const handleReset = async () => {
        loadData();
    };
    const handleDeleteRow = async (id: number | undefined) => {
        if (id) {
            try {
                const {data} = await Swagger.api.boardDelete(id);
                enqueueSnackbar(data.message, {variant: "success"});
                handleReset();
            } catch (e) {
                console.error(e);
                enqueueSnackbar(e.message, {variant: "error"});
            }
        }
    };


    useEffect(() => {
        updateSearchParams(); // Update search parameters
        loadData();
        navigate(`?${searchParams.toString()}`); // Update the URL with the current search parameters
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        table.rowsPerPage,
        table.page,
        table.orderBy,
        table.order,
        filters.query,
        filters.startDate,
        filters.endDate,
    ]);

    function updateSearchParams() {
        searchParams.set("page", String(table.page));
        searchParams.set("size", String(table.rowsPerPage));

        if (filters.query) {
            searchParams.set("query", String(filters.query));
        } else {
            searchParams.delete("query");
        }
        if (filters.startDate && !Number.isNaN(filters.startDate.getTime())) {
            searchParams.set("startTime", filters.startDate.toISOString());
        }
        if (filters.endDate && !Number.isNaN(filters.endDate.getTime())) {
            searchParams.set("endTime", filters.endDate.toISOString());
        }
    }

    useEffect(() => {
        if (paramQuery) {
            handleFilters("query", paramQuery);
        }
        if (paramStartDate) {
            try {
                const startDate: Date = new Date(decodeURIComponent(paramStartDate));
                if (Number.isNaN(startDate.getTime())) {
                    handleFilters("startDate", startDate);
                }
            } catch (e) {
                console.error('e', e);
            }
        }
        if (paramEndDate) {
            try {
                const endDate: Date = new Date(decodeURIComponent(paramEndDate));
                if (Number.isNaN(endDate.getTime())) {
                    handleFilters("endDate", endDate);
                }
            } catch (e) {
                console.error('e', e);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramQuery, paramStartDate, paramEndDate]);

    return (
      <>
          <Container maxWidth={themeStretch ? false : "lg"}>
              <CustomBreadcrumbs
                heading={pageName}
                links={[
                    {
                        name: ROOT_PAGE_NAME,
                        href: paths.dashboard.root,
                    },
                    {
                        name: pageName,
                    },
                ]}
              />

              <Card
                sx={{
                    mt: {xs: 3, md: 5},
                    mb: {xs: 3, md: 5},
                }}
              >
                  <ReviewTableToolbar
                    filters={filters}
                    onFilters={handleFilters}
                    dateError={dateError}
                  />

                  {canReset && (
                    <ReviewTableFiltersResult
                      filters={filters}
                      onFilters={handleFilters}
                      onResetFilters={handleResetFilters}
                      results={table.metadata.total}
                      sx={{p: 2.5, pt: 0}}
                    />
                  )}

                  <TableContainer sx={{position: "relative", overflow: "unset"}}>
                      <Scrollbar>
                          <Table
                            size={table.dense ? "small" : "medium"}
                            sx={{minWidth: 800}}
                          >
                              <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={tableData.length}
                                numSelected={table.selected.length}
                                onSort={table.onSort}
                              />
                              <TableBody>
                                  {!listDataLoading &&
                                    dataFiltered.map((item: ReviewDTO, index: number) => (
                                      <ReviewTableRow
                                        key={index}
                                        row={item}
                                        // selected={table.selected.includes(item.id)}
                                        // onSelectRow={() => table.onSelectRow(item.id)}
                                        onRefreshData={() => handleReset()}
                                      />
                                    ))}
                                   {!listDataLoading && table.metadata.total > 0 && (
                                    <TableEmptyRows
                                      height={denseHeight}
                                      emptyRows={emptyRows(
                                        table.page,
                                        table.rowsPerPage,
                                        table.metadata.total,
                                      )}
                                    />
                                   )}
                                   {!listDataLoading && <TableNoData notFound={notFound}/>}
                                  {listDataLoading && (dataFiltered.length === 0) && (
                                    <TableSkeletonAll
                                      size={table.metadata.size}
                                      denseHeight={denseHeight}
                                    />
                                  )}
                              </TableBody>
                          </Table>
                      </Scrollbar>
                  </TableContainer>

                  <TablePaginationCustom
                    count={table.metadata.total}
                    colSpan={3}
                    page={table.metadata.currentPage - 1}
                    rowsPerPage={table.metadata.size}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                    ActionsComponent={TablePaginationActions}
                  />
              </Card>

          </Container>

          {/* === Delete === */}
          {/*  <TableRowDeleteDiagram */}
          {/*    open={openRowDeleteDiagram.value} */}
          {/*    dataId={selectedId} */}
          {/*    onClose={() => openRowDeleteDiagram.onFalse()} */}
          {/*    onDeleteRow={() => handleDeleteRow(selectedId)} */}
          {/*  /> */}
      </>
    );
}
