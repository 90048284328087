// ----------------------------------------------------------------------

import {Box, List, ListItem, Paper, Typography} from "@mui/material";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {alpha, styled} from '@mui/material/styles';
import {
    DragEndMeta, DragHandleComponent,
    DropLineRendererInjectedProps,
    GhostRendererMeta,
    Item,
    List as SortfulList,
    PlaceholderRendererInjectedProps,
    PlaceholderRendererMeta,
    StackedGroupRendererInjectedProps,
    StackedGroupRendererMeta,
} from "react-sortful";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import styles from '../nested-dnd/nested-dnd.module.css';
import Iconify from "../iconify";
import {CategoryItem, convertFromItemEntitiesMap} from "./service-category-dnd.utils";
import Label from "../label";
import {ServiceCategoryDTO} from "../../generated/swagger/swagger.api";
import {Swagger} from "../../utils/API";


const StyledSortfulList = styled(SortfulList<CategoryItem['id']>)({
    '--padding': '8px',
});

const StyledDropLine = styled('div')(({theme}) => ({
    height: '2px',
    backgroundColor: theme.palette.primary.main,

    '&.horizontal': {
        width: '2px',
        height: 'auto',
    },

    '&::before, &::after': {
        '--size': '6px',
        position: 'absolute',
        width: 'var(--size)',
        height: 'var(--size)',
        borderRadius: '50%',
        backgroundColor: 'inherit',
        content: '""',
    },

    '&::before': {
        top: 'calc(var(--size) / 2 * -1 + 1px)',
        left: 'calc(var(--size) / 2 * -1)',
    },

    '&::after': {
        top: 'calc(var(--size) / 2 * -1 + 1px)',
        right: 'calc(var(--size) / 2 * -1)',
    },

    '&.horizontal::before': {
        top: 'calc(var(--size) / 2 * -1)',
        left: 'calc(var(--size) / 2 * -1 + 1px)',
    },

    '&.horizontal::after': {
        top: 'auto',
        bottom: 'calc(var(--size) / 2 * -1)',
        left: 'calc(var(--size) / 2 * -1 + 1px)',
    },
    marginTop: '16px',
}));

type Props = {
    title?: string;
    data: Map<string, CategoryItem>;
    editMode: boolean;
    onEdit: (id: number) => void;
}

// const TitleComponent = (item: CategoryItem) => {
//     if(item) {
//         return <Typography variant="h6">{item.title}{item.hide && <Label>숨김</Label>}{item.group &&
//             <Label>그룹</Label>}</Typography>
//     }
//     return <></>;
// }

export default function ServiceCategoryDnd({title, data, editMode, onEdit}: Props) {
    const [itemEntitiesMapState, setItemEntitiesMapState] = useState(data);
    const renderDropLineElement = (injectedProps: DropLineRendererInjectedProps) => (
        <StyledDropLine ref={injectedProps.ref} style={injectedProps.style}/>
    );
    const itemElements = useMemo(() => {
        const topLevelItems = itemEntitiesMapState.get("root")!.children!.map((itemId) => itemEntitiesMapState.get(itemId)!);
        const createItemElement = (item: CategoryItem, index: number) => {
            if (item.children) {
                const childItems = item.children.map((itemId) => itemEntitiesMapState.get(itemId)!);
                const childItemElements = childItems.map(createItemElement);

                return (
                    <Item key={item.id} identifier={item.id} index={index} isGroup isLocked={editMode}>
                        <Box
                            sx={{
                                border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
                                borderRadius: 1,
                                p: 1
                            }}>
                            <ListItem
                                secondaryAction={
                                    <>
                                        {
                                            editMode && <IconButton edge="end" aria-label="delete" onClick={(e) => {
                                                e.stopPropagation();
                                                onEdit(Number(item.id))
                                            }}>
                                                <Iconify icon="mingcute:pencil-line"/>
                                            </IconButton>}
                                    </>
                                }>

                                <ListItemIcon>
                                    <Iconify icon="mdi:drag" width={24}
                                             sx={{color: editMode ? 'text.secondary' : 'text.primary'}}/>
                                </ListItemIcon>

                                <ListItemText primary={
                                    <Typography
                                        variant="body1">{item.title}{item.countByService && item.countByService > 0 &&
                                        <Label color="info" sx={{ml: 1}}>서비스: {item.countByService}</Label>}{item.hide &&
                                        <Label color="warning" sx={{ml: 1}}>숨김</Label>} </Typography>
                                }
                                              sx={{color: editMode ? 'text.secondary' : 'text.primary'}}/>
                            </ListItem>
                            <Divider sx={{borderStyle: 'dashed', my: 1}}/>
                            {childItemElements}
                        </Box>
                    </Item>
                );
            }

            return (
                <Item key={item.id} identifier={item.id} index={index} isLocked={editMode}>
                    <ListItem secondaryAction={
                        <>
                            {
                                editMode && <IconButton edge="end" aria-label="delete" onClick={(e) => {
                                    e.stopPropagation();
                                    onEdit(Number(item.id))
                                }}>
                                    <Iconify icon="mingcute:pencil-line"/>
                                </IconButton>}
                        </>
                    }>

                        <ListItemIcon>
                            <Iconify icon="mdi:drag" width={24}
                                     sx={{color: editMode ? 'text.secondary' : 'text.primary'}}/>
                        </ListItemIcon>

                        <ListItemText primary={<Typography
                            variant="body1">{item.title}{item.countByService && item.countByService > 0 &&
                            <Label color="info" sx={{ml: 1}}>서비스: {item.countByService}</Label>}{item.hide &&
                            <Label color="warning" sx={{ml: 1}}>숨김</Label>} </Typography>}
                                      sx={{color: editMode ? 'text.secondary' : 'text.primary'}}/>
                    </ListItem>
                </Item>
            );
        };

        return topLevelItems.map(createItemElement);
    }, [editMode, itemEntitiesMapState, onEdit]);

    const renderGhostElement = useCallback(({identifier, isGroup}: GhostRendererMeta<CategoryItem["id"]>) => {
            const item = itemEntitiesMapState.get(identifier);
            if (!item) return <></>;

            if (isGroup) {
                return (
                    <Box
                        className={styles.ghostItemVisible}
                        sx={{
                            borderRadius: 1,
                            border: (theme) => `dashed 1px ${alpha(theme.palette.primary.main, 0.8)}`,
                            opacity: 0.8,
                            width: '100%',
                            height: '100%',
                            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.3),
                        }}
                    >
                        <ListItem>
                            <ListItemText primary={item.title}/>
                        </ListItem>
                    </Box>
                );
            }

            return (
                <Box
                    className={styles.ghostItemVisible}
                    sx={{
                        borderRadius: 1,
                        border: (theme) => `dashed 1px ${alpha(theme.palette.primary.main, 0.8)}`,
                        opacity: 0.8,
                        width: '100%',
                        height: '100%',
                        bgcolor: (theme) => alpha(theme.palette.primary.light, 0.3),
                    }}
                >
                    <ListItem>
                        <ListItemText primary={item.title}/>
                    </ListItem>
                </Box>
            );
        },
        [itemEntitiesMapState],
    );

    const renderStackedGroupElement = useCallback(
        (injectedProps: StackedGroupRendererInjectedProps, {identifier}: StackedGroupRendererMeta<CategoryItem["id"]>) => {
            const item = itemEntitiesMapState.get(identifier)!;

            return (
                <Box
                    sx={{
                        ...injectedProps.style,
                        borderRadius: 1,
                        border: (theme) => `dashed 1px ${alpha(theme.palette.primary.main, 0.8)}`,
                        opacity: 0.4
                    }}
                >
                    <ListItem>
                        <ListItemText primary={item.title}/>
                    </ListItem>
                </Box>
            );
        },
        [itemEntitiesMapState],
    );

    const renderPlaceholderElement = useCallback(
        (injectedProps: PlaceholderRendererInjectedProps, {
            identifier,
            isGroup
        }: PlaceholderRendererMeta<CategoryItem["id"]>) => {
            const item = itemEntitiesMapState.get(identifier)!;

            return (
                <Box
                    sx={{
                        ...injectedProps.style,
                        borderRadius: 1,
                        border: (theme) => `dashed 1px ${alpha(theme.palette.grey[900], 0.8)}`,
                        opacity: 0.4,
                        bgcolor: 'background.neutral',
                        pt: 1,
                        pl: 3
                    }}
                >
                    <Typography variant="body1">{item.title}</Typography>
                </Box>
            );
        },
        [itemEntitiesMapState],
    );

    const moveArrayItem = (arr: CategoryItem["id"][], from: number, to: number) => {
        const item = arr[from];
        arr.splice(from, 1);
        arr.splice(to, 0, item);
        return arr;
    };

    const onDragEnd = useCallback(
        (meta: DragEndMeta<CategoryItem["id"]>) => {
            if (meta.groupIdentifier === meta.nextGroupIdentifier && meta.index === meta.nextIndex) return;

            const newMap = new Map(itemEntitiesMapState.entries());
            const item = newMap.get(meta.identifier);
            if (!item) return;
            const groupItem = newMap.get(meta.groupIdentifier ?? "root");
            if (!groupItem) return;
            if (!groupItem.children) return;


            if (meta.groupIdentifier === meta.nextGroupIdentifier) {
                const nextIndex = meta.nextIndex ?? groupItem.children?.length ?? 0;
                groupItem.children = moveArrayItem(groupItem.children, meta.index, nextIndex);
            } else {
                const nextGroupItem = newMap.get(meta.nextGroupIdentifier ?? "root");
                if (!nextGroupItem) return;
                if (!nextGroupItem.children) return;

                groupItem.children.splice(meta.index, 1);
                if (!meta.nextIndex) {
                    nextGroupItem.children.push(meta.identifier);
                } else {
                    nextGroupItem.children.splice(meta.nextIndex, 0, item.id);
                }
            }

            setItemEntitiesMapState(newMap);
            console.log(newMap, 'newMap');
            const result = convertFromItemEntitiesMap<ServiceCategoryDTO>(newMap);
            console.log(result, 'result');
            try {
                Swagger.api.serviceCategoryHierarchy(result);
            } catch (e) {
                console.error(e);
            }
        },
        [itemEntitiesMapState],
    );

    return (
        <Paper variant="outlined" sx={{pl: 3, pr: 3}}>
            <StyledSortfulList
                renderDropLine={renderDropLineElement}
                renderGhost={renderGhostElement}
                renderPlaceholder={renderPlaceholderElement}
                renderStackedGroup={renderStackedGroupElement}
                onDragEnd={onDragEnd}
            >
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader" sx={{mb: 1}}>
                            {title}
                        </ListSubheader>
                    }
                >
                    {itemElements}
                </List>
            </StyledSortfulList>
        </Paper>
    );
}
