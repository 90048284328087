import { ChatRoomDTO } from '../generated/swagger/swagger.api';
import { AuthUserType } from '../auth/types';

export function getUsername(
  chatRoom: ChatRoomDTO,
  isMe: boolean,
  opponent: boolean
): string {
  if (!isMe) {
    if (opponent) {
      if (chatRoom.expert?.userNickname) {
        return chatRoom.expert?.userNickname;
      }
      return '-';
    }
    return chatRoom.user.nickname;
  }
  if (isMe) {
    if (opponent) {
      if (chatRoom.user?.nickname) {
        return chatRoom.user?.nickname;
      }
      return '-';
    }
    return chatRoom.expert.userNickname || '';
  }
  return '';
}

export function getUserImage(
  chatRoom: ChatRoomDTO,
  isMe: boolean,
  opponent: boolean
): string {
  if (isMe) {
    if (opponent) {
      if (chatRoom.expert?.userProfile) {
        return chatRoom.expert?.userProfile;
      }
      return `/assets/images/profile_default.png`;
    }
    console.log('chatRoom.user.image', chatRoom.user.image);
    return chatRoom.user.image;
  }
  if (isMe) {
    if (opponent) {
      if (chatRoom.user?.image) {
        return chatRoom.user?.image;
      }
      return `/assets/images/profile_default.png`;
    }
    return chatRoom.expert.userProfile || `/assets/images/profile_default.png`;
  }
  return `/assets/images/profile_default.png`;
}
