import * as Yup from "yup";
import {useCallback, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
// form
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
// @mui
import {LoadingButton} from "@mui/lab";
import {Card, Divider, Stack} from "@mui/material";
import {useSnackbar} from 'src/components/snackbar';
// routes
// @types
// components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ReqServiceCategory, ServiceCategoryDTO} from "../../../generated/swagger/swagger.api";
import {Swagger} from "../../../utils/API";
import FormProvider, {RHFSwitch, RHFTextField, RHFUploadAvatar} from "../../../components/hook-form";
import {useSettingsContext} from "../../../components/settings";
import {SPI18nTextField} from "../../../components/sprintify/form";
import {fData} from "../../../utils/format-number";

// ----------------------------------------------------------------------
const initData: ReqServiceCategory = {
    name: {
        ko: '',
        en: '',
        zhCn: '',
        zhTw: '',
        ja: ''
    }, group: false
    , hide: false
    , iconImage: undefined
    , mainView: false
}

type Props = {
    isEdit?: boolean;
    id?: number;
    currentData?: ServiceCategoryDTO;
    onEnd: VoidFunction;
};

export default function ServiceCategoryForm({isEdit, id, currentData, onEnd}: Props) {
    console.log(currentData, 'currentData');
    let requestBody: ReqServiceCategory = initData;
    if (currentData) {
        requestBody = {
            name: typeof currentData.name === 'string' ? {
                ko: currentData.name,
                en: '',
                zhCn: '',
                zhTw: '',
                ja: ''
            } : currentData.name,
            group: (currentData.child && currentData.child.length > 0) || currentData.group,
            hide: currentData.hide,
            iconImage: currentData.iconImage,
            mainView: currentData.mainView,
        };
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {themeStretch, systemLanguage, systemMultilingual, systemDefaultLanguage} = useSettingsContext();

    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const NewProductSchema = Yup.object({
        name: Yup.object().shape({
            ko: Yup.string().required("제목(한국어)는 필수 입력사항입니다.").max(100),
            en: Yup.string().max(100),
            zhCn: Yup.string().max(100),
            zhTw: Yup.string().max(100),
            ja: Yup.string().max(100),
        }),
        iconImageUrl: Yup.string().url(),
        iconImageId: Yup.number().nullable(),
        group: Yup.boolean(),
        hide: Yup.boolean(),
        mainView: Yup.boolean()
    });

    const defaultValues = useMemo(
        () => ({
            // title: currentData?.title.ko || ''
            name: {
                ko: requestBody?.name.ko || "",
                en: requestBody?.name.en || "",
                zhCn: requestBody?.name.zhCn || "",
                zhTw: requestBody?.name.zhTw || "",
                ja: requestBody?.name.ja || "",
            },
            group: requestBody?.group || false,
            iconImageUrl: requestBody?.iconImage?.url,
            iconImageId: requestBody?.iconImage?.id,
            hide: requestBody?.hide || false,
            mainView: requestBody?.mainView || false,
        }),
        [requestBody?.group, requestBody?.hide, requestBody?.iconImage?.id, requestBody?.iconImage?.url, requestBody?.mainView, requestBody?.name.en, requestBody?.name.ja, requestBody?.name.ko, requestBody?.name.zhCn, requestBody?.name.zhTw]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: {isSubmitting},
    } = methods;

    const values = watch();
    const mainViewValue = watch("mainView");

    useEffect(() => {
        if (isEdit && requestBody) {
            reset(defaultValues);
        }
        if (!isEdit) {
            reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, currentData]);

    const onSubmit = handleSubmit(async (data) => {
        // await new Promise((resolve) => setTimeout(resolve, 3000));
        console.log(data, 'data');
        console.log(data.iconImageId, 'iconImageId');
        const body: ReqServiceCategory = {
            name: typeof data.name === 'string' ? {ko: data.name, en: '', zhCn: '', zhTw: '', ja: ''} : {ko: data.name.ko, en: '', zhCn: '', zhTw: '', ja: ''},
            group: data.group || false,
            hide: data.hide || false,
            mainView: data.mainView || false,
            iconImage: {
                id: data.iconImageId || undefined
            }
        };
        if (isEdit) {
            if (!id) {
                enqueueSnackbar('수정할 ID가 없습니다.', {variant: "error"});
                return;
            }
            try {
                const response = await Swagger.api.serviceCategoryUpdate(id, body);
                console.log(response, 'response');
                if (response.status === 200) {
                    enqueueSnackbar('수정이 완료되었습니다.', {variant: "success"});
                    onEnd();
                    return;
                }
            } catch (e) {
                console.error(e);
                enqueueSnackbar(e.message, {variant: "error"});
                return;
            }
        } else {
            try {
                const response = await Swagger.api.serviceCategoryCreate(body);
                console.log(response, 'response');
                if (response.status === 201) {
                    enqueueSnackbar('등록이 완료되었습니다.', {variant: "success"});
                    reset();
                    onEnd();
                    return;
                }
            } catch (e) {
                console.error(e);
                enqueueSnackbar(e.message, {variant: "error"});
                return;
            }
        }
        enqueueSnackbar('?', {variant: "warning"});
    });

    const handleDrop = useCallback(
        async (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];
            if (file) {

                try {
                    const {data} = await Swagger.api.fileUpload({file})
                    setValue('iconImageUrl', data.url, {shouldValidate: true});
                    setValue('iconImageId', data.id, {shouldValidate: true});
                } catch (e) {
                    console.error(e);
                }
            }
        },
        [setValue]
    );


    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={3}>
                <Card>
                    <Stack spacing={3} sx={{p: 3}}>
                        <SPI18nTextField name="name" label="카테고리 명"/>
                        <Divider sx={{borderStyle: 'dashed'}}/>
                        <RHFSwitch name="group"
                                   disabled={currentData?.child && currentData?.child.length > 0} label="그룹"/>
                        <RHFSwitch name="hide" label="숨김"/>
                        <RHFSwitch name="mainView" label="메인 노출" helperText="아이콘 등록이 필수입니다."/>
                        <RHFTextField name="iconImageId" type="hidden" />
                    </Stack>
                </Card>
                {mainViewValue && <Card>
                    <Stack spacing={3} sx={{p: 3}}>
                        <Box sx={{mb: 5}}>
                            maxSize={3145728}
                            <RHFUploadAvatar
                                name="iconImageUrl"
                                onDrop={handleDrop}
                                helperText={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 3,
                                            mx: 'auto',
                                            display: 'block',
                                            textAlign: 'center',
                                            color: 'text.disabled',
                                        }}
                                    >
                                        *.jpeg, *.jpg, *.png, *.gif
                                        <br/> 최대 {fData(3145728)}
                                    </Typography>
                                }
                            />
                        </Box>
                    </Stack>
                </Card>}
            </Stack>
            <Stack sx={{mt: 5}}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={isSubmitting}
                >
                    {!isEdit ? "등록하기" : "수정하기"}
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
