import {Helmet} from "react-helmet-async";
import {SERVICE_NAME} from "src/config-global";
import KkoListView from "src/sections/kko/view/kko-list-view";
import {PageProps} from "../../../types/page";
import ChatManageListView from "../../../sections/chat-manage/view/chat-manage-list-view";

// ----------------------------------------------------------------------
export default function ChatManagePage({pageName}:PageProps) {

    return (
        <>
            <Helmet>
                <title>
                    {pageName} | {SERVICE_NAME}
                </title>
            </Helmet>

            <ChatManageListView pageName={pageName}/>
        </>
    );
}
