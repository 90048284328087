import { useDropzone } from 'react-dropzone';
// @mui
import {
    Box,
    Button,
    IconButton,
    Stack,
    StackProps,
    Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// assets

//
import Iconify from '../iconify';
//
import { UploadProps } from './types';
import RejectionFiles from './errors-rejection-files';
import SingleFilePreview from './preview-single-file';
import MultiFilePreview from './preview-multi-file';

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    outline: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
    '&:hover': {
        opacity: 0.72,
    },
}));
type PlaceholderProps = {
    sx?: StackProps['sx'];
    title: string | undefined;
    description: string | undefined;
};

// ----------------------------------------------------------------------

export default function Upload({
                                   disabled,
                                   multiple = false,
                                   error,
                                   helperText,
                                   //
                                   file,
                                   onDelete,
                                   //
                                   files,
                                   thumbnail,
                                   onUpload,
                                   onRemove,
                                   onRemoveAll,
                                   sx,
                                   title,
                                   description,
                                   ...other
                               }: UploadProps) {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        fileRejections,
    } = useDropzone({
        multiple,
        disabled,
        ...other,
    });
    const hasFile = !!file && !multiple;
    // const imgUrl = file ? file.preview : null;
    const imgUrl = typeof file === 'string' ? file : file?.preview || file?.url;

    const hasFiles = files && multiple && files.length > 0;
    const isError = isDragReject || !!error;

    return (
        <Box sx={{ width: 1, position: 'relative', ...sx }}>
            <StyledDropZone
                {...getRootProps()}
                sx={{
                    ...(isDragActive && {
                        opacity: 0.72,
                    }),
                    ...(isError && {
                        color: 'error.main',
                        bgcolor: 'error.lighter',
                        borderColor: 'error.light',
                    }),
                    ...(disabled && {
                        opacity: 0.48,
                        pointerEvents: 'none',
                    }),
                }}
            >
                <input {...getInputProps()} />

                <Placeholder title={title} description={description} />

                {hasFile && <SingleFilePreview imgUrl={imgUrl} />}
            </StyledDropZone>

            {helperText && helperText}

            <RejectionFiles fileRejections={fileRejections} />

            {hasFile && (
                <IconButton
                    size="small"
                    onClick={onDelete}
                    sx={{
                        top: 16,
                        right: 16,
                        zIndex: 9,
                        position: 'absolute',
                        color: (theme) => alpha(theme.palette.common.white, 0.8),
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                        '&:hover': {
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                        },
                    }}
                >
                    <Iconify icon="eva:close-fill" width={18} />
                </IconButton>
            )}

            {hasFiles && (
                <>
                    <Box sx={{ my: 3 }}>
                        <MultiFilePreview
                            files={files}
                            thumbnail={thumbnail}
                            onRemove={onRemove}
                        />
                    </Box>

                    <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                        {onRemoveAll && (
                            <Button
                                color="inherit"
                                variant="outlined"
                                size="small"
                                onClick={onRemoveAll}
                            >
                                Remove all
                            </Button>
                        )}

                        {onUpload && (
                            <Button size="small" variant="contained" onClick={onUpload}>
                                Upload files
                            </Button>
                        )}
                    </Stack>
                </>
            )}
        </Box>
    );
}

// ----------------------------------------------------------------------

function Placeholder({ sx, title, description, ...other }: PlaceholderProps) {
    return (
        <Stack
            spacing={5}
            alignItems="center"
            justifyContent="center"
            direction={{
                xs: 'column',
                md: 'row',
            }}
            sx={{
                width: 1,
                height: 1,
                textAlign: {
                    xs: 'center',
                    md: 'left',
                },
                ...sx,
            }}
            {...other}
        >
            {/* <UploadIllustration sx={{ width: 220 }} /> */}

            <div>
                <Typography gutterBottom variant="h5" sx={{ textAlign: 'center' }}>
                    {title}
                </Typography>

                <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary', textAlign: 'center' }}
                >
                    {description}
                </Typography>
            </div>
        </Stack>
    );
}
