import {useCallback, useEffect, useState} from 'react';
// @mui
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// routes
import {paths} from 'src/routes/paths';
// _mock
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import {useSettingsContext} from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
// types
//
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import Stack from "@mui/material/Stack";
import {BoardDTO, ServiceCategoryDTO} from "../../../generated/swagger/swagger.api";
import {Swagger} from "../../../utils/API";
import {DrawerWrapper} from "../../../components/sprintify/drawer";
import ServiceCategoryForm from "./service-category-form";
import ServiceCategoryDnd from "../../../components/service-category/service-category-dnd";
import {convertToItemEntitiesMap} from "../../../components/service-category/service-category-dnd.utils";

// ----------------------------------------------------------------------
type Props = {
    pageName: string;
}

export default function ServiceCategoryListView({pageName}: Props) {
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {themeStretch, systemLanguage, systemMultilingual, systemDefaultLanguage} = useSettingsContext();
    const {enqueueSnackbar} = useSnackbar();


    //= Loading State
    const [listDataLoading, setListDataLoading] = useState<boolean>(false);
    const [detailDataLoading, setDetailDataLoading] = useState<boolean>(false);

    //= Popup
    const openNew = useBoolean();
    const openView = useBoolean();
    const openEdit = useBoolean();

    const onEdit = useBoolean();
    const [categories, setCategories] = useState<ServiceCategoryDTO[]>([]);
    const [detailData, setDetailData] = useState<ServiceCategoryDTO>();

    const [selectedId, setSelectedId] = useState<number | undefined>();


    const loadData = async () => {
        setListDataLoading(true);
        setCategories([]);

        const {data} = await Swagger.api.serviceCategoryList({isDepth:true});
        console.log(data, 'data');
        setCategories(data.items);
        // table.setPageMetadata(data.metadata);
        setListDataLoading(false);
    };

    const loadDetailData = async (id: number) => {
        setDetailDataLoading(true);
        const {data} = await Swagger.api.serviceCategoryGet(id);
        setDetailData(data);
        setDetailDataLoading(false);
    };


    const handleReset = async () => {
        loadData();
    };

    const handleCloseDrawer = useCallback(() => {
        openNew.onFalse();
        openEdit.onFalse();
        openView.onFalse();
    }, [openEdit, openNew, openView]);

    const handleOpenView = useCallback((id: number | undefined) => {
        openNew.onFalse();
        openEdit.onFalse();
        openView.onTrue();
        setSelectedId(id);
    }, [openEdit, openNew, openView]);

    const handleOpenEdit = useCallback((id: number | undefined) => {
        openNew.onFalse();
        openEdit.onTrue();
        openView.onFalse();
        setSelectedId(id);
    }, [openEdit, openNew, openView]);

    const handleDeleteRow = useCallback(async () => {
        if (detailData?.id && !detailData?.child || detailData?.child?.length === 0) {
            try {
                const {data} = await Swagger.api.serviceCategoryDelete(detailData.id);
                console.log(data, 'data');
                if (data?.result) {
                    enqueueSnackbar('삭제되었습니다.', {variant: 'success'})
                    return;
                }
            } catch (e) {
                console.log(e);
            } finally {
                loadData();
            }
            enqueueSnackbar('삭제에 실패하였습니다.', {variant: 'warning'})
        } else {
            enqueueSnackbar('자식 데이터가 존재할 경우, 삭제할 수 없습니다.', {variant: 'warning'})
        }
    }, [detailData, enqueueSnackbar]);


    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (selectedId) {
            loadDetailData(selectedId);
        } else {
            setDetailData(undefined);
        }
    }, [selectedId]);

    return (
        <>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <CustomBreadcrumbs
                    heading={pageName}
                    links={[
                        {name: '관리자', href: paths.dashboard.root},
                        {name: pageName}
                    ]}
                    action={
                        <Stack direction="row" spacing={1}>
                            <Button
                                variant="contained"
                                startIcon={<Iconify icon="mingcute:add-line"/>}
                                onClick={openNew.onTrue}
                            >
                                새 카테고리
                            </Button>
                            <Button
                                variant={onEdit.value ? 'outlined' : 'contained'}
                                startIcon={<Iconify icon="mingcute:pencil-line"/>}
                                onClick={onEdit.onToggle}
                            >
                                수정
                            </Button>
                        </Stack>
                    }
                    sx={{
                        mb: {xs: 3, md: 5},
                    }}
                />

                {categories && categories.length > 0 &&
                    <ServiceCategoryDnd
                        data={convertToItemEntitiesMap<ServiceCategoryDTO>(categories)}
                        onEdit={(id) => handleOpenEdit(id)}
                        editMode={onEdit.value}
                    />
                }

            </Container>

            <DrawerWrapper
                title="새글 작성"
                open={openNew.value}
                onClose={handleCloseDrawer}
                children={
                    <ServiceCategoryForm
                        onEnd={() => {
                            handleCloseDrawer();
                            handleReset();
                        }}/>}
            />

            <DrawerWrapper
                loading={detailDataLoading}
                title="수정 하기"
                open={openEdit.value}
                onClose={handleCloseDrawer}
                onDelete={handleDeleteRow}
                children={detailData &&
                    <ServiceCategoryForm
                        isEdit
                        id={detailData.id}
                        currentData={detailData}
                        onEnd={() => {
                            handleReset();
                            if (selectedId) loadDetailData(selectedId);
                        }}/>
                }
            />
        </>
    );
}
