import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 120,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
          <svg xmlns="http://www.w3.org/2000/svg" width="216" height="28" viewBox="0 0 216 28" fill="none">
              <path d="M71.0989 0.47998H57.3078L49.7061 22.31H59.3909L60.6529 18.61H67.7638L69.0157 22.31H78.7005L71.0989 0.47998ZM62.4156 13.47L64.2083 8.03998L66.0011 13.47H62.4156Z" fill={PRIMARY_MAIN}/>
              <path d="M96.3976 0.47998H94.4346C94.4346 2.65998 94.0139 4.38998 93.1426 5.66998C92.2913 6.94998 91.0394 7.63998 89.3768 7.76998V0.47998H79.712V22.31H89.3768V14.34C92.5217 14.45 94.2142 17.1 94.4646 22.31H101.055C100.053 17.51 98.5609 10.54 96.4076 0.47998H96.3976Z" fill={PRIMARY_MAIN}/>
              <path d="M8.89362 6.37998V8.58998H21.2125C22.6146 8.58998 23.9066 9.82998 23.9467 11.22L24.0268 14.2L24.1069 17.21C24.1971 20.14 22.0638 22.31 19.0892 22.31H0V16.7H14.9329C15.1131 16.7 15.2333 16.57 15.2433 16.4L15.1833 14.21H2.7442C1.32202 14.21 0 12.92 0 11.52V8.59998V5.40998C0 2.55998 2.12325 0.47998 5.01768 0.47998H23.6562L23.8165 6.08998H9.20409C9.03383 6.08998 8.90364 6.20998 8.90364 6.37998H8.89362Z" fill={PRIMARY_MAIN}/>
              <path d="M182.009 0.47998H168.218L160.616 22.31H170.311L171.563 18.61H178.674L179.936 22.31H189.6L182.009 0.47998ZM173.325 13.47L175.118 8.03998L176.911 13.47H173.325Z" fill={PRIMARY_MAIN}/>
              <path d="M210.562 11.36C212.055 10.22 213.126 8.79998 213.827 7.05998C214.528 5.33998 214.879 3.13998 214.879 0.47998H205.014C205.014 2.65998 204.593 4.39998 203.722 5.66998C202.871 6.94998 201.619 7.63998 199.956 7.76998V0.47998H190.291V22.31H199.956V14.34C203.101 14.45 204.804 17.1 205.054 22.31H215.5C215.179 17.27 213.547 13.62 210.562 11.35V11.36Z" fill={PRIMARY_MAIN}/>
              <path d="M120.104 6.09998C119.934 6.09998 119.813 6.21998 119.813 6.38998V7.72998C119.353 5.54998 118.842 3.13998 118.271 0.47998H134.566L134.726 6.09998H120.104Z" fill={PRIMARY_MAIN}/>
              <path d="M135.047 17.21C135.137 20.14 132.994 22.31 130.029 22.31H122.918C122.618 20.81 122.217 18.97 121.736 16.69H125.863C126.033 16.69 126.163 16.56 126.163 16.39L126.093 14.21H121.206C120.845 12.54 120.454 10.67 120.004 8.58997H132.132C133.545 8.58997 134.836 9.82997 134.877 11.22L134.957 14.21L135.047 17.21Z" fill={PRIMARY_MAIN}/>
              <path d="M145.313 6.39V8.6H157.631C159.034 8.6 160.325 9.84 160.366 11.23L160.446 14.21L160.526 17.22C160.616 20.15 158.483 22.32 155.508 22.32H136.399V16.71H151.332C151.512 16.71 151.632 16.58 151.642 16.41L151.582 14.22H139.153C137.721 14.22 136.419 12.93 136.419 11.53V8.61V5.42C136.419 2.57 138.532 0.5 141.427 0.5H160.065L160.225 6.11H145.613C145.443 6.11 145.313 6.23 145.313 6.4V6.39Z" fill={PRIMARY_MAIN}/>
              <path d="M34.3927 6.37999V8.58999H46.7115C48.1137 8.58999 49.4056 9.82999 49.4457 11.22L49.5258 14.2L49.606 17.21C49.6961 20.14 47.5628 22.31 44.5883 22.31H25.479V16.7H40.4119C40.5921 16.7 40.7123 16.57 40.7223 16.4L40.6623 14.21H28.2332C26.801 14.21 25.499 12.92 25.499 11.52V8.59999V5.40999C25.499 2.55999 27.6123 0.48999 30.5067 0.48999H49.1452L49.3055 6.09999H34.6931C34.5229 6.09999 34.3927 6.21999 34.3927 6.38999V6.37999Z" fill={PRIMARY_MAIN}/>
              <path d="M124.03 27.96L123.269 24.1C123.149 23.47 122.588 23.01 121.947 23.01H102.807C101.956 23.01 101.325 23.78 101.485 24.61L102.136 27.96H124.01H124.03Z" fill={PRIMARY_MAIN}/>
          </svg>

      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
