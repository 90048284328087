import {Helmet} from 'react-helmet-async';
// sections
import {ReviewListView} from 'src/sections/review/view';
import {SERVICE_NAME} from "../../../config-global";
import {PageProps} from "../../../types/page";

// ----------------------------------------------------------------------

export default function ReviewListPage({pageName}:PageProps) {
  return (
    <>
      <Helmet>
        <title>{pageName} | {SERVICE_NAME}</title>
      </Helmet>

       <ReviewListView pageName={pageName}/>
    </>
  );
}
