import {createContext, useCallback, useContext, useMemo, useState,} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {ROW_COUNT} from "../../components/sprintify/table/use-table";
import {initialTable, TableProps, useTable} from "../../components/sprintify/table";
import {CalculateDTO} from "../../generated/swagger/swagger.api";
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import {Swagger} from "../../utils/API";
import {fDate} from "../../utils/format-time";


// ----------------------------------------------------------------------
export type CalculateFiltersValue = string | string[] | Date | number | null;
export type CalculateFilters = {
    query: string;
    orderStatus: string;
    startDate: Date | null;
    endDate: Date | null;
};

type ApplyFilterProps = {
    inputData: CalculateDTO[];
    comparator: (a: any, b: any) => number;
    filters: CalculateFilters;
    dateError: boolean;
}

const defaultFilters: CalculateFilters = {
    query: "",
    orderStatus: "ALL",
    startDate: null,
    endDate: null,
};

type Props = {
    searchParams: URLSearchParams;
    paramPage: number;
    paramSize: number;
    paramQuery: string;
    paramStartDate: string;
    paramEndDate: string;

    table: TableProps;
    denseHeight: number;
    defaultFilters: CalculateFilters;
    filters: CalculateFilters;
    handleFilters: (name: string, value: CalculateFiltersValue) => void;
    handleResetFilters: () => void;

    onDeleteData: (id: number) => void;
    downloadExcel: () => void;
};
const initialState: Props = {
    searchParams: new URLSearchParams(''),
    paramPage: 0,
    paramSize: 0,
    paramQuery: '',
    paramStartDate: '',
    paramEndDate: '',

    table: initialTable,
    denseHeight: 72,
    defaultFilters,
    filters: defaultFilters,
    handleFilters: (name: string, value: CalculateFiltersValue) => {
    },
    handleResetFilters: () => {
    },
    onDeleteData: (id: number) => {
    },
    downloadExcel: () => {
    },
};
export const CalculateManagerContext = createContext(initialState);

export const useCalculateManagerContext = () => {
    const context = useContext(CalculateManagerContext);

    if (!context)
        throw new Error(
            'useCalculateManagerContext must be use inside useCalculateManagerProvider'
        );

    return context;
};

type ManagerProviderProps = {
    children: React.ReactNode;
};

export function CalculateManagerProvider({children}: ManagerProviderProps) {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const paramPage = searchParams.get("page") ? Number(searchParams.get("page")) : 0;
    const paramSize = searchParams.get("size") ? Number(searchParams.get("size")) : ROW_COUNT;
    const paramQuery = searchParams.get("query") || "";
    const paramStartDate = searchParams.get("startTime") || "";
    const paramEndDate = searchParams.get("endTime") || "";

    const table = useTable({
        defaultOrderBy: "id",
        defaultOrder: "desc",
        defaultRowsPerPage: paramSize,
        defaultCurrentPage: paramPage,
    });
    const [filters, setFilters] = useState(defaultFilters); // 필터 객체

    const denseHeight = table.dense ? 52 : 72;

    const handleFilters = useCallback(
        (name: string, value: CalculateFiltersValue) => {
            table.onResetPage();
            setFilters((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [table]
    );

    const handleResetFilters = useCallback(() => {
        setFilters(defaultFilters);
    }, []);

    const onDeleteData = useCallback((id: number) => {
        try {
            // const {data} = await Swagger.api.boardDelete(d);
            // enqueueSnackbar(data.message, {variant: "success"});
            console.log('delete');
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, {variant: "error"});
        }
    }, [enqueueSnackbar]);

    const downloadExcel = useCallback(async () => {
        try {
            const {data} = await Swagger.api.calculateList();
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('이체 내역');

            worksheet.columns = [
                {header: '은행명 또는 은행코드', key: 'bankCode', width: 15},
                {header: '입금계좌번호', key: 'accountNo', width: 20},
                {header: '이체금액', key: 'amount', width: 15},
                {header: '예금주성명', key: 'accountHolder', width: 15},
                {header: '입금계좌메모', key: 'accountMemo', width: 20},
                {header: '출금계좌메모', key: 'withdrawalMemo', width: 20},
                {header: 'CMS코드 1', key: 'cmsCode1', width: 15},
                {header: 'CMS코드 2', key: 'cmsCode2', width: 15},
                {header: 'CMS코드 3', key: 'cmsCode3', width: 15},
                {header: '수수료 부담', key: 'feeCharge', width: 15},
                {header: '메모', key: 'memo', width: 20},
            ];
            worksheet.getRow(1).hidden = true;
            data.forEach(calculate => {
                const {expert} = calculate;
                const {withdrawal} = expert;

                let totalCalculateAmount = 0;
                calculate.orders.forEach(order => {
                    console.log(order.calculateAmount, '# order.calculateAmount');
                    totalCalculateAmount += order.amount - order.feeAmount - order.calculateAmount;
                })
                worksheet.addRow({
                    bankCode: withdrawal.bank,
                    accountNo: withdrawal.accountNo,
                    amount: totalCalculateAmount,
                    accountHolder: withdrawal.accountHolder,
                    accountMemo: `싹싹 정산 (${fDate(new Date())})`,
                    withdrawalMemo: `싹싹 정산 (${fDate(new Date())})`,
                    cmsCode1: '',
                    cmsCode2: '',
                    cmsCode3: '',
                    feeCharge: '0',
                    memo: '',
                });
            });

            // 엑셀 파일 생성
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                saveAs(blob, `싹싹-${fDate(new Date())}-정산내역.xlsx`);
            });
        } catch (e) {
            console.error(e);
        }
    }, []);


    const memoizedValue = useMemo(
        () => ({
            searchParams,
            paramPage,
            paramSize,
            paramQuery,
            paramStartDate,
            paramEndDate,

            table,
            denseHeight,

            defaultFilters,
            filters,
            handleFilters,
            handleResetFilters,

            onDeleteData,
            downloadExcel,
        }),
        [
            searchParams,
            paramPage,
            paramSize,
            paramQuery,
            paramStartDate,
            paramEndDate,
            table,
            denseHeight,
            filters,
            handleFilters,
            handleResetFilters,
            onDeleteData,
            downloadExcel,]
    );

    return (
        <CalculateManagerContext.Provider value={memoizedValue}>
            {children}
        </CalculateManagerContext.Provider>
    );
}

export const applyFilter = ({
                                inputData,
                                comparator,
                                filters: f,
                                dateError
                            }: ApplyFilterProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {startDate, endDate} = f;
    console.log(inputData, 'inputData');
    console.log(f, 'filter');

    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    inputData = stabilizedThis.map((el) => el[0]);

    // if (name) {
    //   inputData = inputData.filter(
    //     (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    //   );
    // }
    //
    // if (status !== 'all') {
    //   inputData = inputData.filter((user) => user.status === status);
    // }
    //
    // if (role.length) {
    //   inputData = inputData.filter((user) => role.includes(user.role));
    // }
    // if (!dateError) {
    //     if (startDate && endDate) {
    //         inputData = inputData.filter(
    //             (data) =>
    //                 fTimestamp(data.createdTime) >= fTimestamp(startDate) &&
    //                 fTimestamp(data.createdTime) <= fTimestamp(endDate),
    //         );
    //     }
    // }
    return inputData;
};
