import {Helmet} from 'react-helmet-async';
// sections
import {SERVICE_NAME} from "../../../config-global";
import {PageProps} from "../../../types/page";
import CalculateListView from "../../../sections/calculate/calculate-list-view";

// ----------------------------------------------------------------------

export default function CalculateListPage({pageName}:PageProps) {
  return (
    <>
      <Helmet>
        <title>{pageName} | {SERVICE_NAME}</title>
      </Helmet>

      <CalculateListView pageName={pageName}/>
    </>
  );
}
