// form
import {Controller, useFormContext} from 'react-hook-form';
// @mui
import {Stack, Typography} from '@mui/material';
import ColorPicker from '../color-utils/color-picker';
import FormHelperText from "@mui/material/FormHelperText";
// ----------------------------------------------------------------------

type Props = {
    label?: string;
    name: string;
    helperText?: string;
    colors: string[];
};

export default function RHFColorPicker({
                                           label,
                                           name,
                                           helperText,
                                           colors,
                                       }: Props) {

    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <Stack spacing={1}>
                    {label && <Typography variant="subtitle2">{label}</Typography>}
                    <ColorPicker
                        selected={field.value as string}
                        onSelectColor={(color) => field.onChange(color as string)}
                        colors={colors}
                    />
                    {(!!error || helperText) && (
                    <FormHelperText error={!!error} sx={{px: 2}}>
                        {error ? error?.message : helperText}
                    </FormHelperText>
                    )}
                </Stack>
            )}
        />
    );
}
