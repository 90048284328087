import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen} from 'src/components/loading-screen';
import {BoardManagerProvider} from "../../sections/board/board-manage-provider";
import {UserManagerProvider} from "../../sections/user/user-manage-provider";
import {ServiceManagerProvider} from "../../sections/service/service-manage-provider";
import {OrderManagerProvider} from "../../sections/order/order-manage-provider";
import {TaskManagerProvider} from "../../sections/task/task-manage-provider";
import {ReviewManagerProvider} from "../../sections/review/review-manage-provider";
import {ServiceCategoryManagerProvider} from "../../sections/service-category/service-category-manage-provider";
import ServiceCategoryPage from "../../pages/dashboard/service/category";
import {PolicyManagerProvider} from "../../sections/policy/policy-manage-provider";
import {FaqManagerProvider} from "../../sections/faq/faq-manage-provider";
import {ContactManagerProvider} from "../../sections/contact2/contact-manage-provider";
import {BoardCategoryManagerProvider} from "../../sections/board-category/board-category-manage-provider";
import ReviewListPage from "../../pages/dashboard/review/list";
import CalculateListPage from "../../pages/dashboard/calculate/list";
import {CalculateManagerProvider} from "../../sections/calculate/calculate-manage-provider";
import { KkoManagerProvider } from 'src/sections/kko/kko-manage-provider';
import KkoListPage from "../../pages/dashboard/kko/list";
import ChatManagePage from "../../pages/dashboard/chat-manage/list";
import {ChatManagerProvider} from "../../sections/chat-manage/chat-manage-manage-provider";
import BannerListPage from "../../pages/dashboard/banner/list";
import {BannerManagerProvider} from "../../sections/banner/banner-manage-provider";

// ----------------------------------------------------------------------

// BOARD
const BoardListPage = lazy(() => import('src/pages/dashboard/board/list'));
const BoardCategoryPage = lazy(() => import('src/pages/dashboard/board/category'));
// FAQ
const FaqListPage = lazy(() => import('src/pages/dashboard/faq/list'));
// Contact
const ContactListPage = lazy(() => import('src/pages/dashboard/contact/list'));
// Policy
const PolicyListPage = lazy(() => import('src/pages/dashboard/policy/list'));

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
// Task
const TaskListPage = lazy(() => import('src/pages/dashboard/task/list'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// SERVICE
const ServiceListPage = lazy(() => import('src/pages/dashboard/service/list'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'master',
        children: [
          { path: 'service-category', element: <ServiceCategoryManagerProvider><ServiceCategoryPage pageName="서비스 카테고리 관리" /></ServiceCategoryManagerProvider>, index: true },
          { path: 'board-category', element: <BoardCategoryManagerProvider><BoardCategoryPage pageName="게시판 카테고리 관리"/></BoardCategoryManagerProvider>, index: true },
          { path: 'kko', element: <KkoManagerProvider><KkoListPage pageName="카카오 알림톡 템플릿 관리"/></KkoManagerProvider>, index: true },
        ],
      },
      {
        path: 'board',
        children: [
          { element: <BoardManagerProvider><BoardListPage pageName="공지사항 관리"/></BoardManagerProvider>, index: true },
          { path: 'list', element: <BoardManagerProvider><BoardListPage pageName="공지사항 관리"/></BoardManagerProvider> },
        ],
      },
      {
        path: 'faq',
        children: [
          { element: <FaqManagerProvider><FaqListPage pageName="FAQ 관리"/></FaqManagerProvider>, index: true },
          { path: 'list', element: <FaqManagerProvider><FaqListPage pageName="FAQ 관리"/></FaqManagerProvider> },
        ],
      },
      {
        path: 'contact',
        children: [
          { element: <ContactManagerProvider><ContactListPage pageName="1:1문의 관리"/></ContactManagerProvider>, index: true },
          { path: 'list', element: <ContactManagerProvider><ContactListPage pageName="1:1문의 관리"/></ContactManagerProvider> },
        ],
      },
      {
        path: 'policy',
        children: [
          { element: <PolicyManagerProvider><PolicyListPage pageName="약관 관리"/></PolicyManagerProvider>, index: true },
          { path: 'list', element: <PolicyManagerProvider><PolicyListPage pageName="약관 관리"/></PolicyManagerProvider> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserManagerProvider><UserListPage pageName="회원 관리" /></UserManagerProvider> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'service',
        children: [
          { path: 'list', element: <ServiceManagerProvider><ServiceListPage pageName="서비스 관리" /></ServiceManagerProvider> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderManagerProvider><OrderListPage pageName="주문/결제 관리" /></OrderManagerProvider>, index: true },
          { path: 'list', element: <OrderManagerProvider><OrderListPage pageName="주문/결제 관리" /></OrderManagerProvider> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'calculate',
        children: [
          { element: <CalculateManagerProvider><CalculateListPage pageName="정산 관리" /></CalculateManagerProvider>, index: true }
        ],
      },
      {
        path: 'task',
        children: [
          { element: <TaskManagerProvider><TaskListPage pageName="작업 관리" /></TaskManagerProvider>, index: true },
          { path: 'list', element: <TaskManagerProvider><TaskListPage pageName="작업 관리" /></TaskManagerProvider> },
        ],
      },
      {
        path: 'review',
        children: [
          { element: <ReviewManagerProvider><ReviewListPage pageName="리뷰 관리" /></ReviewManagerProvider>, index: true },
          { path: 'list', element: <ReviewManagerProvider><ReviewListPage pageName="리뷰 관리" /></ReviewManagerProvider> },
        ],
      },
      {
        path: 'chat-manage',
        children: [
          { element: <ChatManagerProvider><ChatManagePage pageName="채팅 관리"/></ChatManagerProvider>, index: true },
          { path: 'list', element: <ChatManagerProvider><ChatManagePage pageName="채팅 관리"/></ChatManagerProvider> },
        ],
      },
      {
        path: 'banner',
        children: [
          { element: <BannerManagerProvider><BannerListPage pageName="배너 관리"/></BannerManagerProvider>, index: true },
          { path: 'list', element: <BannerManagerProvider><BannerListPage pageName="배너 관리"/></BannerManagerProvider> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
