import {Helmet} from "react-helmet-async";
import {SERVICE_NAME} from "src/config-global";
import BannerListView from "src/sections/banner/view/banner-list-view";
import {PageProps} from "../../../types/page";

// ----------------------------------------------------------------------
export default function BannerListPage({pageName}:PageProps) {

    return (
        <>
            <Helmet>
                <title>
                    {pageName} | {SERVICE_NAME}
                </title>
            </Helmet>

            <BannerListView pageName={pageName}/>
        </>
    );
}
