import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Card, Container, Stack, Table, TableBody, TableContainer} from "@mui/material";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import {useSettingsContext} from "src/components/settings";
import {
    emptyRows,
    getComparator,
    TableEmptyRows,
    TableHeadCustom,
    TableNoData,
    TablePaginationActions,
    TablePaginationCustom,
    TableSelectedAction,
    TableSkeletonAll
} from "src/components/sprintify/table";
import {ChatRoomDTO, ChatRoomId} from "src/generated/swagger/swagger.api";
import {Swagger} from "src/utils/API";
import TableSelectedDeleteDiagram from "src/components/sprintify/table/TableSelectedDeleteDiagram";
import {useSnackbar} from "src/components/snackbar";
import {transformSortJSON} from "src/components/sprintify/table/use-table";
import {DrawerWrapper} from "src/components/sprintify/drawer";
import TableRowDeleteDiagram from "src/components/sprintify/table/TableRowDeleteDiagram";
import isEqual from "lodash/isEqual";
import {useBoolean} from "src/hooks/use-boolean";
import ChatManageFiltersResult from "../chat-manage-filters-result";
import {paths, ROOT_PAGE_NAME} from "../../../routes/paths";
import {fISO} from "../../../utils/format-time";
import {applyFilter, useChatManageContext} from "../chat-manage-manage-provider";
import ChatManageTableToolbar from "../chat-manage-table-toolbar";
import ChatManageTableAction from "../../../components/chat-manage/chat-manage-table-action";
import ChatManageTableRow from "./chat-manage-table-row";
import ChatManageViewBody from "./chat-manage-view-body";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
    {id: "user", label: "사용자", align: "left"},
    {id: "expert", label: "전문가", align: "left"},
    {id: "countByMessage", label: "대화 수", align: "center"},
    {id: "latestTime", label: "마지막 대화", align: "center"},
    {id: "createdTime", label: "등록시간", align: "center"},
    {id: '-', label: '', width: 80},
];

type Props = {
    pageName: string;
}

export default function ChatManageListView({pageName}: Props) {
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {themeStretch, systemLanguage, systemMultilingual, systemDefaultLanguage} = useSettingsContext();
    const {enqueueSnackbar} = useSnackbar();
    const {
        table,
        denseHeight,

        defaultFilters,
        filters,
        handleFilters,
        handleResetFilters,

        searchParams,
        paramQuery,
        paramStartDate,
        paramEndDate,
    } = useChatManageContext();

    //= Loading State
    const [listDataLoading, setListDataLoading] = useState<boolean>(false);
    const [detailDataLoading, setDetailDataLoading] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<ChatRoomDTO>();

    //= Popup
    const openView = useBoolean();
    const openRowDeleteDiagram = useBoolean();
    const openSelectedDeleteDiagram = useBoolean();

    //= DATA
    const [selectedId, setSelectedId] = useState<ChatRoomId | undefined>();
    const [tableData, setTableData] = useState<ChatRoomDTO[]>([]);

    const dateError = filters.startDate && filters.endDate ? filters.startDate.getTime() > filters.endDate.getTime() : false;

    const dataFiltered = applyFilter({
        inputData: tableData,
        comparator: getComparator(table.order, table.orderBy),
        filters,
        dateError,
    });

    const canReset = !isEqual(defaultFilters, filters);
    const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

    const loadData = async () => {
        setListDataLoading(true);
        setTableData([]);
        let _query = '';
        if (filters.query && filters.query.length > 1) {
            _query = filters.query;
        }
        const {data} = await Swagger.api.chatRoomPage({
            size: table.rowsPerPage,
            page: table.page + 1,
            query: _query,
            startTime: fISO(filters.startDate),
            endTime: fISO(filters.endDate),
            orderBy:
                table.orderBy &&
                transformSortJSON(
                    JSON.stringify({
                        [table.orderBy]: table.order,
                    }),
                ),
        });
        console.log(data, 'chatManage');
        setTableData(data.items);
        table.setPageMetadata(data.metadata);
        setListDataLoading(false);
    };

    const loadDetailData = async (id: ChatRoomId) => {
        setDetailDataLoading(true);
        const {data} = await Swagger.api.chatRoomGet(id.userId, id.expertId);
        setDetailData(data);
        setDetailDataLoading(false);
    };

    const handleReset = async () => {
        loadData();
    };

    const handleCloseDrawer = () => {
        openView.onFalse();
        setSelectedId(undefined);
    };


    const handleOpenView = (id: ChatRoomId | undefined) => {
        openView.onTrue();
        setSelectedId(id);
    };

    const handleDeleteRows = async (selectedRows: number[]) => {
        try {
            const {data} = await Swagger.api.kkoDeleteByIds(selectedRows);
            table.setSelected([]);
            enqueueSnackbar(data.message, {variant: "success"});
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, {variant: "error"});
        }
        handleReset();
        openSelectedDeleteDiagram.onFalse()
    };

    const handleDeleteRow = async (id: string | undefined) => {
        if (id) {
            try {
                // const {data} = await Swagger.api.kkoDelete(id);
                // enqueueSnackbar(data.message, {variant: "success"});
                handleCloseDrawer();
                handleReset();
            } catch (e) {
                console.error(e);
                enqueueSnackbar(e.message, {variant: "error"});
            }
        }
    };

    useEffect(() => {
        updateSearchParams(); // Update search parameters
        loadData();
        navigate(`?${searchParams.toString()}`); // Update the URL with the current search parameters
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        table.rowsPerPage,
        table.page,
        table.orderBy,
        table.order,
        filters.query,
        filters.startDate,
        filters.endDate
    ]);

    function updateSearchParams() {
        searchParams.set("page", String(table.page));
        searchParams.set("size", String(table.rowsPerPage));

        if (filters.query) {
            searchParams.set("query", String(filters.query));
        }
        if (filters.startDate && !Number.isNaN(filters.startDate.getTime())) {
            searchParams.set("startTime", filters.startDate.toISOString());
        }
        if (filters.endDate && !Number.isNaN(filters.endDate.getTime())) {
            searchParams.set("endTime", filters.endDate.toISOString());
        }
    }

    useEffect(() => {
        if (paramQuery) {
            handleFilters("query", paramQuery);
        }
        if (paramStartDate) {
            try {
                const startDate: Date = new Date(decodeURIComponent(paramStartDate));
                if (Number.isNaN(startDate.getTime())) {
                    handleFilters("startDate", startDate);
                }
            } catch (e) {
                console.error('e', e);
            }
        }
        if (paramEndDate) {
            try {
                const endDate: Date = new Date(decodeURIComponent(paramEndDate));
                if (Number.isNaN(endDate.getTime())) {
                    handleFilters("endDate", endDate);
                }
            } catch (e) {
                console.error('e', e);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramQuery, paramStartDate, paramEndDate]);

    useEffect(() => {
        if (selectedId) {
            loadDetailData(selectedId);
        } else {
            setDetailData(undefined);
        }
    }, [selectedId]);

    return (
        <>
            <Container maxWidth={themeStretch ? false : "lg"}>
                <CustomBreadcrumbs
                    heading={pageName}
                    links={[
                        {
                            name: ROOT_PAGE_NAME,
                            href: paths.dashboard.root,
                        },
                        {
                            name: pageName,
                        },
                    ]}
                    action={
                        <Stack direction="row" spacing={1} />
                    }
                />

                <Card
                    sx={{
                        mt: {xs: 3, md: 5},
                        mb: {xs: 3, md: 5},
                    }}
                >
                    <ChatManageTableToolbar
                        filters={filters}
                        onFilters={handleFilters}
                        dateError={dateError}
                    />

                    {canReset && (
                        <ChatManageFiltersResult
                            filters={filters}
                            onFilters={handleFilters}
                            onResetFilters={handleResetFilters}
                            results={table.metadata.total}
                            sx={{p: 2.5, pt: 0}}
                        />
                    )}

                    <TableContainer sx={{position: "relative", overflow: "unset"}}>
                        <TableSelectedAction
                            dense={table.dense}
                            numSelected={table.selected.length}
                            rowCount={tableData.length}
                            onSelectAllRows={(checked) =>
                                // table.onSelectAllRows(
                                //     checked,
                                //     tableData.map((row) => `${row.id.userId}-${row.id.expertId}`),
                                // )
                                {}
                            }
                            action={
                                <ChatManageTableAction
                                    onOpenSelectedDeleteDiagram={() => openSelectedDeleteDiagram.onTrue()}/>
                            }
                        />

                        <Scrollbar>
                            <Table
                                size={table.dense ? "small" : "medium"}
                                sx={{minWidth: 800}}
                            >
                                <TableHeadCustom
                                    order={table.order}
                                    orderBy={table.orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={table.selected.length}
                                    onSort={table.onSort}
                                    onSelectAllRows={(checked) =>
                                        // table.onSelectAllRows(
                                        //     checked,
                                        //     tableData.map((row) => `${row.id.userId}-${row.id.expertId}`),
                                        // )
                                        {}
                                    }
                                />
                                <TableBody>
                                    {!listDataLoading &&
                                        dataFiltered.map((item: ChatRoomDTO, index: number) => (
                                            <ChatManageTableRow
                                                key={`${item.id.userId}-${item.id.expertId}`}
                                                row={item}
                                                selected={table.selected.includes(0)}
                                                onSelectRow={() => table.onSelectRow(0)}
                                                onViewRow={() => handleOpenView(item.id)}
                                                onEditRow={() => {}}
                                                onRefreshData={() => handleReset()}
                                            />
                                        ))}
                                    {!listDataLoading && table.metadata.total > 0 && (
                                        <TableEmptyRows
                                            height={denseHeight}
                                            emptyRows={emptyRows(
                                                table.page,
                                                table.rowsPerPage,
                                                table.metadata.total,
                                            )}
                                        />
                                    )}
                                    {!listDataLoading && <TableNoData notFound={notFound}/>}
                                    {listDataLoading && (dataFiltered.length === 0) && (
                                        <TableSkeletonAll
                                            size={table.metadata.size}
                                            denseHeight={denseHeight}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        count={table.metadata.total}
                        colSpan={3}
                        page={table.metadata.currentPage - 1}
                        rowsPerPage={table.metadata.size}
                        onPageChange={table.onChangePage}
                        onRowsPerPageChange={table.onChangeRowsPerPage}
                        dense={table.dense}
                        onChangeDense={table.onChangeDense}
                        ActionsComponent={TablePaginationActions}
                    />
                </Card>

            </Container>

            <TableSelectedDeleteDiagram
                open={openSelectedDeleteDiagram.value}
                onClose={() => openSelectedDeleteDiagram.onFalse()}
                onDeleteSelected={() => {
                    handleDeleteRows(table.selected);
                }}
                selected={table.selected}
            />

            {/* === Drawer === */}
            {/* <DrawerWrapper */}
            {/*    title="새글 작성" */}
            {/*    open={openNew.value} */}
            {/*    onClose={handleCloseDrawer} */}
            {/*    children={ */}
            {/*        <ChatManageNewEditForm onEnd={() => { */}
            {/*            handleCloseDrawer(); */}
            {/*            handleReset(); */}
            {/*        }}/>} */}
            {/* /> */}

             <DrawerWrapper
                loading={detailDataLoading}
                title="상세 보기"
                open={openView.value}
                onClose={handleCloseDrawer}
                onEdit={() => {}}
                onDelete={() => {
                    openRowDeleteDiagram.onTrue();
                }}
                children={detailData && <ChatManageViewBody data={detailData}/>}
             />

            {/* <DrawerWrapper */}
            {/*    loading={detailDataLoading} */}
            {/*    title="수정 하기" */}
            {/*    open={openEdit.value} */}
            {/*    onClose={handleCloseDrawer} */}
            {/*    onView={() => handleOpenView(selectedId)} */}
            {/*    onDelete={() => { */}
            {/*        openRowDeleteDiagram.onTrue(); */}
            {/*    }} */}
            {/*    children={detailData && */}
            {/*        <ChatManageNewEditForm */}
            {/*            isEdit */}
            {/*            id={detailData.id} */}
            {/*            currentData={{ */}
            {/*                title: detailData.title, */}
            {/*                template: detailData.template, */}
            {/*                templateCode: detailData.templateCode, */}
            {/*            }} onEnd={() => { */}
            {/*            handleReset(); */}
            {/*            if (selectedId) loadDetailData(selectedId); */}
            {/*        }}/> */}
            {/*    } */}
            {/* /> */}

            {/* === Delete === */}
            {selectedId && (
                <TableRowDeleteDiagram
                    open={openRowDeleteDiagram.value}
                    dataId={`${selectedId.userId}-${selectedId.expertId}`}
                    onClose={() => openRowDeleteDiagram.onFalse()}
                    onDeleteRow={() => handleDeleteRow(`${selectedId.userId}-${selectedId.expertId}`)}
                />
            )}
        </>
    );
}

// ----------------------------------------------------------------------
// const applyFilter = ({
//                          inputData,
//                          comparator,
//                          filters: f,
//                          dateError
//                      }: {
//     inputData: ChatRoomDTO[];
//     comparator: (a: any, b: any) => number;
//     filters: IKkoFilters;
//     dateError: boolean;
// }) => {
//     alert('');
//     // eslint-disable-next-line @typescript-eslint/no-unused-vars
//     const {startDate, endDate} = f;
//     console.log(inputData, 'inputData');
//     console.log(f, 'filter');
//
//     const stabilizedThis = inputData.map((el, index) => [el, index] as const);
//
//     stabilizedThis.sort((a, b) => {
//         const order = comparator(a[0], b[0]);
//         if (order !== 0) return order;
//         return a[1] - b[1];
//     });
//     inputData = stabilizedThis.map((el) => el[0]);
//
//     // if (name) {
//     //   inputData = inputData.filter(
//     //     (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
//     //   );
//     // }
//     //
//     // if (status !== 'all') {
//     //   inputData = inputData.filter((user) => user.status === status);
//     // }
//     //
//     // if (role.length) {
//     //   inputData = inputData.filter((user) => role.includes(user.role));
//     // }
//     if (!dateError) {
//         if (startDate && endDate) {
//             inputData = inputData.filter(
//                 (data) =>
//                     fTimestamp(data.createdTime) >= fTimestamp(startDate) &&
//                     fTimestamp(data.createdTime) <= fTimestamp(endDate),
//             );
//         }
//     }
//     return inputData;
// }
