// @mui
import {Button, Checkbox, Divider, IconButton, MenuItem, Stack, TableCell, TableRow, Typography} from "@mui/material";
// utils
// @types
// components
import Iconify from "src/components/iconify";
import {BannerDTO, ColorMode} from "src/generated/swagger/swagger.api";
import TableRowDeleteDiagram from "src/components/sprintify/table/TableRowDeleteDiagram";
import {Swagger} from "src/utils/API";
import {useSnackbar} from "src/components/snackbar";
import LocalizedText from "src/components/sprintify/localized";
import {fDateTime} from "../../../utils/format-time";
import CustomPopover, {usePopover} from "../../../components/custom-popover";
import {useBoolean} from "../../../hooks/use-boolean";
import Image from "../../../components/image";
import {alpha} from "@mui/material/styles";
import Label from "../../../components/label";

// ----------------------------------------------------------------------

type Props = {
    row: BannerDTO;
    selected: boolean;
    onSelectRow: VoidFunction;
    onViewRow: VoidFunction;
    onEditRow: VoidFunction;
    onRefreshData: VoidFunction;
};

export default function BannerTableRow({
                                           row,
                                           selected,
                                           onSelectRow,
                                           onViewRow,
                                           onEditRow,
                                           onRefreshData,
                                       }: Props) {
    const {enqueueSnackbar} = useSnackbar();
    const {
        id,
        text1,
        text2,
        background,
        backgroundColor,
        buttonTargetBlank,
        colorMode,
        fontColor1,
        fontColor2,
        buttonLabel,
        buttonUrl,
        orderBy,
        createdTime,
        hide
    } = row;
    const openRowDeleteDiagram = useBoolean();
    const openPopover = usePopover();

    const handleDelete = async (_id: number) => {
        try {
            const {data} = await Swagger.api.bannerDelete(_id);
            enqueueSnackbar(data.message, {variant: "success"});
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, {variant: "error"});
        }
        openRowDeleteDiagram.onFalse();
        onRefreshData();
    };

    const colorChip = (color: string) => <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
            width: 20,
            height: 20,
            bgcolor: color,
            borderRadius: '50%',
            border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
        }}
    />

    return (
        <>
            <TableRow hover selected={selected}>
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow}/>
                </TableCell>

                <TableCell>{id}</TableCell>
                <TableCell><Label
                    color="info">{colorMode === ColorMode.MANAGER && '관리자설정'}{colorMode === ColorMode.SYSTEM && '시스템'}</Label></TableCell>

                <TableCell align="center"><Stack direction="row" alignItems="center" spacing={1}>
                    {backgroundColor && colorChip(backgroundColor)}
                    <Image
                        onClick={() => {
                            onViewRow();
                            openPopover.onClose();
                        }}
                        alt={backgroundColor} src={background} sx={{borderRadius: 1, height: 100, width: 1, cursor: "pointer"}}/>
                </Stack>
                </TableCell>

                <TableCell>


                    <Stack direction="row" spacing={1}>
                        {fontColor1 && colorChip(fontColor1)}
                        <Typography variant="body2">
                            <LocalizedText value={text1}/>
                        </Typography>
                    </Stack>
                </TableCell>

                <TableCell align="center">
                    <Stack direction="row" spacing={1}>
                        {fontColor2 && colorChip(fontColor2)}
                        <Typography variant="body2">
                            {text2 && <LocalizedText value={text2}/>}
                        </Typography>
                    </Stack>
                </TableCell>


                <TableCell align="center">

                    {buttonUrl ?
                        <Button color="primary"
                                size="small"
                                target="_blank"
                                href={buttonUrl}
                                variant="contained"><Stack direction="row" spacing={0.5}>{buttonLabel ?
                            <LocalizedText value={buttonLabel}/> : <>-</>}<>{buttonTargetBlank ? '(아웃)' : '(내부)'}</>
                        </Stack></Button> : '-'}
                </TableCell>

                <TableCell align="center">{orderBy}</TableCell>
                <TableCell align="center">{hide ? <Label color="warning">숨김</Label> : '-'}</TableCell>
                <TableCell align="center">{fDateTime(createdTime)}</TableCell>

                <TableCell align="right">
                    <IconButton
                        color={openPopover.open ? "inherit" : "default"}
                        onClick={openPopover.onOpen}
                    >
                        <Iconify icon="eva:more-vertical-fill"/>
                    </IconButton>
                </TableCell>
            </TableRow>

            <CustomPopover
                open={openPopover.open}
                onClose={openPopover.onClose}
                arrow="right-top"
                sx={{width: 160}}
            >
                <MenuItem
                    onClick={() => {
                        onViewRow();
                        openPopover.onClose();
                    }}
                >
                    <Iconify icon="eva:eye-fill"/>
                    상세
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        onEditRow();
                        openPopover.onClose();
                    }}
                >
                    <Iconify icon="mingcute:pencil-line"/>
                    수정
                </MenuItem>

                <Divider sx={{borderStyle: "dashed"}}/>

                <MenuItem
                    onClick={() => {
                        openRowDeleteDiagram.onTrue();
                        openPopover.onClose();
                    }}
                    sx={{color: "error.main"}}
                >
                    <Iconify icon="eva:trash-2-outline"/>
                    삭제
                </MenuItem>
            </CustomPopover>

            <TableRowDeleteDiagram
                open={openRowDeleteDiagram.value}
                dataId={id}
                onClose={() => openRowDeleteDiagram.onFalse()}
                onDeleteRow={() => handleDelete(id)}
            />
        </>
    );
}
