import {LocalizedDTO, ServiceCategoryDTO} from "../../generated/swagger/swagger.api";

export type CategoryItem = {
    id: string;
    title: string;
    hide: boolean;
    group: boolean;
    orderBy?: number;
    countByService?: number;
    children: CategoryItem["id"][] | undefined
};

interface CategoryGeneric<T> {
    id: number | string;
    name: string | LocalizedDTO;
    hide: boolean;
    group: boolean;
    countByService?: number;
    orderBy?: number;
    child?: T[];
}

export function convertToItemEntitiesMap<T extends CategoryGeneric<T>>(categories: T[]): Map<string, CategoryItem> {
    const itemEntitiesMap = new Map<string, CategoryItem>();

    itemEntitiesMap.set("root", {
        id: "root",
        title: "",
        hide: false,
        group: false,
        orderBy: 0,
        countByService: 0,
        children: []
    });

    const addItem = (category: T, currentParentId: string) => {
        console.log(category, 'category');
        const id = `${category.id}`;
        // eslint-disable-next-line no-nested-ternary
        const children = category.child && category.child.length > 0
            ? category.child.map(child => `${child.id}`)
            : category.group ? [] : undefined;
        itemEntitiesMap.set(id, {
            id,
            title: typeof category.name === 'string' ? category.name : category.name.ko,
            hide: category.hide,
            group: category.group,
            orderBy: category.orderBy,
            children
        });
        if (currentParentId === "root") {
            const root = itemEntitiesMap.get("root");
            if (root?.children)
                root.children.push(id);
        }
        category.child?.forEach(child => child && addItem(child, id));
    };

    // 최상위 레벨 카테고리 처리
    categories.forEach(category => addItem(category, "root"));

    return itemEntitiesMap;
}

export function convertFromItemEntitiesMap<T extends CategoryGeneric<T>>(itemEntitiesMap: Map<string, CategoryItem>): T[] {
    // 각 아이템의 자식을 CategoryGeneric<T> 형태로 변환
    function convertChildren(childrenIds: string[] | undefined): T[] {
        if (!childrenIds) return [];
        return childrenIds.map(id => convertItemToCategory(itemEntitiesMap.get(id)!));
    }

    // CategoryItem을 CategoryGeneric<T>로 변환
    function convertItemToCategory(item: CategoryItem): T {
        return {
            id: item.id,
            name: item.title,
            hide: item.hide,
            group: item.group,
            orderBy: item.orderBy,
            countByService: item.countByService,
            child: convertChildren(item.children)
        } as T;
    }

    // 루트 아이템을 찾고, 루트의 자식을 기반으로 최상위 레벨 카테고리를 생성
    const root = itemEntitiesMap.get("root");
    if (!root || !root.children) {
        throw new Error("Invalid item entities map: no root found");
    }
    return convertChildren(root.children);
}


// const initialItemEntitiesMap = new Map<CategoryItem["id"], CategoryItem>([
//     [rootItemId, {id: rootItemId, title: "", children: ["a", "b", "c", "d", "e", "f"]}],
//     ["a", {id: "a", title: "Item A", children: undefined}],
//     ["b", {id: "b", title: "Item B", children: undefined}],
//     ["c", {id: "c", title: "Item C", children: undefined}],
//     ["d", {id: "d", title: "Item D", children: undefined}],
//     ["e", {id: "e", title: "Group E", children: ["e-1", "e-2", "e-3", "e-4", "e-5", "e-6", "e-7"]}],
//     ["e-1", {id: "e-1", title: "Item E - 1", children: undefined}],
//     ["e-2", {id: "e-2", title: "Item E - 2", children: undefined}],
//     ["e-3", {id: "e-3", title: "Item E - 3", children: undefined}],
//     ["e-4", {id: "e-4", title: "Item E - 4", children: undefined}],
//     ["e-5", {id: "e-5", title: "Group E - 5", children: ["e-5-1"]}],
//     ["e-5-1", {id: "e-5-1", title: "Item E - 5 - 1", children: undefined}],
//     ["e-6", {id: "e-6", title: "Group E - 6", children: []}],
//     ["e-7", {id: "e-7", title: "Item E - 7", children: undefined}],
//     ["f", {id: "f", title: "Item F", children: undefined}],
// ]);
