import {Helmet} from "react-helmet-async";
import {SERVICE_NAME} from "src/config-global";
import KkoListView from "src/sections/kko/view/kko-list-view";
import {PageProps} from "../../../types/page";

// ----------------------------------------------------------------------
export default function KkoListPage({pageName}:PageProps) {

    return (
        <>
            <Helmet>
                <title>
                    {pageName} | {SERVICE_NAME}
                </title>
            </Helmet>

            <KkoListView pageName={pageName}/>
        </>
    );
}
