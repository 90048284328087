/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ReqLogin {
  /**
   * 이메일 주소
   * @example "super@super.com"
   */
  email: string;
  /**
   * 비밀번호
   * @example "super12345!"
   */
  password: string;
}

export interface ResLogin {
  /**
   * access_token
   * @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN1cGVyQHN1cGVyLmNvbSIsInN1YiI6MiwiaWF0IjoxNjg2NDE5NDkwLCJleHAiOjE2ODY0MjMwOTB9.OI7uWv3xwA_EiA_MX8H-g6in7zAWtefKQXjxgrrJE7g"
   */
  access_token: string;
  /**
   * refresh_token
   * @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN1cGVyQHN1cGVyLmNvbSIsInN1YiI6MiwiaWF0IjoxNjg2NDE5NDkwLCJleHAiOjE2ODY0MjMwOTB9.OI7uWv3xwA_EiA_MX8H-g6in7zAWtefKQXjxgrrJE7g"
   */
  refresh_token: string;
}

export interface ReqRefreshToken {
  /**
   * refresh_token
   * @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN1cGVyQHN1cGVyLmNvbSIsInN1YiI6MiwiaWF0IjoxNjg2NDE5NDkwLCJleHAiOjE2ODY0MjMwOTB9.OI7uWv3xwA_EiA_MX8H-g6in7zAWtefKQXjxgrrJE7g"
   */
  refresh_token: string;
}

export interface AuthedUser {
  /**
   * User ID
   * @example "1"
   */
  userId: number;
  /**
   * 이메일 주소
   * @example "super@super.com"
   */
  email: string;
  /**
   * 권한
   * @example "[USER]"
   */
  role: string[];
}

export interface VerificationEmbed {
  /**
   * 이메일 인증
   * @example "true"
   */
  email: boolean;
  /**
   * 모바일 인증
   * @example "true"
   */
  mobile: boolean;
}

export interface SocialIdEmbed {
  /**
   * 패이스북 ID
   * @example ""
   */
  facebookId?: string;
  /**
   * 패이스북 이름
   * @example ""
   */
  facebookName?: string;
  /**
   * 구글 ID
   * @example ""
   */
  googleId?: string;
  /**
   * 구글 이름
   * @example ""
   */
  googleName?: string;
  /**
   * 카카오 ID
   * @example ""
   */
  kakaoId?: string;
  /**
   * 카카오 이름
   * @example ""
   */
  kakaoName?: string;
  /**
   * Naver ID
   * @example ""
   */
  naverId?: string;
  /**
   * Naver 이름
   * @example ""
   */
  naverName?: string;
  /**
   * 애플 ID
   * @example ""
   */
  appleId?: string;
  /**
   * 애플 이름
   * @example ""
   */
  appleName?: string;
}

export interface TermsAgreeEmbed {
  /**
   * 서비스 이용약관 동의 (필수)
   * @example "true"
   */
  taService: boolean;
  /**
   * 개인정보 수집이용 동의 (필수)
   * @example "true"
   */
  taPrivacy: boolean;
  /**
   * 본인은 만 19세 이상입니다. (선택)
   * @example "false"
   */
  taAdult?: boolean;
  /**
   * 전자금융거래 이용약관 동의 (필수)
   * @example "false"
   */
  taEft?: boolean;
  /**
   * 위치정보서비스 및 위치기반서비스 이용약관 (필수)
   * @example "false"
   */
  taLocation?: boolean;
  /**
   * 마케팅 활용 항목 (이메일, 문자, 앱푸시, 카카오톡) (선택)
   * @example "false"
   */
  taMarketing: boolean;
  /**
   * 마케팅 수신 동의 날짜 (동의시에만 사용)
   * @format date-time
   * @example ""
   */
  taMarketingRcvTime?: string;
  /**
   * 문자 발송 수신 동의 (선택)
   * @example "false"
   */
  smsRcv?: boolean;
  /**
   * SMS 발송 수신 동의 날짜 (동의시에만 사용)
   * @format date-time
   * @example ""
   */
  smsRcvTime?: string;
  /**
   * 이메일 발송 수신 동의 (선택)
   * @example "false"
   */
  emailRcv?: boolean;
  /**
   * 이메일 발송 수신 동의 날짜 (동의시에만 사용)
   * @format date-time
   * @example ""
   */
  emailRcvTime?: string;
  /**
   * 앱푸시 발송 수신 동의 (선택)
   * @example "false"
   */
  pushRcv?: boolean;
  /**
   * 푸시 발송 수신 동의 날짜 (동의시에만 사용)
   * @format date-time
   * @example ""
   */
  pushRcvTime?: string;
  /**
   * 카카오 발송 수신 동의 (선택)
   * @example "false"
   */
  kakaoRcv?: boolean;
  /**
   * 카카오 알림톡 발송 수신 동의 날짜 (동의시에만 사용)
   * @format date-time
   * @example ""
   */
  kakaoRcvTime?: string;
}

export interface Profile {
  /**
   * User ID
   * @example "1"
   */
  id: number;
  /**
   * 프로필이미지 URL
   * @example ""
   */
  image: string;
  /**
   * 이메일 주소
   * @example "super@super.com"
   */
  email: string;
  /**
   * 닉네임(별명)
   * @example "별명"
   */
  nickname: string;
  /**
   * 이름
   * @example "길동"
   */
  firstName: string;
  /**
   * 성
   * @example "홍"
   */
  lastName: string;
  /**
   * 성명
   * @example "홍길동"
   */
  fullName: string;
  /**
   * 휴대폰번호
   * @example "01041533582"
   */
  mobile: string;
  /**
   * 권한
   * @example "[USER]"
   */
  authorities: string[];
  /**
   * 인증 정보(모바일, 이메일)
   * @example "인증 정보"
   */
  verification: VerificationEmbed;
  /**
   * 패스워드 보유의 유/무
   * @example "true"
   */
  hasPassword: boolean;
  /**
   * 소셜 로그인 정보
   * @example ""
   */
  socialId: SocialIdEmbed;
  /**
   * 약관 동의 정보
   * @example ""
   */
  termsAgree: TermsAgreeEmbed;
  /**
   * 코렉터(전문가) 모드
   * @example ""
   */
  expertMode: boolean;
}

export interface SMS {
  /** 수신번호 */
  destPhone: string;
  /** 제목 */
  subject: string;
  /** 메시지 */
  msgBody: string;
}

export type DirectSendSMSResult = object;

export interface ResResult {
  /**
   * 결과 성공 여부
   * @example "true"
   */
  result: boolean;
  /**
   * 결과 메시지
   * @example "성공하였습니다./실패하였습니다."
   */
  message: string;
}

export interface ReqFindPwd {
  /**
   * 이메일 주소
   * @example "super@super.com"
   */
  email: string;
}

export interface ReqChkFindPwd {
  /**
   * 이메일 주소
   * @example "super@super.com"
   */
  email: string;
  /**
   * 인증 코드
   * @example "12345"
   */
  code: string;
}

export interface ReqChangeFindPwd {
  /**
   * 이메일 주소
   * @example "super@super.com"
   */
  email: string;
  /**
   * 인증 코드
   * @example "12345"
   */
  code: string;
  /**
   * 새 비밀번호
   * @example ".."
   */
  newPwd: string;
  /**
   * 새 비밀번호 확인
   * @example ".."
   */
  confirmPwd: string;
}

export interface KeyMessage {
  /**
   * key
   * @example ""
   */
  key: string;
  /**
   * value
   * @example ""
   */
  message: string;
}

export interface SNSReqBody {
  /**
   * sns 유형
   * @example ""
   */
  snsType?: KeyMessage;
  /**
   * 코드
   * @example ""
   */
  code: string;
  /**
   * 리다이렉트 URI
   * @example ""
   */
  redirectUri: string;
  /**
   * state
   * @example ""
   */
  state?: string;
  /**
   * assess token
   * @example ""
   */
  idToken?: string;
}

export interface SNSResBody {
  /** @example "" */
  status: KeyMessage;
  /** @example "" */
  access_token: string;
  /**
   * 리프래시 토큰
   * @example ""
   */
  refresh_token: string;
  /** @example "" */
  google_access_token: string;
  /** @example "" */
  redirectUri: string;
  /** @example "" */
  snsType: KeyMessage;
  /** @example "" */
  id: string;
  /** @example "" */
  name: string;
  /** @example "" */
  nickname: string;
  /** @example "" */
  email: string;
  /** @example "" */
  image: string;
  /** @example "" */
  verifiedEmail: boolean;
  /** @example "" */
  birth: string;
  /** @example "" */
  result: boolean;
}

export interface GooglePojo {
  /** @example "" */
  id: string;
  /** @example "" */
  email: string;
  /** @example "" */
  verified_email: boolean;
  /** @example "" */
  name: string;
  /** @example "" */
  given_name: string;
  /** @example "" */
  family_name: string;
  /** @example "" */
  link: string;
  /** @example "" */
  picture: string;
  /** @example "" */
  accessToken: string;
}

export interface SNSConnect {
  /**
   * SNS Key
   * @example ""
   */
  key: string;
  /**
   * SNS Key
   * @example ""
   */
  nickname?: string;
  /**
   * SNS Key
   * @example "FACEBOOK,"
   */
  email: string;
  /**
   * SNS Key
   * @example "FACEBOOK,"
   */
  password: string;
}

export interface LocalizedDTO {
  /** 한국어 */
  ko: string;
  /** 영어 */
  en: string;
  /** 중국어 간체 */
  zhCn: string;
  /** 중국어 번체 */
  zhTw: string;
  /** 일본어 */
  ja: string;
}

export interface FileModel {
  /** @example "" */
  id?: number;
  /** @example "" */
  originalFilename?: string;
  /** @example "" */
  filename?: string;
  /** @example "" */
  size?: number;
  /** @example "" */
  mimeType?: string;
  /** @example "" */
  etag?: string;
  /** @example "" */
  url?: string;
}

export interface BoardCategoryDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /** icon */
  iconImage?: FileModel;
  /** 카테고리 명 */
  name: string | LocalizedDTO;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime?: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy?: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime?: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy?: number;
  /**
   * 게시판 유형
   * @example ""
   */
  type: string;
  /**
   * 순서
   * @example ""
   */
  orderBy: number;
}

export interface BoardDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /**
   * 카테고리
   * @example ""
   */
  categories: BoardCategoryDTO[];
  /** 제목 */
  title: string | LocalizedDTO;
  /** 내용 */
  content: string | LocalizedDTO;
  /**
   * 게시판 유형
   * @example ""
   */
  type: string;
  /**
   * 상단 고정
   * @example ""
   */
  top: boolean;
  /**
   * 페이지 뷰
   * @example ""
   */
  pageView: number;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
}

export interface PaginationMetadata {
  /**
   * 현 페이지 데이터 조회 수
   * @example "10"
   */
  itemCount: number;
  /**
   * 데이터 총 수
   * @example "0"
   */
  total: number;
  /**
   * 페이지 사이즈
   * @example "10"
   */
  size: number;
  /**
   * 현재 페이지
   * @example "1"
   */
  currentPage: number;
  /**
   * 페이지 총 수
   * @example "0"
   */
  totalPages: number;
}

export interface BoardPage {
  /** 데이터 */
  items: BoardDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface BoardCategoryList {
  /** 데이터 */
  items: BoardCategoryDTO[];
}

export interface ReqBoard {
  /** 제목 */
  title: LocalizedDTO;
  /** 내용 */
  content: LocalizedDTO;
  /** 카테고리 ID */
  categoryIds: number[];
  /** 상단고정 */
  top: boolean;
}

export interface SettingDTO {
  key: string;
  value: object;
}

export interface BooleanSetting {
  value: boolean;
}

export interface LanguageSetting {
  ko: boolean;
  en: boolean;
  zhCn: boolean;
  zhTw: boolean;
  ja: boolean;
}

export interface ReqBoardCategory {
  /** 제목 */
  name: LocalizedDTO;
  /**
   * 게시판 유형
   * @example ""
   */
  type: string;
}

export interface ReqChangeOrder {
  /**
   * 순서변경
   * @example "UP"
   */
  mode: string;
}

export enum PolicyType {
  TERMS = "TERMS",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  MARKETING = "MARKETING",
  LEAVE = "LEAVE",
}

export interface PolicyDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /** 약관 유형 */
  type: PolicyType;
  /**
   * 내용
   * @example ""
   */
  content: string;
  /**
   * 시행일
   * @example "20230713"
   */
  effectiveDate: number;
  /**
   * 국가
   * @example "KR"
   */
  countryCode: string;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
}

export interface PolicyPage {
  /** 데이터 */
  items: PolicyDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface EnumValue {
  /**
   * key
   * @example ""
   */
  key: string;
  /**
   * value
   * @example ""
   */
  value: string;
}

export interface ReqPolicy {
  /** 약관 유형 */
  type: PolicyType;
  /** 내용 */
  content: string;
  /**
   * 시행일
   * @example "20230713"
   */
  effectiveDate: number;
}

export interface SESSender {
  /** @example "" */
  to: string[];
  /** @example "" */
  subject: string;
  /** @example "" */
  html: string;
}

export interface Address {
  /**
   * 주소1
   * @example "서울특별시 마포구 독막로9길 18"
   */
  postalCode: string;
  /**
   * 주소2 상세주소
   * @example "201호"
   */
  address1: string;
  /**
   * 우편번호
   * @example "34152"
   */
  address2: string;
}

export enum GenderType {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export interface LeaveMeta {
  /**
   * 탈퇴여부
   * @example ""
   */
  leave: boolean;
  /**
   * 탈퇴이유
   * @example ""
   */
  leaveReason: string;
  /**
   * 탈퇴상세이유
   * @example ""
   */
  leaveDetailReason: string;
  /**
   * 탈퇴시간
   * @format date-time
   * @example ""
   */
  removePrivacyTime: string;
}

export enum AuthorityType {
  SUPER = "SUPER",
  ADMIN = "ADMIN",
  USER = "USER",
}

export interface AuthorityDTO {
  /** 역할 */
  role: AuthorityType;
}

export enum Status {
  INIT = "INIT",
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCEL = "CANCEL",
}

export interface ServiceCategoryDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /** 아이콘 */
  iconImage?: FileModel;
  /** 카테고리 명 */
  name: string | LocalizedDTO;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime?: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy?: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime?: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy?: number;
  /**
   * 순서
   * @example 0
   */
  orderBy?: number;
  /** 자식 카테고리 */
  child?: ServiceCategoryDTO[];
  /**
   * 그룹
   * @example false
   */
  group: boolean;
  /**
   * 숨김
   * @example false
   */
  hide: boolean;
  /**
   * 서비스 등록 수
   * @example 0
   */
  countByService: number;
  /** 메인 노출 */
  mainView: boolean;
}

export interface ExpertInfoEmbed {
  /** 코렉터 소개 */
  introduce: string;
  /** 간략 소개 회사명 */
  simpleCompanyName?: string | null;
  /** 간략 소개 연차 */
  simpleExperience?: string | null;
  /** 카테고리 Id 목록 */
  categoryIds?: number[] | null;
}

export interface FileEntity {
  /** original filename */
  originalFilename?: string;
  /** new filename */
  filename?: string;
  /** file size */
  size?: number;
  /** mimeType */
  mimeType?: string;
  /** Etag */
  etag?: string;
  /** URL */
  url?: string;
}

export interface ExpertCompanyEmbed {
  /** 경력사항 */
  employmentHistories?: string[];
  /** 회사 이메일 */
  compEmail?: string;
  /** 회사 이메일 인증 여부 */
  compEmailVrf?: boolean;
  /** 인증파일1 (재직 증명서 파일 주소) ID */
  compAuthFile1Id?: number;
  /** 파일 */
  compAuthFile1: FileEntity | null;
}

export interface ExpertWithdrawalEmbed {
  /** 은행명 */
  bank: string;
  /** 계좌번호 */
  accountNo: string;
  /** 예금주 */
  accountHolder: string;
}

export interface ExpertDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /**
   * Version
   * @example ""
   */
  version: number;
  /** 코렉터 등록 상태 */
  createStatus: Status;
  /** 코렉터 수정 상태 */
  updateStatus: Status;
  /** 코렉터 등록 및 수정 반려 사유 */
  rejectMessage: string;
  /** 카테고리 선택 */
  categories: ServiceCategoryDTO[];
  /** 전문가 상세 정보 */
  info: ExpertInfoEmbed;
  /** 회사 상세 정보 */
  company: ExpertCompanyEmbed;
  /** 정산 정보 */
  withdrawal: ExpertWithdrawalEmbed;
  /**
   * 사용자 ID
   * @example ""
   */
  userId: number;
  /**
   * 코레터 사용자 명
   * @example ""
   */
  userNickname: string | null;
  /** 코레터 사용자 프로필 이미지 */
  userProfile: string | null;
  /** 코레터 사용자 프로필 이미지 */
  userEmail: string | null;
  /**
   * 등록: true, 수정: false
   * @example ""
   */
  originMode: boolean;
}

export interface UserDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /**
   * 이메일
   * @example "test@test.com"
   */
  email: string;
  /**
   * 닉네임
   * @example "닉네임"
   */
  nickname: string;
  /**
   * 이름
   * @example "길동"
   */
  firstName: string;
  /**
   * 성
   * @example "홍"
   */
  lastName: string;
  /**
   * 성명
   * @example "홍길동"
   */
  fullName: string;
  /**
   * 국가코드
   * @example "KR"
   */
  countryCode: string;
  /**
   * 휴대폰번호
   * @example "01012341234"
   */
  mobile: string;
  /** 주소 */
  address: Address;
  /**
   * 비밀번호
   * @example "dasklje123"
   */
  password: string;
  /**
   * 이미지 URL
   * @example ""
   */
  image?: string;
  /** 성별 */
  gender: GenderType;
  /**
   * 생년월일
   * @example "19870123"
   */
  birth?: string;
  /** 약관 동의 정보 */
  termsAgree: TermsAgreeEmbed;
  /** 인증 동의 정보 */
  verification: VerificationEmbed;
  /** SNS 계정 정보 */
  socialId: SocialIdEmbed;
  /** 탈퇴 정보 */
  leaveMeta: LeaveMeta;
  /** 차단여부 */
  blocked: boolean;
  /** 권한 */
  authorities: AuthorityDTO[];
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
  /** 전문가 */
  oriExpert: ExpertDTO | null;
  /** 전문가 */
  copyExpert: ExpertDTO | null;
}

export interface UserPage {
  /** 데이터 */
  items: UserDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface ExpertCountStatus {
  /**
   * 대기
   * @example 0
   */
  init: number | null;
  /**
   * 요청
   * @example 0
   */
  requested: number | null;
  /**
   * 승인
   * @example 0
   */
  approved: number | null;
  /**
   * 거절
   * @example 0
   */
  rejected: number | null;
  /**
   * 취소
   * @example 0
   */
  cancel: number | null;
}

export interface ExpertCount {
  /**
   * 일반
   * @example 0
   */
  normal: number | null;
  /** 등록상태 */
  createStatus: ExpertCountStatus;
  /** 수정상태 */
  updateStatus: ExpertCountStatus;
}

export interface AdminReqExpert {
  /** 전문가 상세 정보 */
  info: ExpertInfoEmbed;
  /** 회사 상세 정보 */
  company: ExpertCompanyEmbed;
  /** 정산 정보 */
  withdrawal: ExpertWithdrawalEmbed;
  /**
   * 변경할 유저 ID
   * @example ""
   */
  userId: number;
}

export interface ChangeStatus {
  /** 오리진 전문가 ID */
  oriExpertId: number;
  /** version */
  version: number;
  /** 코렉터 등록 상태 */
  status: Status;
  /** 거절 사유 */
  rejectMessage: string | null;
}

export interface Bank {
  /**
   * CODE
   * @example "001"
   */
  code: string;
  /**
   * 은행명
   * @example "신한은행"
   */
  name: string;
}

export enum ServiceType {
  SELF_INFO = "SELF_INFO",
  INTERVIEW_SCRIPT = "INTERVIEW_SCRIPT",
}

export enum ServiceStatus {
  INIT = "INIT",
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  LIMITED = "LIMITED",
}

export interface ServiceInfoEmbed {
  /** 서비스 명 */
  title: string | null;
  /** 서비스 소개 */
  introduce: string | null;
  /** 키워드 */
  keywords: string | null;
}

export interface TimePriceEntity {
  /** 시간당 */
  time: number;
  /** 시간당 금액 */
  timePrice: number;
}

export interface ItemPriceEntity {
  /** 문항당 */
  itemCount: number;
  /** 시간당 금액 */
  itemPrice: number;
}

export interface ServicePriceDto {
  /** 기본 가격 (VAT포함) */
  price: number | null;
  /** 시간 가격 설정 */
  timePrices: TimePriceEntity[] | null;
  /** 문항 가격 설정 */
  itemPrices: ItemPriceEntity[] | null;
}

export interface TimePriceDTO {
  /** 시간당 ID */
  id: number;
  /** 시간당 */
  time: number;
  /** 시간당 금액 */
  timePrice: number;
}

export interface ItemPriceDTO {
  /** 문항당 ID */
  id: number;
  /** 문항당 */
  itemCount: number;
  /** 시간당 금액 */
  itemPrice: number;
}

export interface ServiceDTO {
  /** ID */
  id: number;
  /** 판매건순 */
  salesCount: number;
  /** Version */
  version: number;
  /** 서비스 등록 상태 */
  createStatus: ServiceStatus;
  /** 서비스 수정 상태 */
  updateStatus: ServiceStatus;
  /** 서비스 유형 */
  type: ServiceType;
  /** 서비스 등록 및 수정 반려 사유 */
  rejectMessage: string;
  /** 서비스 판매 제한 사유 */
  limitMessage: string;
  /** 서비스 상세 정보 */
  info: ServiceInfoEmbed;
  /** 서비스 가격정보 */
  price: ServicePriceDto;
  /** 수정·검수·환불 정책 */
  policyInfo: string | null;
  /** 썸네일 이미지 */
  thumbnail?: FileModel | null;
  /** 상세 이미지 */
  detailImages?: FileModel[] | null;
  /** 카테고리 목록 */
  categories: ServiceCategoryDTO[];
  /**
   * 등록: true, 수정: false
   * @example ""
   */
  originMode: boolean;
  /**
   * 정지: true, 운영: false
   * @example ""
   */
  stopMode: boolean;
  /**
   * 메인노출1 여부
   * @example ""
   */
  isMainView01: boolean;
  /**
   * 메인노출2 여부
   * @example ""
   */
  isMainView02: boolean;
  /**
   * 내 서비스
   * @example ""
   */
  myService: boolean;
  /**
   * 위시여부
   * @example false
   */
  wished: boolean;
  /**
   * 총 위시수
   * @example false
   */
  wishCount: number;
  /**
   * 총 리뷰 수
   * @example 10
   */
  totalReviews: number;
  /**
   * 평균 별점
   * @example 4.5
   */
  averageRating: number;
  /**
   * 코레터 ID
   * @example ""
   */
  expertId: number;
  /**
   * 코레터
   * @example ""
   */
  expert: ExpertDTO;
  /** 시간 가격 설정 */
  timePrices: TimePriceDTO[] | null;
  /** 문항 가격 설정 */
  itemPrices: ItemPriceDTO[] | null;
  /** 서비스 수정 */
  copyService: ServiceDTO | null;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
}

export interface ServicePage {
  /** 데이터 */
  items: ServiceDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export enum Type {
  SELF_INFO = "SELF_INFO",
  INTERVIEW_SCRIPT = "INTERVIEW_SCRIPT",
}

export interface AdminReqService {
  /** 서비스 상세 정보 */
  id?: number | null;
  /** 서비스 유형 */
  type: Type;
  /** 서비스 상세 정보 */
  info: ServiceInfoEmbed;
  /** 서비스 가격정보 */
  price: ServicePriceDto;
  /** 수정·검수·환불 정책 */
  policyInfo?: string | null;
  /** 썸네일 이미지 */
  thumbnail?: FileModel | null;
  /** 상세 이미지 */
  detailImages?: FileModel[] | null;
  /** 카테고리 Id 목록 */
  categoryIds: number[];
  /**
   * 변경할 유저 ID
   * @example ""
   */
  userId: number;
}

export interface ServiceCountStatus {
  /**
   * 대기
   * @example 0
   */
  init: number | null;
  /**
   * 요청
   * @example 0
   */
  requested: number | null;
  /**
   * 승인
   * @example 0
   */
  approved: number | null;
  /**
   * 거절
   * @example 0
   */
  rejected: number | null;
  /**
   * 정지
   * @example 0
   */
  limited: number | null;
  /**
   * 중단
   * @example 0
   */
  stopped: number | null;
}

export interface ServiceCount {
  /**
   * 일반
   * @example 0
   */
  normal: number | null;
  /** 등록상태 */
  createStatus: ServiceCountStatus;
  /** 수정상태 */
  updateStatus: ServiceCountStatus;
}

export interface ServiceChangeStatus {
  /** 오리진 서비스 ID */
  oriServiceId: number;
  /** Version */
  version: number;
  /** 서비 등록 상태 */
  status: ServiceStatus;
  /** 거절 사유 */
  rejectMessage: string | null;
  /** 판매제한 사유 */
  limitMessage?: string | null;
}

export interface AdminStopModeBody {
  /**
   * 변경할 유저 ID
   * @example ""
   */
  userId: number;
}

export interface ServiceMainViewBody {
  /** 오리진 서비스 ID */
  oriServiceId: number;
  /**
   * 메인타입
   * @example ""
   */
  type: string;
  /**
   * 노출여부(true/false)
   * @example ""
   */
  value: boolean;
}

export interface ServiceFee {
  /** 판매자 부담 수수료 */
  fee: number;
  /** 사용자 부담 수수료 */
  userFee: number;
}

export enum OrderStatus {
  INIT = "INIT",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  CANCEL = "CANCEL",
  CONFIRM = "CONFIRM",
}

export enum PayMethod {
  CARD = "CARD",
  TRANS = "TRANS",
  VBANK = "VBANK",
  TEST = "TEST",
}

export interface TxEmbed {
  /** TX ID */
  trno?: string;
  /** TX 상태 */
  txStatus?: string;
  /** TX 결과 이유 */
  txReason?: string;
  /** TX 결과 JSON */
  txJson?: object;
}

export enum TaskStatus {
  INIT = "INIT",
  WORKING = "WORKING",
  COMPLETED = "COMPLETED",
  REJECT = "REJECT",
  CONFIRM = "CONFIRM",
}

export type Any = object;

export interface ScriptDTO {
  /** ID */
  id: number;
  /** 제목 */
  title: string | null;
  /** 내용 */
  contents?: string | null;
  /** 메모 */
  memo?: string | null;
  /** 내용 */
  oriContent?: Any | null;
  /** 내용 */
  editingContent?: Any | null;
  /** 작업 */
  taskId: number | null;
}

export interface TaskDTO {
  /**
   * Order ID
   * @example ""
   */
  orderId: string;
  /**
   * Order Status
   * @example ""
   */
  orderStatus: string | null;
  /**
   * ID
   * @example ""
   */
  id: number;
  /** 작업 상태 */
  status: TaskStatus;
  /** 작업 제목 */
  title: string;
  /** @example "" */
  service: ServiceDTO | null;
  /** @example "" */
  user: Profile;
  /** @example "" */
  itemPrice: ItemPriceDTO | null;
  /** @example "" */
  timePrice: TimePriceDTO | null;
  /** @example "" */
  scripts: ScriptDTO[] | null;
  /**
   * 등록일
   * @format date-time
   */
  createdTime: string;
  /**
   * 완료 예정 시간
   * @format date-time
   */
  estimatedCompletionTime: string | null;
  /**
   * 시작 제약 시간
   * @format date-time
   */
  startLimitTime: string | null;
  /**
   * 완료 시간
   * @format date-time
   */
  completedTime: string | null;
  /**
   * 작업 거절 시간
   * @format date-time
   */
  canceledTime: string | null;
  /**
   * 확정 시간
   * @format date-time
   */
  confirmTime: string | null;
  /**
   * 리뷰가 포함 여부
   * @example false
   */
  hasReview: boolean;
  /** 거절사유 */
  rejectMessage?: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
  /** 정산 여부 */
  calculate: boolean;
}

export interface OrderDTO {
  /** @example 0 */
  id: number;
  /** @example "" */
  orderId: string;
  /** @example "" */
  title: string;
  /** @example "" */
  paymentTime?: string;
  /**
   * 주문 취소 시간
   * @format date-time
   */
  canceledTime?: string;
  /**
   * 정산 완료 시간
   * @format date-time
   */
  calculateTime?: string;
  /** 정산 완료 시간 */
  calculate: boolean;
  /** 결제 상태 */
  status: OrderStatus;
  /** 결제 유형 */
  payMethod: PayMethod;
  /** @example "" */
  service: ServiceDTO;
  /** @example "" */
  itemPrice: ItemPriceDTO;
  /** @example "" */
  timePrice: TimePriceDTO;
  /** @example "" */
  tx: TxEmbed;
  /** @example "" */
  taskId?: number;
  task?: TaskDTO;
  /** 구매자 정보 */
  user: Profile;
  /** @example "" */
  amount: number;
  /**
   * 서비스 금액
   * @example ""
   */
  serviceAmount: number;
  /**
   * 추가 금액
   * @example ""
   */
  addAmount: number;
  /**
   * 수수료
   * @example ""
   */
  feeAmount: number;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
  /**
   * 판매 금액
   * @example ""
   */
  buyAmount: number;
  /**
   * 정산받을 금액
   * @example ""
   */
  calculateAmount: number;
}

export interface OrderPage {
  /** 데이터 */
  items: OrderDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface OrderCount {
  /**
   * 전체
   * @example 0
   */
  total: number;
  /**
   * 대기중
   * @example 0
   */
  init: number;
  /**
   * 결제완료
   * @example 0
   */
  complete: number;
  /**
   * 실패
   * @example 0
   */
  failed: number;
  /**
   * 취소
   * @example 0
   */
  cancel: number;
  /**
   * 확정
   * @example 0
   */
  confirm: number;
}

export interface CalculateDTO {
  /** expert */
  expert: ExpertDTO;
  /** orders */
  orders: OrderDTO[];
}

export interface KsnetCancelData {
  /** PG거래번호 */
  tid: string;
  /** 거래일시(yyyyMMddHHmmss) */
  tradeDateTime: string;
  /** 취소된금액 */
  cancelAmount: string;
  /** 응답코드 */
  respCode: string;
  /** 응답메시지 */
  respMessage: string;
  /** 가맹점데이터, 구체적인 타입이 명시되지 않아 any로 지정 */
  payload: object;
  /** 발급사타입 */
  issuerCardType: string;
  /** 발급사명 */
  issuerCardName: string;
  /** 매입사타입 */
  purchaseCardType: string;
  /** 매입사명 */
  purchaseCardName: string;
  /** 승인번호 */
  approvalNumb: string;
  /** 카드번호 */
  cardNumb: string;
  /** 유효기간 */
  expiryDate: string;
  /** 할부개월수 */
  installMonth: string;
}

export interface KsnetCancelResponse {
  /** API 요청 고유값 */
  aid: string;
  /** API 응답 코드 */
  code: string;
  /** API 응답 메시지 */
  message: string;
  /** DATA */
  data: KsnetCancelData;
}

export interface AnalyticsTotalOrderReportRow {
  date: string;
  orders: number;
}

export interface AnalyticsTotalOrderReport {
  data: AnalyticsTotalOrderReportRow[];
  totalOrders: number;
}

export interface AnalyticsTotalPaymentsReportRow {
  date: string;
  payments: number;
}

export interface AnalyticsTotalPaymentsReport {
  data: AnalyticsTotalPaymentsReportRow[];
  totalPayments: number;
}

export interface UpdateAdminNickname {
  /**
   * 변경할 닉네임
   * @example ""
   */
  nickname: string;
  /**
   * 변경할 유저 ID
   * @example ""
   */
  userId: number;
}

export interface UpdateAdminEmail {
  /**
   * 변경할 이메일 주소
   * @example ""
   */
  email: string;
  /**
   * 변경할 유저 ID
   * @example ""
   */
  userId: number;
}

export interface UpdateVerification {
  /**
   * 변경할 유저 ID
   * @example ""
   */
  userId: number;
  /**
   * 인증여부 true/false
   * @example ""
   */
  value: boolean;
}

export interface VerificationUpdatedBody {
  /**
   * 인증여부(true/false)
   * @example ""
   */
  value: boolean;
  /**
   * 수신시간
   * @format date-time
   * @example ""
   */
  date: string;
}

export interface UpdateAdminFullNaMe {
  /**
   * 변경할 유저 ID
   * @example ""
   */
  userId: number;
  /**
   * 변경할 이름
   * @example ""
   */
  fullName: string;
}

export interface UpdateAdminMobile {
  /**
   * 휴대폰번호
   * @example ""
   */
  mobile: string;
  /**
   * 변경할 유저 ID
   * @example ""
   */
  userId: number;
}

export interface UpdateAdminPassword {
  /**
   * 변경할 유저 ID
   * @example ""
   */
  userId: number;
  /**
   * 신규 비밀번호
   * @example ""
   */
  newPassword: string;
  /**
   * 신규 비밀번호 확인
   * @example ""
   */
  confirmPassword: string;
}

export interface UpdateBoolean {
  /**
   * true/false
   * @example ""
   */
  value: boolean;
}

export interface MarketingUpdatedBody {
  /**
   * 수신여부(true/false)
   * @example ""
   */
  value: boolean;
  /**
   * 수신시간
   * @format date-time
   * @example ""
   */
  date: string;
}

export interface UpdateAdminTerms {
  /**
   * 변경할 유저 ID
   * @example ""
   */
  userId: number;
  /**
   * 동의 여부 true/false
   * @example ""
   */
  value: boolean;
}

export interface CreatePassword {
  /**
   * 비밀번호
   * @example ""
   */
  password: string;
}

export interface TaskPage {
  /** 데이터 */
  items: TaskDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface TaskCount {
  total: number;
  init: number;
  working: number;
  complete: number;
  reject: number;
  confirm: number;
  delay: number;
}

export interface ReviewId {
  /**
   * user ID
   * @example 0
   */
  userId: number;
  /**
   * service ID
   * @example 0
   */
  taskId: number;
}

export interface ReviewDTO {
  /**
   * Review ID
   * @example 0
   */
  id: ReviewId;
  /**
   * 별점
   * @example 0
   */
  rating: number;
  /**
   * 후기
   * @example ""
   */
  message: string;
  /**
   * 전문가 답글
   * @example ""
   */
  reply: string;
  /**
   * user nickname
   * @example ""
   */
  userNickname: string;
  /**
   * user Image
   * @example ""
   */
  userImage: string;
  /** @example "" */
  task: TaskDTO;
  /** @example "" */
  images: FileModel[];
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 답글 등록일
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 메인노출
   * @example false
   */
  isMainView: boolean;
}

export interface ReviewPage {
  /** 데이터 */
  items: ReviewDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface MainViewUpdatedBody {
  /**
   * true/false
   * @example ""
   */
  value: boolean;
}

export interface ReqServiceCategory {
  /** 제목 */
  name: LocalizedDTO;
  /** 그룹 */
  group: boolean;
  /** 숨김 */
  hide: boolean;
  /** 아이콘 */
  iconImage?: FileModel;
  /** 메인 노출 */
  mainView: boolean;
}

export interface ServiceCategoryList {
  /** 데이터 */
  items: ServiceCategoryDTO[];
}

export interface FaqCategoryDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /** icon */
  iconImage?: FileModel;
  /** 카테고리 명 */
  name: string | LocalizedDTO;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime?: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy?: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime?: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy?: number;
  /**
   * 순서
   * @example ""
   */
  orderBy: number;
}

export interface FaqDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /**
   * 카테고리
   * @example ""
   */
  categories: FaqCategoryDTO[];
  /** 제목 */
  title: string | LocalizedDTO;
  /** 내용 */
  content: string | LocalizedDTO;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
}

export interface FaqPage {
  /** 데이터 */
  items: FaqDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface ReqFaq {
  /** 제목 */
  title: LocalizedDTO;
  /** 내용 */
  content: LocalizedDTO;
  /** 카테고리 ID */
  categoryIds: number[];
}

export interface ContactDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /** 제목 */
  title: string;
  /** 내용 */
  content: string;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
}

export interface ContactPage {
  /** 데이터 */
  items: ContactDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface ReqContact {
  /** 제목 */
  title: string;
  /** 내용 */
  content: string;
}

export interface KkoDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /** 템플릿 코드 */
  templateCode: string;
  /** 템플릿 */
  template: string;
  /** 템플릿 제목 */
  title: string;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
}

export interface KkoPage {
  /** 데이터 */
  items: KkoDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface ReqKko {
  /** 템플릿 코드 */
  templateCode: string;
  /** 템플릿 */
  template: string;
  /** 템플릿 제목 */
  title: string;
}

export interface ReqSendTest {
  /** 템플릿 코드 */
  templateCode: string;
  /** 수신번호 */
  mobile: string;
}

export interface ChatRoomId {
  /**
   * ID
   * @example ""
   */
  userId: number;
  /**
   * ID
   * @example ""
   */
  expertId: number;
}

export enum ChatMessageType {
  TXT = "TXT",
  IMG = "IMG",
  HWP = "HWP",
  PDF = "PDF",
  EXC = "EXC",
  PPT = "PPT",
  WRD = "WRD",
  ORD = "ORD",
}

export interface ChatMessageDTO {
  /**
   * ID
   * @example ""
   */
  id: number;
  /** @example "" */
  chatRoomId: ChatRoomId;
  /** @example "" */
  user: Profile;
  /** @example "" */
  expert: ExpertDTO;
  order: OrderDTO | null;
  /** @example "" */
  message: string | null;
  /** @example "" */
  file: FileModel | null;
  /** 메시지 유형 */
  type: ChatMessageType;
  /** @example "" */
  read: boolean;
  /**
   * @format date-time
   * @example ""
   */
  createdTime: string;
}

export interface ChatRoomDTO {
  /**
   * {userId}-{expertId}
   * @example ""
   */
  id: ChatRoomId;
  /**
   * ID
   * @example ""
   */
  userId: number;
  /**
   * ID
   * @example ""
   */
  expertId: number;
  /** @example "" */
  user: Profile;
  /** @example "" */
  expert: ExpertDTO;
  /** @example "" */
  services: string[];
  /** @example "" */
  expertMode: boolean;
  /**
   * 마지막 대화
   * @format date-time
   * @example "2024-04-16T09:15:07.673Z"
   */
  latestTime?: string;
  /**
   * 마지막 대화
   * @example ""
   */
  latestMessage?: string;
  /**
   * 대화 수
   * @example 0
   */
  countByMessage?: number;
  /**
   * 읽음
   * @example ""
   */
  hasNotRead: boolean;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
  /** 수정한 계정 ID */
  chatMessages: ChatMessageDTO[];
}

export interface ChatRoomPage {
  /** 데이터 */
  items: ChatRoomDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface GaDailyUserReportRow {
  date: string;
  activeUsers: number;
  newUsers: number;
  createdUsers: number;
  sessions: number;
}

export interface GaDailyUserReport {
  data: GaDailyUserReportRow[];
  activeUsers: number;
}

export interface GaDauPerMauReportRow {
  activeUsers: number;
  dauPerMau: number;
}

export interface GaDauPerMauReport {
  data: GaDauPerMauReportRow;
}

export interface GaDeviceCategoryReportRow {
  device: string;
  activeUsers: number;
}

export interface GaDeviceCategoryReport {
  data: GaDeviceCategoryReportRow[];
}

export interface GaFullPageUrlReportRow {
  fullPageUrl: string;
  activeUsers: number;
  newUsers: number;
}

export interface GaFullPageUrlReport {
  data: GaFullPageUrlReportRow[];
}

export enum ColorMode {
  SYSTEM = "SYSTEM",
  MANAGER = "MANAGER",
}

export interface BannerDTO {
  /** ID */
  id: number;
  /** TEXT1 */
  text1: string | LocalizedDTO;
  /** TEXT2 */
  text2?: string | LocalizedDTO;
  /** Button 제목 */
  buttonLabel?: string | LocalizedDTO;
  fontColor1: string;
  fontColor2: string;
  background: string;
  backgroundColor: string;
  buttonUrl?: string;
  buttonTargetBlank?: boolean;
  hide?: boolean;
  /** 색상 모드 */
  colorMode: ColorMode;
  /** orderBy */
  orderBy: number;
  /**
   * 등록일
   * @format date-time
   * @example ""
   */
  createdTime: string;
  /**
   * 등록한 계정 ID
   * @example ""
   */
  createdBy: number;
  /**
   * 수정시간
   * @format date-time
   * @example ""
   */
  updatedTime: string;
  /**
   * 수정한 계정 ID
   * @example ""
   */
  updatedBy: number;
}

export interface BannerPage {
  /** 데이터 */
  items: BannerDTO[];
  /** 페이지 메타 정보 */
  metadata: PaginationMetadata;
}

export interface ReqBanner {
  /** TEXT 1 */
  text1: LocalizedDTO;
  /** TEXT 2 */
  text2?: LocalizedDTO;
  /** 버튼 제목 */
  buttonLabel?: LocalizedDTO;
  background: string;
  fontColor1: string;
  fontColor2: string;
  backgroundColor: string;
  buttonUrl?: string;
  buttonTargetBlank?: boolean;
  hide?: boolean;
  /** orderBy */
  orderBy: number;
  /** 색상 모드 */
  colorMode: ColorMode;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Admin 문서
 * @version 1.0.0
 * @contact
 *
 * Admin 문서를 위한 설명
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name AppControllerGetHello
   * @request GET:/
   */
  appControllerGetHello = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/`,
      method: "GET",
      ...params,
    });

  i18N = {
    /**
     * No description
     *
     * @name AppControllerI18N
     * @request GET:/i18n
     */
    appControllerI18N: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/i18n`,
        method: "GET",
        ...params,
      }),
  };
  i18N2 = {
    /**
     * No description
     *
     * @name AppControllerI18N2
     * @request GET:/i18n-2
     */
    appControllerI18N2: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/i18n-2`,
        method: "GET",
        ...params,
      }),
  };
  acceptLanguage = {
    /**
     * No description
     *
     * @name AppControllerAcceptLanguage
     * @request GET:/accept-language
     */
    appControllerAcceptLanguage: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/accept-language`,
        method: "GET",
        ...params,
      }),
  };
  api = {
    /**
     * No description
     *
     * @tags 회원
     * @name UserLogin
     * @summary 로그인 정보
     * @request POST:/api/v1/user/login
     */
    userLogin: (data: ReqLogin, params: RequestParams = {}) =>
      this.request<ResLogin, void>({
        path: `/api/v1/user/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원
     * @name UserRefreshToken
     * @summary 리프래시 토큰
     * @request POST:/api/v1/user/refresh-token
     */
    userRefreshToken: (data: ReqRefreshToken, params: RequestParams = {}) =>
      this.request<ResLogin, void>({
        path: `/api/v1/user/refresh-token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원
     * @name UserAuthedUser
     * @summary JWT Access_token 확인
     * @request GET:/api/v1/user/authed-user
     * @secure
     */
    userAuthedUser: (params: RequestParams = {}) =>
      this.request<AuthedUser, void>({
        path: `/api/v1/user/authed-user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원
     * @name UserMe
     * @summary 내 정보
     * @request GET:/api/v1/user/me
     * @secure
     */
    userMe: (params: RequestParams = {}) =>
      this.request<Profile, void>({
        path: `/api/v1/user/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원
     * @name UserSendSms
     * @summary 문자 발송
     * @request POST:/api/v1/user/send/sms
     */
    userSendSms: (data: SMS, params: RequestParams = {}) =>
      this.request<DirectSendSMSResult, any>({
        path: `/api/v1/user/send/sms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원
     * @name UserBlock
     * @summary 사용자 차단
     * @request POST:/api/v1/user/{userId}/block/{blocked}
     */
    userBlock: (userId: number, blocked: boolean, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/user/${userId}/block/${blocked}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 찾기
     * @name FindPwd
     * @summary 비밀번호 찾기
     * @request POST:/api/v1/user/find-pwd
     */
    findPwd: (data: ReqFindPwd, params: RequestParams = {}) =>
      this.request<ResResult, void>({
        path: `/api/v1/user/find-pwd`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 찾기
     * @name ChkFindPwd
     * @summary 비밀번호 찾기
     * @request POST:/api/v1/user/chk-find-pwd
     */
    chkFindPwd: (data: ReqChkFindPwd, params: RequestParams = {}) =>
      this.request<ResResult, void>({
        path: `/api/v1/user/chk-find-pwd`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 찾기
     * @name ChangeFindPwd
     * @summary 비밀번호 찾기
     * @request POST:/api/v1/user/change-find-pwd
     */
    changeFindPwd: (data: ReqChangeFindPwd, params: RequestParams = {}) =>
      this.request<ResResult, void>({
        path: `/api/v1/user/change-find-pwd`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social Login (Google)
     * @name GoogleWebLogin
     * @summary 웹용 구글 로그인 연동
     * @request POST:/api/v1/google-login
     */
    googleWebLogin: (data: SNSReqBody, params: RequestParams = {}) =>
      this.request<SNSResBody, any>({
        path: `/api/v1/google-login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social Login (Google)
     * @name GoogleNativeLogin
     * @summary 웹용 구글 로그인 연동
     * @request POST:/api/v1/google-login/native
     */
    googleNativeLogin: (data: GooglePojo, params: RequestParams = {}) =>
      this.request<SNSResBody, any>({
        path: `/api/v1/google-login/native`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social Login (Google)
     * @name GoogleConnectKey
     * @summary CONNECT 상태의 회원 연동
     * @request POST:/api/v1/google-login/connect
     */
    googleConnectKey: (data: SNSConnect, params: RequestParams = {}) =>
      this.request<Profile, any>({
        path: `/api/v1/google-login/connect`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social Login (Google)
     * @name GoogleUpdateConnect
     * @summary 웹용 구글 로그인 연동 (마이페이지 용)
     * @request POST:/api/v1/google-login/update
     * @secure
     */
    googleUpdateConnect: (data: SNSReqBody, params: RequestParams = {}) =>
      this.request<SocialIdEmbed, any>({
        path: `/api/v1/google-login/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판
     * @name BoardPage
     * @summary 게시판 paging
     * @request GET:/api/v1/board
     * @secure
     */
    boardPage: (
      query: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /**
         * 게시판 유형
         * @example "NOTICE"
         */
        type: "NOTICE" | "FAQ";
        /** 카테고리 */
        categoryIds?: number[];
        /** 순서변경 */
        orderBy?: string;
        /** 숨김 */
        hide?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<BoardPage, any>({
        path: `/api/v1/board`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판
     * @name BoardCreate
     * @summary 게시판 등록
     * @request POST:/api/v1/board
     */
    boardCreate: (data: ReqBoard, params: RequestParams = {}) =>
      this.request<BoardDTO, any>({
        path: `/api/v1/board`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판
     * @name BoardDeleteByIds
     * @summary 게시판 삭제
     * @request DELETE:/api/v1/board
     */
    boardDeleteByIds: (data: number[], params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/board`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판
     * @name BoardCategories
     * @summary 게시판 카테고리
     * @request GET:/api/v1/board/categories
     * @secure
     */
    boardCategories: (
      query: {
        /**
         * 게시판 유형
         * @example "NOTICE"
         */
        type: "NOTICE" | "FAQ";
      },
      params: RequestParams = {},
    ) =>
      this.request<BoardCategoryList, any>({
        path: `/api/v1/board/categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판
     * @name BoardGet
     * @summary 게시판 상세
     * @request GET:/api/v1/board/{id}
     * @secure
     */
    boardGet: (id: number, params: RequestParams = {}) =>
      this.request<BoardDTO, any>({
        path: `/api/v1/board/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판
     * @name BoardUpdate
     * @summary 게시판 수정
     * @request PUT:/api/v1/board/{id}
     */
    boardUpdate: (id: number, data: ReqBoard, params: RequestParams = {}) =>
      this.request<BoardDTO, any>({
        path: `/api/v1/board/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판
     * @name BoardDelete
     * @summary 게시판 삭제
     * @request DELETE:/api/v1/board/{id}
     */
    boardDelete: (id: number, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/board/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 설정
     * @name GetSetting
     * @summary 설정 정보 보기
     * @request GET:/api/setting
     * @secure
     */
    getSetting: (params: RequestParams = {}) =>
      this.request<SettingDTO[], void>({
        path: `/api/setting`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 설정
     * @name GetSettingDefaultLang
     * @summary 기본언어 설정 보기
     * @request GET:/api/setting/default-language
     * @secure
     */
    getSettingDefaultLang: (params: RequestParams = {}) =>
      this.request<SettingDTO, void>({
        path: `/api/setting/default-language`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 설정
     * @name GetSettingMultilingual
     * @summary 다국어 설정
     * @request GET:/api/setting/multilingual
     * @secure
     */
    getSettingMultilingual: (params: RequestParams = {}) =>
      this.request<BooleanSetting, void>({
        path: `/api/setting/multilingual`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 설정
     * @name SetSettingMultilingual
     * @summary 다국어 설정
     * @request POST:/api/setting/multilingual
     * @secure
     */
    setSettingMultilingual: (data: BooleanSetting, params: RequestParams = {}) =>
      this.request<BooleanSetting, void>({
        path: `/api/setting/multilingual`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 설정
     * @name GetSettingLanguage
     * @summary 다국어 언어 설정
     * @request GET:/api/setting/language
     * @secure
     */
    getSettingLanguage: (params: RequestParams = {}) =>
      this.request<LanguageSetting, void>({
        path: `/api/setting/language`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 설정
     * @name SetSettingLanguage
     * @summary 다국어 언어 설정
     * @request POST:/api/setting/language
     * @secure
     */
    setSettingLanguage: (data: LanguageSetting, params: RequestParams = {}) =>
      this.request<LanguageSetting, void>({
        path: `/api/setting/language`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 설정
     * @name GetSmsSender
     * @summary 발신 번호
     * @request GET:/api/setting/sms-sender
     * @secure
     */
    getSmsSender: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/setting/sms-sender`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판 카테고리
     * @name BoardCategoryList
     * @summary 게시판 카테고리 List
     * @request GET:/api/v1/board-category
     * @secure
     */
    boardCategoryList: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /** 유형 */
        types?: string[];
        /** 순서변경 */
        orderBy?: string;
        /** 숨김 */
        hide?: boolean;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BoardCategoryList, any>({
        path: `/api/v1/board-category`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판 카테고리
     * @name BoardCategoryCreate
     * @summary 게시판 등록
     * @request POST:/api/v1/board-category
     */
    boardCategoryCreate: (data: ReqBoardCategory, params: RequestParams = {}) =>
      this.request<BoardCategoryDTO, any>({
        path: `/api/v1/board-category`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판 카테고리
     * @name BoardCategoryDeleteByIds
     * @summary 게시판 삭제
     * @request DELETE:/api/v1/board-category
     */
    boardCategoryDeleteByIds: (data: number[], params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/board-category`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판 카테고리
     * @name BoardCategoryGet
     * @summary 게시판 카테고리 상세
     * @request GET:/api/v1/board-category/{id}
     * @secure
     */
    boardCategoryGet: (id: number, params: RequestParams = {}) =>
      this.request<BoardCategoryDTO, any>({
        path: `/api/v1/board-category/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판 카테고리
     * @name BoardCategoryUpdate
     * @summary 게시판 카테고리 수정
     * @request PUT:/api/v1/board-category/{id}
     */
    boardCategoryUpdate: (id: number, data: ReqBoardCategory, params: RequestParams = {}) =>
      this.request<BoardCategoryDTO, any>({
        path: `/api/v1/board-category/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판 카테고리
     * @name BoardCategoryDelete
     * @summary 게시판 카테고리 삭제
     * @request DELETE:/api/v1/board-category/{id}
     */
    boardCategoryDelete: (id: number, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/board-category/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 게시판 카테고리
     * @name BoardCategoryChangeOrder
     * @summary 카테고리 순서 정의
     * @request PUT:/api/v1/board-category/changeOrder/{id}
     * @secure
     */
    boardCategoryChangeOrder: (id: number, data: ReqChangeOrder, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/board-category/changeOrder/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 파일
     * @name FileUpload
     * @summary 파일 업로드
     * @request POST:/api/v1/admin/file
     * @secure
     */
    fileUpload: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileModel, any>({
        path: `/api/v1/admin/file`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 파일
     * @name FilesUpload
     * @summary 파일 업로드 (복수)
     * @request POST:/api/v1/admin/file/multiple
     * @secure
     */
    filesUpload: (
      data: {
        files?: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<FileModel[], any>({
        path: `/api/v1/admin/file/multiple`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 파일
     * @name FileGet
     * @summary 파일 상세
     * @request GET:/api/v1/admin/file/{id}
     * @secure
     */
    fileGet: (id: number, params: RequestParams = {}) =>
      this.request<FileModel, any>({
        path: `/api/v1/admin/file/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 이용약관 및 개인정보처리 문서
     * @name PolicyPage
     * @summary 약관 paging
     * @request GET:/api/v1/policy
     * @secure
     */
    policyPage: (
      query: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 유형 */
        types?: string[];
        countryCode: string;
        /** 순서변경 */
        orderBy?: string;
        /** 숨김 */
        hide?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PolicyPage, any>({
        path: `/api/v1/policy`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 이용약관 및 개인정보처리 문서
     * @name PolicyCreate
     * @summary 약관 등록
     * @request POST:/api/v1/policy
     */
    policyCreate: (data: ReqPolicy, params: RequestParams = {}) =>
      this.request<PolicyDTO, any>({
        path: `/api/v1/policy`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 이용약관 및 개인정보처리 문서
     * @name PolicyDeleteByIds
     * @summary 약관 삭제
     * @request DELETE:/api/v1/policy
     */
    policyDeleteByIds: (data: number[], params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/policy`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 이용약관 및 개인정보처리 문서
     * @name PolicyTypes
     * @summary 약관 유형
     * @request GET:/api/v1/policy/types
     * @secure
     */
    policyTypes: (params: RequestParams = {}) =>
      this.request<EnumValue[], any>({
        path: `/api/v1/policy/types`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 이용약관 및 개인정보처리 문서
     * @name PolicyGet
     * @summary 약관 상세
     * @request GET:/api/v1/policy/{id}
     * @secure
     */
    policyGet: (id: number, params: RequestParams = {}) =>
      this.request<PolicyDTO, any>({
        path: `/api/v1/policy/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 이용약관 및 개인정보처리 문서
     * @name PolicyUpdate
     * @summary 약관 수정
     * @request PUT:/api/v1/policy/{id}
     */
    policyUpdate: (id: number, data: ReqPolicy, params: RequestParams = {}) =>
      this.request<PolicyDTO, any>({
        path: `/api/v1/policy/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 이용약관 및 개인정보처리 문서
     * @name PolicyDelete
     * @summary 약관 삭제
     * @request DELETE:/api/v1/policy/{id}
     */
    policyDelete: (id: number, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/policy/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SES 메일 발신 테스트
     * @name SendEmailTest
     * @summary 발신 테스트
     * @request POST:/api/v1/ses/test1
     */
    sendEmailTest: (data: SESSender, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/api/v1/ses/test1`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저 관리
     * @name UserPage
     * @summary 사용자 paging
     * @request GET:/api/v1/user
     * @secure
     */
    userPage: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 순서변경 */
        orderBy?: string;
        /** 숨김 */
        hide?: boolean;
        /** 상태 */
        status?: string;
        /** 권한 */
        authorities?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<UserPage, any>({
        path: `/api/v1/user`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저 관리
     * @name ExpertCountByStatus
     * @summary 전문가 상태 수
     * @request GET:/api/v1/user/count-by-status
     * @secure
     */
    expertCountByStatus: (params: RequestParams = {}) =>
      this.request<ExpertCount, any>({
        path: `/api/v1/user/count-by-status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 유저 관리
     * @name UserGet
     * @summary 사용자 상세
     * @request GET:/api/v1/user/{id}
     * @secure
     */
    userGet: (id: number, params: RequestParams = {}) =>
      this.request<UserDTO, any>({
        path: `/api/v1/user/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원
     * @name ExpertUpdate
     * @summary 코렉터 수정
     * @request POST:/api/v1/expert
     * @secure
     */
    expertUpdate: (data: AdminReqExpert, params: RequestParams = {}) =>
      this.request<ExpertDTO, any>({
        path: `/api/v1/expert`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원
     * @name ExpertChangeCreateStatus
     * @summary 등록 상태 변경
     * @request POST:/api/v1/expert/change-create-status
     * @secure
     */
    expertChangeCreateStatus: (data: ChangeStatus, params: RequestParams = {}) =>
      this.request<ResResult, void>({
        path: `/api/v1/expert/change-create-status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원
     * @name ExpertChangeUpdateStatus
     * @summary 수정 상태 변경
     * @request POST:/api/v1/expert/change-update-status
     * @secure
     */
    expertChangeUpdateStatus: (data: ChangeStatus, params: RequestParams = {}) =>
      this.request<ResResult, void>({
        path: `/api/v1/expert/change-update-status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원
     * @name ExpertBanks
     * @summary 은행 목록
     * @request GET:/api/v1/expert/banks
     */
    expertBanks: (params: RequestParams = {}) =>
      this.request<Bank[], any>({
        path: `/api/v1/expert/banks`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ServicePage
     * @summary 서비스 paging
     * @request GET:/api/v1/service
     * @secure
     */
    servicePage: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /** 서비스 타입 */
        serviceType?: ServiceType[];
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 순서 변경(POPULAR, RATE) */
        orderBy?: string;
        /** 오리진 모드 */
        originMode?: boolean;
        /** Service stopMode */
        stopMode?: string;
        /** 숨김 */
        hide?: boolean;
        /** 상태 */
        status?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ServicePage, any>({
        path: `/api/v1/service`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ServiceDelete
     * @summary 서비스 삭제
     * @request PUT:/api/v1/service/{id}/delete
     * @secure
     */
    serviceDelete: (id: number, params: RequestParams = {}) =>
      this.request<ServiceDTO, any>({
        path: `/api/v1/service/${id}/delete`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ServiceDeletes
     * @summary 서비스 복수 삭제
     * @request PUT:/api/v1/service/deletes
     * @secure
     */
    serviceDeletes: (data: number[], params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/service/deletes`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ServiceUpdate
     * @summary 서비스 수정
     * @request PUT:/api/v1/service/{id}
     * @secure
     */
    serviceUpdate: (id: number, data: AdminReqService, params: RequestParams = {}) =>
      this.request<ServiceDTO, any>({
        path: `/api/v1/service/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ServiceGet
     * @summary 서비스 상세
     * @request GET:/api/v1/service/{id}
     * @secure
     */
    serviceGet: (id: number, params: RequestParams = {}) =>
      this.request<ServiceDTO, any>({
        path: `/api/v1/service/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ServiceCountByStatus
     * @summary 서비스 상태 수
     * @request GET:/api/v1/service/count-by-status
     * @secure
     */
    serviceCountByStatus: (params: RequestParams = {}) =>
      this.request<ServiceCount, any>({
        path: `/api/v1/service/count-by-status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ServiceChangeCreateStatus
     * @summary 등록 상태 변경
     * @request POST:/api/v1/service/change-create-status
     * @secure
     */
    serviceChangeCreateStatus: (data: ServiceChangeStatus, params: RequestParams = {}) =>
      this.request<ResResult, void>({
        path: `/api/v1/service/change-create-status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ServiceChangeUpdateStatus
     * @summary 등록 상태 변경
     * @request POST:/api/v1/service/change-update-status
     * @secure
     */
    serviceChangeUpdateStatus: (data: ServiceChangeStatus, params: RequestParams = {}) =>
      this.request<ResResult, void>({
        path: `/api/v1/service/change-update-status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ServiceStop
     * @summary 서비스 정지
     * @request PUT:/api/v1/service/{id}/stop/{stopMode}
     * @secure
     */
    serviceStop: (id: number, stopMode: boolean, data: AdminStopModeBody, params: RequestParams = {}) =>
      this.request<ServiceDTO, any>({
        path: `/api/v1/service/${id}/stop/${stopMode}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ChangeServiceMainView
     * @summary 메인노출 서비스 변경
     * @request POST:/api/v1/service/mainView/change
     * @secure
     */
    changeServiceMainView: (data: ServiceMainViewBody, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/service/mainView/change`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 관리
     * @name ServiceFee
     * @summary 서비스 수수료
     * @request GET:/api/v1/service/fee/price
     */
    serviceFee: (params: RequestParams = {}) =>
      this.request<ServiceFee, any>({
        path: `/api/v1/service/fee/price`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 주문/결제
     * @name OrderPage
     * @summary 주문/결제 paging
     * @request GET:/api/v1/order
     * @secure
     */
    orderPage: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 순서변경 */
        orderBy?: string;
        /** 숨김 */
        hide?: boolean;
        /** 상태 */
        orderStatus?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderPage, any>({
        path: `/api/v1/order`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 주문/결제
     * @name OrderCountByStatus
     * @summary 주문 상태 수
     * @request GET:/api/v1/order/count-by-status
     * @secure
     */
    orderCountByStatus: (params: RequestParams = {}) =>
      this.request<OrderCount, any>({
        path: `/api/v1/order/count-by-status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 주문/결제
     * @name CalculateList
     * @summary 서비스 정산 대상
     * @request GET:/api/v1/order/calculate
     * @secure
     */
    calculateList: (params: RequestParams = {}) =>
      this.request<CalculateDTO[], any>({
        path: `/api/v1/order/calculate`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 주문/결제
     * @name CalculateComplete
     * @summary 서비스 정산 완료
     * @request POST:/api/v1/order/{orderId}/calculate
     * @secure
     */
    calculateComplete: (orderId: string, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/order/${orderId}/calculate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 주문/결제
     * @name OrderPaymentCancel
     * @summary 결제 취소
     * @request POST:/api/v1/order/{tid}/payment-cancel
     * @secure
     */
    orderPaymentCancel: (tid: string, params: RequestParams = {}) =>
      this.request<KsnetCancelResponse, any>({
        path: `/api/v1/order/${tid}/payment-cancel`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 주문/결제
     * @name OrderChangeStatus
     * @summary 주문 상태 변경
     * @request POST:/api/v1/order/{id}/change-status/{status}
     * @secure
     */
    orderChangeStatus: (id: number, status: string, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/order/${id}/change-status/${status}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 주문/결제
     * @name OrderTotalOrders
     * @summary orderTotalOrders
     * @request GET:/api/v1/order/total-orders
     */
    orderTotalOrders: (
      query: {
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.868Z"
         */
        startDate: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.868Z"
         */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AnalyticsTotalOrderReport, any>({
        path: `/api/v1/order/total-orders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 주문/결제
     * @name OrderTotalPayments
     * @summary orderTotalPayments
     * @request GET:/api/v1/order/total-payments
     */
    orderTotalPayments: (
      query: {
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.868Z"
         */
        startDate: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.868Z"
         */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AnalyticsTotalPaymentsReport, any>({
        path: `/api/v1/order/total-payments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 주문/결제
     * @name OrderGet
     * @summary 주문 상세
     * @request GET:/api/v1/order/{id}
     * @secure
     */
    orderGet: (id: number, params: RequestParams = {}) =>
      this.request<OrderDTO, any>({
        path: `/api/v1/order/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description true: 존재함, false: 존재하지 않음
     *
     * @tags 회원 수정
     * @name DuplicateNickname
     * @summary 닉네임 중복 체크
     * @request POST:/api/v1/user/nickname/duplicate
     * @secure
     */
    duplicateNickname: (data: UpdateAdminNickname, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/user/nickname/duplicate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 닉네임 변경
     *
     * @tags 회원 수정
     * @name AdminChangeNickname
     * @summary 닉네임 변경
     * @request POST:/api/v1/user/nickname/change
     * @secure
     */
    adminChangeNickname: (data: UpdateAdminNickname, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/user/nickname/change`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 이메일 변경
     *
     * @tags 회원 수정
     * @name AdminChangeEmail
     * @summary 이메일 변경
     * @request POST:/api/v1/user/email/change
     * @secure
     */
    adminChangeEmail: (data: UpdateAdminEmail, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/user/email/change`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 수정
     * @name ChangeVerificationEmail
     * @summary 이메일 인증여부
     * @request POST:/api/v1/user/email/verification
     * @secure
     */
    changeVerificationEmail: (data: UpdateVerification, params: RequestParams = {}) =>
      this.request<VerificationUpdatedBody, any>({
        path: `/api/v1/user/email/verification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 이름 변경
     *
     * @tags 회원 수정
     * @name AdminChangeFullName
     * @summary 이름 변경
     * @request POST:/api/v1/user/fullName/change
     * @secure
     */
    adminChangeFullName: (data: UpdateAdminFullNaMe, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/user/fullName/change`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description 전화번호 변경
     *
     * @tags 회원 수정
     * @name AdminChangeMobile
     * @summary 전화번호 변경
     * @request POST:/api/v1/user/mobile/change
     * @secure
     */
    adminChangeMobile: (data: UpdateAdminMobile, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/user/mobile/change`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 수정
     * @name ChangeVerificationMobile
     * @summary 전화번호 인증여부
     * @request POST:/api/v1/user/mobile/verification
     * @secure
     */
    changeVerificationMobile: (data: UpdateVerification, params: RequestParams = {}) =>
      this.request<VerificationUpdatedBody, any>({
        path: `/api/v1/user/mobile/verification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 수정
     * @name ChangePassword
     * @summary 비밀번호 변경
     * @request POST:/api/v1/user/password/change
     * @secure
     */
    changePassword: (data: UpdateAdminPassword, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/user/password/change`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 수정
     * @name ChangeProfileImg
     * @summary 프로필사진  변경
     * @request POST:/api/v1/user/upload-profile
     * @secure
     */
    changeProfileImg: (
      data: {
        /**
         * The file to upload.
         * @format binary
         */
        file?: File;
        /** The ID of the user. */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileModel, any>({
        path: `/api/v1/user/upload-profile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 수정
     * @name ChangeMarketing
     * @summary 마케팅 수신 동의 변경
     * @request POST:/api/v1/user/marketing
     * @secure
     */
    changeMarketing: (data: UpdateBoolean, params: RequestParams = {}) =>
      this.request<MarketingUpdatedBody, any>({
        path: `/api/v1/user/marketing`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 수정
     * @name ChangeSmsRcv
     * @summary SMS/LMS 수신 동의 변경
     * @request POST:/api/v1/user/sms-rcv
     * @secure
     */
    changeSmsRcv: (data: UpdateAdminTerms, params: RequestParams = {}) =>
      this.request<MarketingUpdatedBody, any>({
        path: `/api/v1/user/sms-rcv`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 수정
     * @name ChangeEmailRcv
     * @summary 이메일 수신 동의 변경
     * @request POST:/api/v1/user/email-rcv
     * @secure
     */
    changeEmailRcv: (data: UpdateAdminTerms, params: RequestParams = {}) =>
      this.request<MarketingUpdatedBody, any>({
        path: `/api/v1/user/email-rcv`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 수정
     * @name ChangeKakaoRcv
     * @summary 카카오 알림톡 수신 동의 변경
     * @request POST:/api/v1/user/kakao-rcv
     * @secure
     */
    changeKakaoRcv: (data: UpdateAdminTerms, params: RequestParams = {}) =>
      this.request<MarketingUpdatedBody, any>({
        path: `/api/v1/user/kakao-rcv`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 수정
     * @name ChangePushRcv
     * @summary 모바일 푸시 수신 동의 변경
     * @request POST:/api/v1/user/push-rcv
     * @secure
     */
    changePushRcv: (data: UpdateBoolean, params: RequestParams = {}) =>
      this.request<MarketingUpdatedBody, any>({
        path: `/api/v1/user/push-rcv`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원 수정
     * @name CreatePassword
     * @summary 비밀번호 생성 (네이버 가입, 비밀번호 등록이 안된 계정)
     * @request POST:/api/v1/user/create-password
     * @secure
     */
    createPassword: (data: CreatePassword, params: RequestParams = {}) =>
      this.request<ResResult, void>({
        path: `/api/v1/user/create-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 작업
     * @name TaskPage
     * @summary 작업 paging
     * @request GET:/api/v1/task
     * @secure
     */
    taskPage: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 순서변경 */
        orderBy?: string;
        /** 숨김 */
        hide?: boolean;
        /** 상태 */
        taskStatus?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TaskPage, any>({
        path: `/api/v1/task`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 작업
     * @name TaskCountByStatus
     * @summary 작업 상태 수
     * @request GET:/api/v1/task/count-by-status
     * @secure
     */
    taskCountByStatus: (params: RequestParams = {}) =>
      this.request<TaskCount, any>({
        path: `/api/v1/task/count-by-status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 작업
     * @name TaskGet
     * @summary 작업 상세
     * @request GET:/api/v1/task/{id}
     * @secure
     */
    taskGet: (id: number, params: RequestParams = {}) =>
      this.request<TaskDTO, any>({
        path: `/api/v1/task/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 리뷰
     * @name ReviewPage
     * @summary 리뷰 paging
     * @request GET:/api/v1/review
     * @secure
     */
    reviewPage: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 순서변경 */
        orderBy?: string;
        /** 숨김 */
        hide?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReviewPage, any>({
        path: `/api/v1/review`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 리뷰
     * @name ChangeMainView
     * @summary 메인노출 리뷰 변경
     * @request POST:/api/v1/review/mainView/change/{userId}-{taskId}
     * @secure
     */
    changeMainView: (userId: number, taskId: number, data: MainViewUpdatedBody, params: RequestParams = {}) =>
      this.request<MainViewUpdatedBody, any>({
        path: `/api/v1/review/mainView/change/${userId}-${taskId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 카테고리
     * @name ServiceCategoryCreate
     * @summary 카테고리 등록
     * @request POST:/api/v1/service-category
     */
    serviceCategoryCreate: (data: ReqServiceCategory, params: RequestParams = {}) =>
      this.request<ServiceCategoryDTO, any>({
        path: `/api/v1/service-category`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 카테고리
     * @name ServiceCategoryList
     * @summary 서비스 카테고리 list
     * @request GET:/api/v1/service-category
     * @secure
     */
    serviceCategoryList: (
      query?: {
        /**
         * 카테고리 전체 보기
         * @example "false"
         */
        isDepth?: boolean;
        /**
         * 부모 ID
         * @example ""
         */
        parentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ServiceCategoryList, any>({
        path: `/api/v1/service-category`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 카테고리
     * @name ServiceCategoryHierarchy
     * @summary 카테고리 하이라키
     * @request PUT:/api/v1/service-category/hierarchy
     */
    serviceCategoryHierarchy: (data: ServiceCategoryDTO[], params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/service-category/hierarchy`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 카테고리
     * @name ServiceCategoryUpdate
     * @summary 카테고리 수정
     * @request PUT:/api/v1/service-category/{id}
     */
    serviceCategoryUpdate: (id: number, data: ReqServiceCategory, params: RequestParams = {}) =>
      this.request<ServiceCategoryDTO, any>({
        path: `/api/v1/service-category/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 카테고리
     * @name ServiceCategoryGet
     * @summary 서비스 카테고리 get
     * @request GET:/api/v1/service-category/{id}
     * @secure
     */
    serviceCategoryGet: (id: number, params: RequestParams = {}) =>
      this.request<ServiceCategoryDTO, any>({
        path: `/api/v1/service-category/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 카테고리
     * @name ServiceCategoryDelete
     * @summary 서비스 카테고리 삭제
     * @request DELETE:/api/v1/service-category/{id}
     */
    serviceCategoryDelete: (id: number, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/service-category/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 서비스 카테고리
     * @name ServiceCategoryParents
     * @summary 카테고리 부모
     * @request GET:/api/v1/service-category/{id}/parents
     */
    serviceCategoryParents: (id: number, params: RequestParams = {}) =>
      this.request<Any, any>({
        path: `/api/v1/service-category/${id}/parents`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQ
     * @name FaqPage
     * @summary 게시판 paging
     * @request GET:/api/v1/faq
     * @secure
     */
    faqPage: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 카테고리 */
        categoryIds?: number[];
        /** 순서변경 */
        orderBy?: string;
        /** 숨김 */
        hide?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FaqPage, any>({
        path: `/api/v1/faq`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQ
     * @name FaqCreate
     * @summary 게시판 등록
     * @request POST:/api/v1/faq
     */
    faqCreate: (data: ReqFaq, params: RequestParams = {}) =>
      this.request<FaqDTO, any>({
        path: `/api/v1/faq`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQ
     * @name FaqDeleteByIds
     * @summary 게시판 삭제
     * @request DELETE:/api/v1/faq
     */
    faqDeleteByIds: (data: number[], params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/faq`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQ
     * @name FaqCategories
     * @summary 게시판 카테고리
     * @request GET:/api/v1/faq/categories
     * @secure
     */
    faqCategories: (params: RequestParams = {}) =>
      this.request<BoardCategoryList, any>({
        path: `/api/v1/faq/categories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQ
     * @name FaqGet
     * @summary 게시판 상세
     * @request GET:/api/v1/faq/{id}
     * @secure
     */
    faqGet: (id: number, params: RequestParams = {}) =>
      this.request<FaqDTO, any>({
        path: `/api/v1/faq/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQ
     * @name FaqUpdate
     * @summary 게시판 수정
     * @request PUT:/api/v1/faq/{id}
     */
    faqUpdate: (id: number, data: ReqFaq, params: RequestParams = {}) =>
      this.request<FaqDTO, any>({
        path: `/api/v1/faq/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQ
     * @name FaqDelete
     * @summary 게시판 삭제
     * @request DELETE:/api/v1/faq/{id}
     */
    faqDelete: (id: number, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/faq/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactPage
     * @summary paging
     * @request GET:/api/v1/contact
     * @secure
     */
    contactPage: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 순서변경 */
        orderBy?: string;
        /** 숨김 */
        hide?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContactPage, any>({
        path: `/api/v1/contact`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactDeleteByIds
     * @summary 삭제
     * @request DELETE:/api/v1/contact
     */
    contactDeleteByIds: (data: number[], params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/contact`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactGet
     * @summary 상세
     * @request GET:/api/v1/contact/{id}
     * @secure
     */
    contactGet: (id: number, params: RequestParams = {}) =>
      this.request<ContactDTO, any>({
        path: `/api/v1/contact/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactUpdate
     * @summary 수정
     * @request PUT:/api/v1/contact/{id}
     */
    contactUpdate: (id: number, data: ReqContact, params: RequestParams = {}) =>
      this.request<ContactDTO, any>({
        path: `/api/v1/contact/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactDelete
     * @summary 삭제
     * @request DELETE:/api/v1/contact/{id}
     */
    contactDelete: (id: number, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/contact/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 카카오 알림톡 템플릿 관리
     * @name KkoPage
     * @summary 알림톡 paging
     * @request GET:/api/v1/kko
     * @secure
     */
    kkoPage: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 순서변경 */
        orderBy?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<KkoPage, any>({
        path: `/api/v1/kko`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 카카오 알림톡 템플릿 관리
     * @name KkoCreate
     * @summary 알림톡 템플릿 등록
     * @request POST:/api/v1/kko
     */
    kkoCreate: (data: ReqKko, params: RequestParams = {}) =>
      this.request<KkoDTO, any>({
        path: `/api/v1/kko`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 카카오 알림톡 템플릿 관리
     * @name KkoDeleteByIds
     * @summary 알림톡 삭제
     * @request DELETE:/api/v1/kko
     */
    kkoDeleteByIds: (data: number[], params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/kko`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 카카오 알림톡 템플릿 관리
     * @name KkoGet
     * @summary 알림톡 상세
     * @request GET:/api/v1/kko/{id}
     * @secure
     */
    kkoGet: (id: number, params: RequestParams = {}) =>
      this.request<KkoDTO, any>({
        path: `/api/v1/kko/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 카카오 알림톡 템플릿 관리
     * @name KkoUpdate
     * @summary 알림톡 수정
     * @request PUT:/api/v1/kko/{id}
     */
    kkoUpdate: (id: number, data: ReqKko, params: RequestParams = {}) =>
      this.request<KkoDTO, any>({
        path: `/api/v1/kko/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 카카오 알림톡 템플릿 관리
     * @name KkoDelete
     * @summary 알림톡 삭제
     * @request DELETE:/api/v1/kko/{id}
     */
    kkoDelete: (id: number, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/kko/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 카카오 알림톡 템플릿 관리
     * @name KkoTest
     * @summary 알림톡 템플릿 테스트 발송
     * @request POST:/api/v1/kko/test
     */
    kkoTest: (data: ReqSendTest, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/kko/test`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 채팅
     * @name ChatRoomPage
     * @summary 채팅룸 paging
     * @request GET:/api/v1/chat
     * @secure
     */
    chatRoomPage: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 순서변경 */
        orderBy?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ChatRoomPage, any>({
        path: `/api/v1/chat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 채팅
     * @name ChatRoomGet
     * @summary 챗룸 상세
     * @request GET:/api/v1/chat/{userId}-{expertId}
     * @secure
     */
    chatRoomGet: (userId: number, expertId: number, params: RequestParams = {}) =>
      this.request<ChatRoomDTO, any>({
        path: `/api/v1/chat/${userId}-${expertId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SMS
     * @name SmsSend
     * @summary 문자 발송
     * @request POST:/api/v1/sms/send
     */
    smsSend: (data: SMS, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/sms/send`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SMS
     * @name SmsSender
     * @summary 문자 발송
     * @request GET:/api/v1/sms/sender
     */
    smsSender: (params: RequestParams = {}) =>
      this.request<Any, any>({
        path: `/api/v1/sms/sender`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 이메일
     * @name EmailSend
     * @summary 메일 발송
     * @request POST:/api/v1/email/send
     */
    emailSend: (data: SESSender, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/email/send`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 이메일
     * @name EmailSender
     * @summary 이메일 발신 주소
     * @request GET:/api/v1/email/sender
     */
    emailSender: (params: RequestParams = {}) =>
      this.request<Any, any>({
        path: `/api/v1/email/sender`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Google Analytics
     * @name GaDailyUsersReport
     * @summary test
     * @request GET:/api/v1/ga/daily-users-report
     * @secure
     */
    gaDailyUsersReport: (
      query: {
        /**
         * @format date-time
         * @example "2024-04-16T09:15:08.008Z"
         */
        startDate: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:08.008Z"
         */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GaDailyUserReport, any>({
        path: `/api/v1/ga/daily-users-report`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Google Analytics
     * @name GaDauPerMau
     * @summary test
     * @request GET:/api/v1/ga/dau-per-mau
     * @secure
     */
    gaDauPerMau: (
      query: {
        /**
         * @format date-time
         * @example "2024-04-16T09:15:08.008Z"
         */
        startDate: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:08.008Z"
         */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GaDauPerMauReport, any>({
        path: `/api/v1/ga/dau-per-mau`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Google Analytics
     * @name GaDeviceCategory
     * @summary test
     * @request GET:/api/v1/ga/device-category
     * @secure
     */
    gaDeviceCategory: (
      query: {
        /**
         * @format date-time
         * @example "2024-04-16T09:15:08.008Z"
         */
        startDate: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:08.008Z"
         */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GaDeviceCategoryReport, any>({
        path: `/api/v1/ga/device-category`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Google Analytics
     * @name GaFullPageUrl
     * @summary gaFullPageUrl
     * @request GET:/api/v1/ga/full-page-url
     */
    gaFullPageUrl: (
      query: {
        /**
         * @format date-time
         * @example "2024-04-16T09:15:08.008Z"
         */
        startDate: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:08.008Z"
         */
        endDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GaFullPageUrlReport, any>({
        path: `/api/v1/ga/full-page-url`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 메인 페이지 배너
     * @name BannerPage
     * @summary 배너 paging
     * @request GET:/api/v1/banner
     * @secure
     */
    bannerPage: (
      query?: {
        /**
         * 검색어
         * @example ""
         */
        query?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        startTime?: string;
        /**
         * @format date-time
         * @example "2024-04-16T09:15:07.491Z"
         */
        endTime?: string;
        /**
         * 한 페이지에 표시할 레코드의 개수
         * @example 20
         */
        size?: number;
        /**
         * 현재 페이지 번호 (1부터 시작)
         * @example 1
         */
        page?: number;
        /** 순서변경 */
        orderBy?: string;
        /** 숨김 */
        hide?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<BannerPage, any>({
        path: `/api/v1/banner`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 메인 페이지 배너
     * @name BannerCreate
     * @summary 배너 등록
     * @request POST:/api/v1/banner
     */
    bannerCreate: (data: ReqBanner, params: RequestParams = {}) =>
      this.request<BannerDTO, any>({
        path: `/api/v1/banner`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 메인 페이지 배너
     * @name BannerDeleteByIds
     * @summary 배너 삭제
     * @request DELETE:/api/v1/banner
     */
    bannerDeleteByIds: (data: number[], params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/banner`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 메인 페이지 배너
     * @name BannerGet
     * @summary 배너 상세
     * @request GET:/api/v1/banner/{id}
     * @secure
     */
    bannerGet: (id: number, params: RequestParams = {}) =>
      this.request<BannerDTO, any>({
        path: `/api/v1/banner/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 메인 페이지 배너
     * @name BannerUpdate
     * @summary 배너 수정
     * @request PUT:/api/v1/banner/{id}
     */
    bannerUpdate: (id: number, data: ReqBanner, params: RequestParams = {}) =>
      this.request<BannerDTO, any>({
        path: `/api/v1/banner/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 메인 페이지 배너
     * @name BannerDelete
     * @summary 배너 삭제
     * @request DELETE:/api/v1/banner/{id}
     */
    bannerDelete: (id: number, params: RequestParams = {}) =>
      this.request<ResResult, any>({
        path: `/api/v1/banner/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TestKakaoAlert
     * @summary 카카오 알림톡 테스트
     * @request POST:/api/v1/test/kakao-test
     */
    testKakaoAlert: (params: RequestParams = {}) =>
      this.request<Any, any>({
        path: `/api/v1/test/kakao-test`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
}
