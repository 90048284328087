import isEqual from 'lodash/isEqual';
import {useCallback, useEffect, useState} from 'react';
// @mui
import {alpha} from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import {paths} from 'src/routes/paths';
// _mock
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {ConfirmDialog} from 'src/components/custom-dialog';
import {useSettingsContext} from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
    emptyRows,
    getComparator,
    TableEmptyRows, TableHeadCustom,
    TableNoData,
    TablePaginationActions,
    TablePaginationCustom,
    TableSelectedAction,
    TableSkeletonAll
} from "src/components/sprintify/table";
// types
//
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {transformSortJSON} from "../../components/sprintify/table/use-table";
import {CalculateDTO, OrderCount, OrderDTO} from "../../generated/swagger/swagger.api";
import {fISO} from "../../utils/format-time";
import {Swagger} from "../../utils/API";
import {DrawerWrapper} from "../../components/sprintify/drawer";
import {applyFilter, useCalculateManagerContext} from "./calculate-manage-provider";
import CalculateTableRow from "./view/calculate-table-row";
import { Stack } from '@mui/material';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'expert', label: '전문가', width: 250},
    {id: 'account', label: '계좌정보'},
    {id: 'ordersCount', label: '주문건수', width: 100},
    {id: 'buyAmount', label: '판매금액', width: 150},
    {id: 'calculateAmount', label: '정산금액', width: 150},
    {id: '-', label: '', width: 80},
];

// ----------------------------------------------------------------------
type Props = {
    pageName: string;
}

export default function CalculateListView({pageName}: Props) {
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {themeStretch, systemLanguage, systemMultilingual, systemDefaultLanguage} = useSettingsContext();
    const {enqueueSnackbar} = useSnackbar();

    const {
        table,
        denseHeight,

        defaultFilters,
        filters,
        handleFilters,
        handleResetFilters,

        searchParams,
        paramQuery,
        paramStartDate,
        paramEndDate,
        downloadExcel
    } = useCalculateManagerContext();

    //= Loading State
    const [listDataLoading, setListDataLoading] = useState<boolean>(false);
    const [detailDataLoading, setDetailDataLoading] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<OrderDTO>();
    const [countByStatus, setCountByStatus] = useState<OrderCount>({
        total: 0,
        init: 0,
        complete: 0,
        failed: 0,
        cancel: 0,
        confirm: 0,
    });

    //= Popup
    const openNew = useBoolean();
    const openView = useBoolean();
    const openEdit = useBoolean();
    const openExpertView = useBoolean();
    const openServiceView = useBoolean();
    const openRowDeleteDiagram = useBoolean();
    const openSelectedDeleteDiagram = useBoolean();

    //= DATA
    const [selectedId, setSelectedId] = useState<number | undefined>();
    const [tableData, setTableData] = useState<CalculateDTO[]>([]);

    const dateError = filters.startDate && filters.endDate ? filters.startDate.getTime() > filters.endDate.getTime() : false;

    const dataFiltered = applyFilter({
        inputData: tableData,
        comparator: getComparator(table.order, table.orderBy),
        filters,
        dateError,
    });

    const confirm = useBoolean();
    const [notFound, setNotFound] = useState(!tableData.length);
    console.log(notFound, 'notFound');

    const loadData = useCallback(async () => {
        setListDataLoading(true);
        setTableData([]);
        const {data} = await Swagger.api.calculateList();
        console.log(data.length, 'data.length');
        setNotFound(!data.length || data.length === 0)
        setTableData(data);
        setListDataLoading(false);
    }, []);

    const handleReset = async () => {
        loadData();
    };

    const handleCloseDrawer = useCallback(() => {
        openExpertView.onFalse();
        openNew.onFalse();
        openEdit.onFalse();
        openView.onFalse();
        setSelectedId(undefined);
    }, [openEdit, openExpertView, openNew, openView]);

    useEffect(() => {
        if (paramQuery) {
            handleFilters("query", paramQuery);
        }
        if (paramStartDate) {
            try {
                const startDate: Date = new Date(decodeURIComponent(paramStartDate));
                if (Number.isNaN(startDate.getTime())) {
                    handleFilters("startDate", startDate);
                }
            } catch (e) {
                console.error('e', e);
            }
        }
        if (paramEndDate) {
            try {
                const endDate: Date = new Date(decodeURIComponent(paramEndDate));
                if (Number.isNaN(endDate.getTime())) {
                    handleFilters("endDate", endDate);
                }
            } catch (e) {
                console.error('e', e);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramQuery, paramStartDate, paramEndDate]);


    useEffect(() => {
        loadData();
    }, [loadData])

    return (
        <>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <CustomBreadcrumbs
                    heading={pageName}
                    links={[
                        {name: '관리자', href: paths.dashboard.root},
                        {name: pageName}
                    ]}
                    sx={{
                        mb: {xs: 3, md: 5},
                    }}
                />

                <Card>
                    <Stack direction="row" justifyContent="flex-end" sx={{ m:2 }} spacing={1}>
                        <Button startIcon={<Iconify icon="file-icons:microsoft-excel"/>} variant="contained" onClick={() => downloadExcel()}>KB은행 정산 데이터 다운로드 (xlsx)</Button>
                    </Stack>

                    <TableContainer sx={{position: 'relative', overflow: 'unset'}}>
                        <TableSelectedAction
                            dense={table.dense}
                            numSelected={table.selected.length}
                            rowCount={tableData.length}
                            onSelectAllRows={(checked) =>
                                table.onSelectAllRows(
                                    checked,
                                    tableData.map((row) => row.expert.id)
                                )
                            }
                            action={
                                <Tooltip title="Delete">
                                    <IconButton color="primary" onClick={confirm.onTrue}>
                                        <Iconify icon="solar:trash-bin-trash-bold"/>
                                    </IconButton>
                                </Tooltip>
                            }
                        />

                        <Scrollbar>
                            <Table size={table.dense ? 'small' : 'medium'} sx={{minWidth: 960}}>
                                <TableHeadCustom
                                    order={table.order}
                                    orderBy={table.orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={table.selected.length}
                                    onSort={table.onSort}
                                    onSelectAllRows={(checked) =>
                                        table.onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.expert.id)
                                        )
                                    }
                                />
                                <TableBody>

                                    {!listDataLoading &&
                                        dataFiltered.map((row: CalculateDTO, index: number) => (
                                            <CalculateTableRow
                                                key={`${row.expert.id}${index}`}
                                                row={row}
                                                selected={table.selected.includes(row.expert.id)}
                                                onSelectRow={() => table.onSelectRow(row.expert.id)}
                                                onViewRow={() => {}}
                                                onDeleteRow={() => {
                                                }}
                                                onRefresh={handleReset}
                                            />
                                        ))}

                                    {!listDataLoading && tableData.length > 0 && (
                                        <TableEmptyRows
                                            height={denseHeight}
                                            emptyRows={emptyRows(
                                                table.page,
                                                table.rowsPerPage,
                                                table.metadata.total,
                                            )}
                                        />
                                    )}

                                    {!listDataLoading && <TableNoData notFound={notFound}/>}
                                    {listDataLoading &&
                                        (!dataFiltered || dataFiltered.length === 0) && (
                                            <TableSkeletonAll
                                                size={table.metadata.size}
                                                denseHeight={denseHeight}
                                            />
                                        )}
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        count={table.metadata.total}
                        colSpan={3}
                        page={table.metadata.currentPage - 1}
                        rowsPerPage={table.metadata.size}
                        onPageChange={table.onChangePage}
                        onRowsPerPageChange={table.onChangeRowsPerPage}
                        dense={table.dense}
                        onChangeDense={table.onChangeDense}
                        ActionsComponent={TablePaginationActions}
                    />
                </Card>
            </Container>

            {/* <DrawerWrapper */}
            {/*    loading={detailDataLoading} */}
            {/*    title="서비스 상세" */}
            {/*    open={openView.value} */}
            {/*    onClose={handleCloseDrawer} */}
            {/*    // onEdit={() => handleOpenUserView(selectedId)} */}
            {/*    // onDelete={() => { */}
            {/*    //     openRowDeleteDiagram.onTrue(); */}
            {/*    // }} */}
            {/*    children={detailData && <OrderViewBody data={detailData}/>} */}
            {/* /> */}

            {/* <DrawerWrapper */}
            {/*    loading={detailDataLoading} */}
            {/*    title="코렉터(전문가) 상세" */}
            {/*    open={openExpertView.value} */}
            {/*    onClose={handleCloseDrawer} */}
            {/*    // onView={() => handleOpenExpertView(selectedId)} */}
            {/*    // onDelete={() => { */}
            {/*    //     openRowDeleteDiagram.onTrue(); */}
            {/*    // }} */}
            {/*    children={detailData && detailData.expert && <ExpertViewBody data={detailData.expert} />} */}
            {/* /> */}

            {/* <ConfirmDialog */}
            {/*    open={confirm.value} */}
            {/*    onClose={confirm.onFalse} */}
            {/*    title="Delete" */}
            {/*    content={ */}
            {/*        <> */}
            {/*            Are you sure want to delete <strong> {table.selected.length} </strong> items? */}
            {/*        </> */}
            {/*    } */}
            {/*    action={ */}
            {/*        <Button */}
            {/*            variant="contained" */}
            {/*            color="error" */}
            {/*            onClick={() => { */}
            {/*                handleDeleteRows(); */}
            {/*                confirm.onFalse(); */}
            {/*            }} */}
            {/*        > */}
            {/*            Delete */}
            {/*        </Button> */}
            {/*    } */}
            {/* /> */}
        </>
    );
}
