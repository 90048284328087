import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Card, Container, Table, TableBody, TableContainer} from "@mui/material";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import {useSettingsContext} from "src/components/settings";
import {
    emptyRows,
    getComparator,
    TableEmptyRows,
    TableHeadCustom,
    TableNoData,
    TablePaginationActions,
    TablePaginationCustom,
    TableSelectedAction,
    TableSkeletonAll
} from "src/components/sprintify/table";
import {BannerDTO} from "src/generated/swagger/swagger.api";
import {Swagger} from "src/utils/API";
import {BannerTableRow} from "src/sections/banner/index";
import TableSelectedDeleteDiagram from "src/components/sprintify/table/TableSelectedDeleteDiagram";
import {useSnackbar} from "src/components/snackbar";
import BannerTableToolbar from "src/sections/banner/board-table-toolbar";
import {transformSortJSON} from "src/components/sprintify/table/use-table";
import {DrawerWrapper} from "src/components/sprintify/drawer";
import TableRowDeleteDiagram from "src/components/sprintify/table/TableRowDeleteDiagram";
import BannerTableAction from "src/components/banner/banner-table-action";
import isEqual from "lodash/isEqual";
import {useBoolean} from "src/hooks/use-boolean";
import BannerTableFiltersResult from "../banner-table-filters-result";
import {paths, ROOT_PAGE_NAME} from "../../../routes/paths";
import {fISO} from "../../../utils/format-time";
import {applyFilter, useBannerManagerContext} from "../banner-manage-provider";
import BannerNewEditForm from "./banner-new-edit-form";
import BannerViewBody from "./banner-view-body";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: "id", label: "ID", align: "left"},
    {id: "colorMode", label: "색상모드", align: "center"},
    {id: "background", label: "배경이미지", align: "center"},
    {id: "text1", label: "텍스트1", align: "center"},
    {id: "text2", label: "텍스트2", align: "center"},
    {id: "buttonLabel", label: "버튼", align: "center"},
    {id: "orderBy", label: "순서(오름차순)", align: "center", isSort: true},
    {id: "hide", label: "숨김", align: "center"},
    {id: "createdTime", label: "등록시간", align: "center"},
    {id: ""},
];

type Props = {
    pageName: string;
}

export default function BannerListView({pageName}: Props) {
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {themeStretch, systemLanguage, systemMultilingual, systemDefaultLanguage} = useSettingsContext();
    const {enqueueSnackbar} = useSnackbar();
    const {
        table,
        denseHeight,

        defaultFilters,
        filters,
        handleFilters,
        handleResetFilters,

        searchParams,
        paramQuery,
        paramStartDate,
        paramEndDate,
    } = useBannerManagerContext();

    //= Loading State
    const [listDataLoading, setListDataLoading] = useState<boolean>(false);
    const [detailDataLoading, setDetailDataLoading] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<BannerDTO>();

    //= Popup
    const openNew = useBoolean();
    const openView = useBoolean();
    const openEdit = useBoolean();
    const openRowDeleteDiagram = useBoolean();
    const openSelectedDeleteDiagram = useBoolean();

    //= DATA
    const [selectedId, setSelectedId] = useState<number | undefined>();
    const [tableData, setTableData] = useState<BannerDTO[]>([]);

    const dateError = filters.startDate && filters.endDate ? filters.startDate.getTime() > filters.endDate.getTime() : false;

    const dataFiltered = applyFilter({
        inputData: tableData,
        comparator: getComparator(table.order, table.orderBy),
        filters,
        dateError,
    });

    const canReset = !isEqual(defaultFilters, filters);
    const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

    const loadData = async () => {
        setListDataLoading(true);
        setTableData([]);
        let _query = '';
        if (filters.query && filters.query.length > 1) {
            _query = filters.query;
        }
        const {data} = await Swagger.api.bannerPage({
            size: table.rowsPerPage,
            page: table.page + 1,
            query: _query,
            startTime: fISO(filters.startDate),
            endTime: fISO(filters.endDate),
            orderBy:
                table.orderBy &&
                transformSortJSON(
                    JSON.stringify({
                        [table.orderBy]: table.order,
                    }),
                ),
        });
        console.log(data, 'banner');
        setTableData(data.items);
        table.setPageMetadata(data.metadata);
        setListDataLoading(false);
    };

    const loadDetailData = async (id: number) => {
        setDetailDataLoading(true);
        const {data} = await Swagger.api.bannerGet(id);
        setDetailData(data);
        setDetailDataLoading(false);
    };

    const handleReset = async () => {
        loadData();
    };

    const handleCloseDrawer = () => {
        openNew.onFalse();
        openEdit.onFalse();
        openView.onFalse();
        setSelectedId(undefined);
    };

    const handleOpenNew = () => {
        openNew.onTrue();
        openEdit.onFalse();
        openView.onFalse();
        setSelectedId(undefined);
    };

    const handleOpenEdit = (id: number | undefined) => {
        openNew.onFalse();
        openEdit.onTrue();
        openView.onFalse();
        setSelectedId(id);
    };

    const handleOpenView = (id: number | undefined) => {
        openNew.onFalse();
        openEdit.onFalse();
        openView.onTrue();
        setSelectedId(id);
    };

    const handleDeleteRows = async (selectedRows: number[]) => {
        try {
            const {data} = await Swagger.api.bannerDeleteByIds(selectedRows);
            table.setSelected([]);
            enqueueSnackbar(data.message, {variant: "success"});
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, {variant: "error"});
        }
        handleReset();
        openSelectedDeleteDiagram.onFalse()
    };

    const handleDeleteRow = async (id: number | undefined) => {
        if (id) {
            try {
                const {data} = await Swagger.api.bannerDelete(id);
                enqueueSnackbar(data.message, {variant: "success"});
                handleCloseDrawer();
                handleReset();
            } catch (e) {
                console.error(e);
                enqueueSnackbar(e.message, {variant: "error"});
            }
        }
    };
    useEffect(() => {
        updateSearchParams(); // Update search parameters
        loadData();
        navigate(`?${searchParams.toString()}`); // Update the URL with the current search parameters
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        table.rowsPerPage,
        table.page,
        table.orderBy,
        table.order,
        filters.query,
        filters.startDate,
        filters.endDate
    ]);

    function updateSearchParams() {
        searchParams.set("page", String(table.page));
        searchParams.set("size", String(table.rowsPerPage));

        if (filters.query) {
            searchParams.set("query", String(filters.query));
        }
        if (filters.startDate && !Number.isNaN(filters.startDate.getTime())) {
            searchParams.set("startTime", filters.startDate.toISOString());
        }
        if (filters.endDate && !Number.isNaN(filters.endDate.getTime())) {
            searchParams.set("endTime", filters.endDate.toISOString());
        }
    }

    useEffect(() => {
        if (paramQuery) {
            handleFilters("query", paramQuery);
        }
        if (paramStartDate) {
            try {
                const startDate: Date = new Date(decodeURIComponent(paramStartDate));
                if (Number.isNaN(startDate.getTime())) {
                    handleFilters("startDate", startDate);
                }
            } catch (e) {
                console.error('e', e);
            }
        }
        if (paramEndDate) {
            try {
                const endDate: Date = new Date(decodeURIComponent(paramEndDate));
                if (Number.isNaN(endDate.getTime())) {
                    handleFilters("endDate", endDate);
                }
            } catch (e) {
                console.error('e', e);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramQuery, paramStartDate, paramEndDate]);

    useEffect(() => {
        if (selectedId) {
            loadDetailData(selectedId);
        } else {
            setDetailData(undefined);
        }
    }, [selectedId]);

    return (
        <>
            <Container maxWidth={themeStretch ? false : "lg"}>
                <CustomBreadcrumbs
                    heading={pageName}
                    links={[
                        {
                            name: ROOT_PAGE_NAME,
                            href: paths.dashboard.root,
                        },
                        {
                            name: pageName,
                        },
                    ]}
                    action={
                        <Button
                            onClick={() => handleOpenNew()}
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill"/>}
                        >
                            새글 작성
                        </Button>
                    }
                />

                <Card
                    sx={{
                        mt: {xs: 3, md: 5},
                        mb: {xs: 3, md: 5},
                    }}
                >
                    <BannerTableToolbar
                        filters={filters}
                        onFilters={handleFilters}
                        dateError={dateError}
                    />

                    {canReset && (
                        <BannerTableFiltersResult
                            filters={filters}
                            onFilters={handleFilters}
                            onResetFilters={handleResetFilters}
                            results={table.metadata.total}
                            sx={{p: 2.5, pt: 0}}
                        />
                    )}

                    <TableContainer sx={{position: "relative", overflow: "unset"}}>
                        <TableSelectedAction
                            dense={table.dense}
                            numSelected={table.selected.length}
                            rowCount={tableData.length}
                            onSelectAllRows={(checked) =>
                                table.onSelectAllRows(
                                    checked,
                                    tableData.map((row) => row.id),
                                )
                            }
                            action={
                                <BannerTableAction
                                    onOpenSelectedDeleteDiagram={() => openSelectedDeleteDiagram.onTrue()}/>
                            }
                        />

                        <Scrollbar>
                            <Table
                                size={table.dense ? "small" : "medium"}
                                sx={{minWidth: 800}}
                            >
                                <TableHeadCustom
                                    order={table.order}
                                    orderBy={table.orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={table.selected.length}
                                    onSort={table.onSort}
                                    onSelectAllRows={(checked) =>
                                        table.onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id),
                                        )
                                    }
                                />
                                <TableBody>
                                    {!listDataLoading &&
                                        dataFiltered.map((item: BannerDTO, index: number) => (
                                            <BannerTableRow
                                                key={item.id}
                                                row={item}
                                                selected={table.selected.includes(item.id)}
                                                onSelectRow={() => table.onSelectRow(item.id)}
                                                onViewRow={() => handleOpenView(item.id)}
                                                onEditRow={() => handleOpenEdit(item.id)}
                                                onRefreshData={() => handleReset()}
                                            />
                                        ))}
                                    {!listDataLoading && table.metadata.total > 0 && (
                                        <TableEmptyRows
                                            height={denseHeight}
                                            emptyRows={emptyRows(
                                                table.page,
                                                table.rowsPerPage,
                                                table.metadata.total,
                                            )}
                                        />
                                    )}
                                    {!listDataLoading && <TableNoData notFound={notFound}/>}
                                    {listDataLoading && (dataFiltered.length === 0) && (
                                        <TableSkeletonAll
                                            size={table.metadata.size}
                                            denseHeight={denseHeight}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        count={table.metadata.total}
                        colSpan={3}
                        page={table.metadata.currentPage - 1}
                        rowsPerPage={table.metadata.size}
                        onPageChange={table.onChangePage}
                        onRowsPerPageChange={table.onChangeRowsPerPage}
                        dense={table.dense}
                        onChangeDense={table.onChangeDense}
                        ActionsComponent={TablePaginationActions}
                    />
                </Card>

            </Container>

            <TableSelectedDeleteDiagram
                open={openSelectedDeleteDiagram.value}
                onClose={() => openSelectedDeleteDiagram.onFalse()}
                onDeleteSelected={() => {
                    handleDeleteRows(table.selected);
                }}
                selected={table.selected}
            />

            {/* === Drawer === */}
            <DrawerWrapper
                title="새글 작성"
                open={openNew.value}
                onClose={handleCloseDrawer}
                children={
                    <BannerNewEditForm onEnd={() => {
                        handleCloseDrawer();
                        handleReset();
                    }}/>}
            />

            <DrawerWrapper
                loading={detailDataLoading}
                title="상세 보기"
                open={openView.value}
                onClose={handleCloseDrawer}
                onEdit={() => handleOpenEdit(selectedId)}
                onDelete={() => {
                    openRowDeleteDiagram.onTrue();
                }}
                children={detailData && <BannerViewBody data={detailData}/>}
            />

            <DrawerWrapper
                loading={detailDataLoading}
                title="수정 하기"
                open={openEdit.value}
                onClose={handleCloseDrawer}
                onView={() => handleOpenView(selectedId)}
                onDelete={() => {
                    openRowDeleteDiagram.onTrue();
                }}
                children={detailData &&
                    <BannerNewEditForm
                        isEdit
                        id={detailData.id}
                        currentData={{
                            text1: typeof detailData.text1 === 'string' ? {
                                ko: detailData.text1,
                                en: '',
                                zhCn: '',
                                zhTw: '',
                                ja: ''
                            } : detailData.text1,
                            text2: typeof detailData.text2 === 'string' ? {
                                ko: detailData.text2,
                                en: '',
                                zhCn: '',
                                zhTw: '',
                                ja: ''
                            } : detailData.text2,
                            buttonLabel: typeof detailData.buttonLabel === 'string' ? {
                                ko: detailData.buttonLabel,
                                en: '',
                                zhCn: '',
                                zhTw: '',
                                ja: ''
                            } : detailData.buttonLabel,
                            background: detailData.background,
                            fontColor1: detailData.fontColor1,
                            fontColor2: detailData.fontColor2,
                            backgroundColor: detailData.backgroundColor,
                            buttonUrl: detailData?.buttonUrl || undefined,
                            orderBy: detailData.orderBy,
                            colorMode: detailData.colorMode,
                            hide: detailData.hide
                        }} onEnd={() => {
                        handleReset();
                        if (selectedId) loadDetailData(selectedId);
                    }}/>
                }
            />

            {/* === Delete === */}
            {selectedId && (
                <TableRowDeleteDiagram
                    open={openRowDeleteDiagram.value}
                    dataId={selectedId}
                    onClose={() => openRowDeleteDiagram.onFalse()}
                    onDeleteRow={() => handleDeleteRow(selectedId)}
                />
            )}
        </>
    );
}
