// @mui
import {Checkbox, IconButton, MenuItem, TableCell, TableRow} from "@mui/material";
// utils
// @types
// components
import Iconify from "src/components/iconify";
import {ChatRoomDTO} from "src/generated/swagger/swagger.api";
import {Swagger} from "src/utils/API";
import {useSnackbar} from "src/components/snackbar";
import {fDateTime} from "../../../utils/format-time";
import CustomPopover, {usePopover} from "../../../components/custom-popover";
import {useBoolean} from "../../../hooks/use-boolean";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import * as React from "react";
import {useState} from "react";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import ListItemText from "@mui/material/ListItemText";
import {fNumber} from "../../../utils/format-number";

// ----------------------------------------------------------------------

type Props = {
    row: ChatRoomDTO;
    selected: boolean;
    onSelectRow: VoidFunction;
    onViewRow: VoidFunction;
    onEditRow: VoidFunction;
    onRefreshData: VoidFunction;
};

export default function ChatManageTableRow({
                                               row,
                                               selected,
                                               onSelectRow,
                                               onViewRow,
                                               onEditRow,
                                               onRefreshData,
                                           }: Props) {
    const {enqueueSnackbar} = useSnackbar();
    const {
        id,
        user,
        expert,
        countByMessage,
        latestTime,
        createdTime,
    } = row;

    const {userId, expertId} = id;

    const collapse = useBoolean();
    const openSendTestDiagram = useBoolean();
    const openRowDeleteDiagram = useBoolean();
    const openPopover = usePopover();

    const [mobileNumber, setMobileNumber] = useState('');

    const handleMobileNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMobileNumber(event.target.value);
    };


    const handleDelete = async (_id: number) => {
        try {
            const {data} = await Swagger.api.kkoDelete(_id);
            enqueueSnackbar(data.message, {variant: "success"});
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, {variant: "error"});
        }
        openRowDeleteDiagram.onFalse();
        onRefreshData();
    };


    const renderSecondary = (
        <TableRow>
            <TableCell sx={{p: 0, border: 'none'}} colSpan={8}>
                <Collapse
                    in={collapse.value}
                    timeout="auto"
                    unmountOnExit
                    sx={{bgcolor: 'background.neutral'}}
                >
                    <Stack component={Paper} sx={{m: 1.5}}>

                        <Stack
                            key={1}
                            direction="row"
                            alignItems="center"
                            sx={{
                                p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                                '&:not(:last-of-type)': {
                                    borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                                },
                                whiteSpace: 'pre-line', // 줄바꿈 적용
                            }}
                        >
                            TEST
                        </Stack>
                    </Stack>
                </Collapse>
            </TableCell>
        </TableRow>
    );

    return (
        <>
            <TableRow hover selected={selected}>
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow}/>
                </TableCell>

                <TableCell>
                    <Stack direction="row" spacing={1}>
                        <Avatar alt={user.fullName} src={user.image} sx={{mr: 2}}/>

                        <Tooltip title={`ID: ${id}`} placement="top-start">
                            <ListItemText
                                primary={user.nickname}
                                secondary={user.email}
                                primaryTypographyProps={{typography: 'body2'}}
                                secondaryTypographyProps={{
                                    component: 'span',
                                    color: 'text.disabled',
                                }}
                            />
                        </Tooltip>
                    </Stack>
                </TableCell>
                <TableCell>
                    {expert && <Stack direction="row" spacing={1}>
                        <Avatar alt={expert.userNickname || '-'} src={expert.userProfile || ''} sx={{mr: 2}}/>

                        <Tooltip title={`ID: ${id}`} placement="top-start">
                            <ListItemText
                                primary={expert.userNickname}
                                secondary={expert.userEmail}
                                primaryTypographyProps={{typography: 'body2'}}
                                secondaryTypographyProps={{
                                    component: 'span',
                                    color: 'text.disabled',
                                }}
                            />
                        </Tooltip>
                    </Stack>}
                </TableCell>

                <TableCell align="center">
                    {countByMessage && fNumber(countByMessage)}
                </TableCell>

                <TableCell>
                    {latestTime && fDateTime(latestTime)}
                </TableCell>

                <TableCell align="center">{fDateTime(createdTime)}</TableCell>

                <TableCell align="right">
                    <IconButton
                        color={openPopover.open ? "inherit" : "default"}
                        onClick={openPopover.onOpen}
                    >
                        <Iconify icon="eva:more-vertical-fill"/>
                    </IconButton>
                </TableCell>
            </TableRow>

            {renderSecondary}

            <CustomPopover
                open={openPopover.open}
                onClose={openPopover.onClose}
                arrow="right-top"
                sx={{width: 160}}
            >
                <MenuItem
                    onClick={() => {
                        onViewRow();
                        openPopover.onClose();
                    }}
                >
                    <Iconify icon="eva:eye-fill"/>
                    상세
                </MenuItem>

            </CustomPopover>

            {/* <TableRowDeleteDiagram */}
            {/*    open={openRowDeleteDiagram.value} */}
            {/*    dataId={id} */}
            {/*    onClose={() => openRowDeleteDiagram.onFalse()} */}
            {/*    onDeleteRow={() => handleDelete(id)} */}
            {/* /> */}
        </>
    );
}
